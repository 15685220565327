import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import TableListIcon from '../../static/icons/table_control_list.svg';
import TableGridIcon from '../../static/icons/table_control_grid.svg';
import CampaignResultTable from './CampaignResultTable';
import CampaignResultGrid from './CampaignResultGrid';
import PagePaginate from '../../molecules/PagePaginate';
import { PosterType } from '../../types/liveReport';

const CampaignResultView = ({
  data,
  filterIsOpen,
  changePage,
  amountOfPages,
  sortLiveReports,
  page,
}: CampaignResultViewProps) => {
  const [tableMode, setTableMode] = React.useState<boolean>(true);

  const [amountOnPageResults, setAmountOnPageResults] = React.useState(null);

  const [startResultView, setStartResultView] = React.useState(1);
  const [endResultView, setEndResultView] = React.useState(null);

  const setAmountOnPageOfResults = React.useCallback(
    (value) => {
      setStartResultView(1);
      if (tableMode) {
        const maxResultView = value + 1 >= data.length ? data.length : value;
        setAmountOnPageResults(value);
        setEndResultView(maxResultView + 1 >= data.length ? data.length : maxResultView + 1);
        return;
      }
      if (!filterIsOpen && !tableMode) {
        const maxResultView = data.length <= 5 ? data.length : 5;
        setAmountOnPageResults(5);
        setEndResultView(maxResultView);
        return;
      }
      const maxResultView = data.length <= 3 ? data.length : 3;
      setAmountOnPageResults(3);
      setEndResultView(maxResultView);
    },
    [tableMode, filterIsOpen, data]
  );

  const changeResultPosition = (value) => {
    if (tableMode) {
      const maxResultView =
        amountOnPageResults + value + 1 >= data.length
          ? data.length
          : amountOnPageResults + value + 1;
      setStartResultView(1 + value);
      setEndResultView(maxResultView);
      return;
    }
    if (!filterIsOpen && !tableMode) {
      const maxResultView =
        data.length <= amountOnPageResults + value * 5
          ? data.length
          : amountOnPageResults + value * 5;
      setStartResultView(1 + value * 5 > data.length ? data.length : 1 + value * 5);
      setEndResultView(maxResultView);
      return;
    }
    const maxResultView =
      data.length <= amountOnPageResults + value * 3
        ? data.length
        : amountOnPageResults + value * 3;
    setStartResultView(1 + value * 3 > data.length ? data.length : 1 + value * 3);
    setEndResultView(maxResultView);
  };

  return (
    <ResultTableContainer>
      <TitleContainer>
        <SectionTitle>Posting History</SectionTitle>
        <TableControlsContainer>
          <IconContainer isTableMode={!tableMode} onClick={() => setTableMode(false)}>
            <ReactSVG src={TableGridIcon} />
          </IconContainer>
          <IconContainer isTableMode={tableMode} onClick={() => setTableMode(true)}>
            <ReactSVG src={TableListIcon} />
          </IconContainer>
        </TableControlsContainer>
      </TitleContainer>
      <ResultContainer>
        {tableMode && (
          <CampaignResultTable
            data={data}
            setAmountOnPageOfResults={setAmountOnPageOfResults}
            changeResultPosition={changeResultPosition}
            sortLiveReports={sortLiveReports}
          />
        )}
        {!tableMode && (
          <CampaignResultGrid
            data={data}
            filterIsOpen={filterIsOpen}
            setAmountOnPageOfResults={setAmountOnPageOfResults}
            changeResultPosition={changeResultPosition}
          />
        )}
      </ResultContainer>
      <PaginationContainer>
        {data.length ? (
          <AmountOfResultsContainer>
            {startResultView} - {endResultView} of {data.length} Results
          </AmountOfResultsContainer>
        ) : null}
        {amountOfPages && amountOfPages > 1 ? (
          <PagePaginate amountOfPages={amountOfPages} handlePageChange={changePage} page={page} />
        ) : null}
      </PaginationContainer>
    </ResultTableContainer>
  );
};

const ResultTableContainer = styled.div`
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 0px;
  align-items: center;
  justify-content: space-between;
`;

const AmountOfResultsContainer = styled.p.attrs({
  className: 'font-body1',
})`
  color: var(--secondaryText);
  margin: 0;
`;

const IconContainer = styled(({ isTableMode, ...rest }) => <div {...rest} />)`
  margin: 0 8px;
  cursor: pointer;

  & > div {
    padding-top: 4px;

    svg {
      path {
        fill: ${(props) => (props.isTableMode ? 'var(--elementBackground);' : '')};
      }
    }
  }
`;

const TableControlsContainer = styled.div`
  display: flex;
  margin-left: 25px;
`;

const SectionTitle = styled.p.attrs({
  className: 'font-body3',
})`
  color: var(--mainText);
  margin: 0;
  margin-left: 7px;
  margin-bottom: 3px;
  min-height: 20px;
  display: flex;
  align-items: center;
`;

const ResultContainer = styled.div`
  width: 100%;
  min-height: 50px;
  background: var(--mainBackground);
  border-radius: 5px;
  padding: 16px 8px;
`;

interface CampaignResultViewProps {
  data?: PosterType[];
  filterIsOpen: boolean;
  changePage: (pageNumber: number) => void;
  sortLiveReports: (sortValue: string) => void;
  amountOfPages: number | null;
  page: number;
}

export default CampaignResultView;

import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import CloseBurger from '../../static/icons/close_burger.svg';
import Burger from '../../static/icons/burger.svg';

const BurgerMenuButton = ({ onClick, isOpen }: PropTypes) => {
  return (
    <BurgerContainer onClick={onClick}>
      {isOpen ? <ReactSVG src={CloseBurger} /> : <ReactSVG src={Burger} />}
    </BurgerContainer>
  );
};

const BurgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

interface PropTypes {
  onClick: () => void;
  isOpen: boolean;
}

export default BurgerMenuButton;

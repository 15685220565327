import React from 'react';
import styled from 'styled-components';
import DashboardTemplate from '../templates/DashboardTemplate';

const FaqPage = () => {
  return (
    <DashboardTemplate
      body={
        <DashboardContainer>
          <Title>FAQ</Title>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
          <p>
            1. What is the PCDS platformThe platform is designed to control the quantity and quality
            of work on the placement of posters in different campaigns for different brands and
            clients.
          </p>
        </DashboardContainer>
      }
    />
  );
};

const Title = styled.h2.attrs({
  className: 'font-h1',
})`
  text-align: center;
  padding: 24px 0;
`;

const SectionTitle = styled.h2.attrs({
  className: 'font-h2',
})`
  text-align: left;
  padding: 16px 0;
  margin: 0;
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 96px 160px 96px;
  width: 100%;
`;

export default FaqPage;

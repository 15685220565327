import React from 'react';
import { ErrorObj, ErrorArrayedObj } from '../types/authTypes';
import { createErrorMessageArray } from '../utils/validator';

const HelperMessages = ({ errorMessages, isError }: PropTypes) => {
  return (
    <>
      {createErrorMessageArray(errorMessages)?.map((element) => (
        <p className={isError ? 'error-message font-body3' : 'font-body3'} key={element}>
          {element}
        </p>
      ))}
    </>
  );
};

interface PropTypes {
  isError?: boolean;
  errorMessages: string | string[] | ErrorObj | ErrorArrayedObj | null;
}

export default HelperMessages;

import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import RouteTypes from '../pages/routes.enum';
import { isAccessTokenInStore } from '../utils/auth';

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const isAuthenticated = isAccessTokenInStore();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Redirect to={RouteTypes.DASHBOARD} /> : <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;

interface Props {
  path: string;
  exact?: boolean;
  strict?: boolean;
  sensitive?: boolean;
  component?: React.FC<RouteComponentProps<any>> | React.ComponentType;
}

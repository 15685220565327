import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setServerError } from '../redux/ducks/requestApiError';
import AuthTemplate from '../templates/AuthTemplate';
import RoutesTypes from './routes.enum';
import LogInForm from '../organisms/LogInForm';
import MainLoader from '../atoms/loaders/MainLoader';
import { LogInFormState } from '../types/authTypes';
import { checkEmptyFields, checkEmptyObj } from '../utils/validator';
import { setAuthData } from '../utils/auth';
import { setUserInfo } from '../redux/ducks/userAuth';

import { logInUser } from '../api/userAuth';

const initFormState: LogInFormState = {
  email: '',
  password: '',
};

const LogInPage = () => {
  const [formState, setFormState] = React.useState(initFormState);
  const [errors, setError] = React.useState({});
  const [fetchProcess, setFetchProcess] = React.useState(false);

  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleChangeField = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFormState({
      ...formState,
      [key]: newValue,
    });
  };

  const postData = () => {
    setFetchProcess(true);
    const newError = checkEmptyFields(formState);
    if (checkEmptyObj(newError)) {
      setFetchProcess(false);
      return setError(newError);
    }
    return logInUser(formState)
      .then((response) => {
        const { token, user } = response.data;
        setAuthData(token, user);
        dispatch(setUserInfo(user));
        setError({});
        setFetchProcess(false);
        push(RoutesTypes.DASHBOARD);
      })
      .catch((error) => {
        const { data, status } = error.response;
        setFetchProcess(false);
        if (status >= 500) {
          dispatch(setServerError(status));
          return;
        }
        setError(data);
      });
  };

  const FooterContent = () => (
    <FooterText className="font-body3">
      Don't have an account?{' '}
      <Link to={RoutesTypes.REGISTRATION} className="font-body2">
        Register Now
      </Link>
    </FooterText>
  );

  const BodyContent = () => (
    <div>
      {fetchProcess && <MainLoader />}
      <RegistrationTitle>Log in to PoP</RegistrationTitle>
      <LogInForm
        onFieldChange={handleChangeField}
        values={formState}
        errors={errors}
        onSubmit={postData}
      />
    </div>
  );

  return <AuthTemplate body={BodyContent()} footer={FooterContent()} />;
};

const RegistrationTitle = styled.h2.attrs({
  className: 'font-h1',
})`
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--elementBackground);
  text-align: center;
`;

const FooterText = styled.p`
  color: var(--mainText);
`;

export default LogInPage;

import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import Icon from '../../static/icons/Close_modal.svg';

const CloseModalButton = ({ onClick }: Props) => (
  <CloseButtonContainer>
    <ReactSVG src={Icon} onClick={onClick} />
  </CloseButtonContainer>
);

const CloseButtonContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

interface Props {
  onClick: () => void;
}

export default CloseModalButton;

import React from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import MarkerClusterer from '@google/markerclustererplus';
import { ReactSVG } from 'react-svg';
import PaperBoxContainer from '../../atoms/PaperBox';
import googleMapStyle from '../../styles/googleMapStyle';
import PostDataCard from '../../molecules/PostDataCard';
import setMarkersColor from '../../utils/setMarkersColor';
import { MapMarker, PosterType } from '../../types/liveReport';
import FullScreen from '../../static/icons/fullscreen.svg';
import FullScreenClose from '../../static/icons/fullscreen_exit.svg';

const mapDefaultProps = {
  center: {
    lat: 53.509,
    lng: -4.118,
  },
  zoom: 5.8,
};

const MapSection = ({ mapMarkers, getPosterInfo, poster, forwardRef }: MapSectionTypes) => {
  const [position, setPosition] = React.useState(null);
  const [mousePosition, setMousePosition] = React.useState(null);
  const [delayHandler, setDelayHandler] = React.useState(false);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [idToSearch, setIdToSearch] = React.useState(null);
  const [mapIsLoaded, setMapIsLoaded] = React.useState(false);

  const [googleMapMarkers, setGoogleMapMarkers] = React.useState<{ [key: string]: any[] }>({});
  const [googleMapClusters, setGoogleMapClusters] = React.useState([]);
  const infoModalRef = React.useRef(null);

  const innerHeight = Math.floor(window.innerHeight);

  const reactGoogleRef = React.useRef(null);
  const mapRef = React.useRef();
  const googleRef = React.useRef<HTMLDivElement>();
  const infoWindow = React.useRef<HTMLElement>();
  const markersCampaigns = React.useRef(null);

  const handleMouseEnter = (props) => {
    const { id } = props.info;
    setIdToSearch(id);
    setDelayHandler(true);
  };

  const makePosterInfoView = () => {
    if (!poster) return null;
    return (
      <StyledPaperBoxContainer position={position} forwardRef={infoModalRef}>
        <PostDataCard postData={poster} />
      </StyledPaperBoxContainer>
    );
  };

  React.useEffect(() => {
    let timeout;
    if (delayHandler) {
      timeout = setTimeout(() => {
        getPosterInfo(idToSearch);
      }, 500);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [delayHandler]);

  const handleMouseLeave = () => {
    setDelayHandler(false);
    setPosition(null);
    getPosterInfo(null);
  };

  const handleMouseClusterEnter = (cluster) => {
    // @ts-ignore - possibly undefined
    const marker = cluster.getMarkers()[0];
    const campaign = marker.info.campaign;
    // @ts-ignore - setContent
    infoWindow.current.setContent(`
       <div class="g_map_info_window">
         <h2>${campaign}</h2>
       </div>
    `);
    // @ts-ignore - / infoWindow.current possibly undefined
    infoWindow.current.setPosition(cluster.getCenter());
    // @ts-ignore  - / infoWindow.current possibly undefined
    infoWindow.current.open(mapRef.current);
  };

  const handleMouseClusterLeave = (e) => {
    // @ts-ignore - / infoWindow.current possibly undefined
    infoWindow.current.close();
  };

  const handleSkip = () => {
    if (infoWindow && infoWindow.current) {
      // @ts-ignore - / infoWindow.current possibly undefined
      infoWindow.current.close();
    }
    setDelayHandler(false);
    setPosition(null);
    getPosterInfo(null);
  };

  React.useEffect(() => {
    if (infoModalRef && infoModalRef.current) {
      // @ts-ignore - / infoModalRef.current possibly undefined
      const infoModalRefHeight = infoModalRef.current.getBoundingClientRect().height;
      const infoModalRefWidth = infoModalRef.current.getBoundingClientRect().width;
      const clientYPosition =
        infoModalRefHeight + mousePosition.clientY > innerHeight
          ? innerHeight - infoModalRefHeight
          : mousePosition.clientY / 2;

      setPosition({
        clientX: mousePosition.clientX - infoModalRefWidth - 30,
        clientY: clientYPosition,
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [infoModalRef, poster]);

  const getModalInfoPosition = (e) => {
    setMousePosition({
      clientX: e.clientX,
      clientY: e.clientY,
    });
  };

  const createMapOptions = (maps) => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
        style: maps.ZoomControlStyle.DEFAULT,
      },
      styles: googleMapStyle,
      scrollwheel: true,
      fullscreenControl: false,
    };
  };

  const setGoogleMapRef = (map, maps) => {
    mapRef.current = map;
    googleRef.current = maps;
    // @ts-ignore - / infoWindow.current possibly undefined
    infoWindow.current = new googleRef.current.InfoWindow();
    // @ts-ignore -  possibly undefined
    googleRef.current.event.addDomListener(map.getDiv(), 'mouseover', (e) => {
      getModalInfoPosition(e);
    });
    setMapIsLoaded(true);
  };

  const clearGMap = () => {
    googleMapClusters.forEach((cluster) => {
      cluster.setMap(null);
    });
    Object.values(googleMapMarkers).forEach((element) => {
      element.forEach((markerToDelete) => {
        markerToDelete.setMap(null);
      });
    });
    setGoogleMapClusters([]);
    setGoogleMapMarkers({});
  };

  React.useEffect(() => {
    if (mapMarkers.length) {
      if (
        (!markersCampaigns.current && mapMarkers.length) ||
        (markersCampaigns.current && markersCampaigns.current.length < mapMarkers.length)
      ) {
        const campaigns = mapMarkers.map((markerElement) => markerElement[0].campaign);
        const mapCampaigns = markersCampaigns.current?.map((mapCamp) => mapCamp.campaign) || [];
        const filteredCampaigns = campaigns.filter((campaignElement) => {
          return !mapCampaigns?.includes(campaignElement);
        });
        const actualCampaignsList = [...mapCampaigns, ...filteredCampaigns];
        markersCampaigns.current = actualCampaignsList.map((campaignName, index) => {
          return {
            campaign: campaignName,
            color: setMarkersColor(index),
          };
        });
      }
      if (Object.keys(googleMapMarkers).length) {
        clearGMap();
      }
      if (
        mapRef &&
        googleRef &&
        mapRef.current &&
        googleRef.current &&
        mapMarkers &&
        mapMarkers.length
      ) {
        const googleMarkersObj = {};
        const googleMapClustersArr = [];
        mapMarkers.length &&
          mapMarkers.forEach((campaignMarkers, index) => {
            const campaign = campaignMarkers[0].campaign;
            const searchedCampaign = markersCampaigns.current.find((searchObj) => {
              return searchObj.campaign === campaign;
            });
            const color = searchedCampaign ? searchedCampaign.color : setMarkersColor(index);
            const markers =
              campaignMarkers &&
              campaignMarkers.map((marker) => {
                const icon = {
                  path:
                    'M7 0.8125C3.1332 0.8125 0 3.9331 0 7.7985C0 14.4821 7 23.1985 7 23.1985C7 23.1985 14 14.4807 14 7.7985C14 3.9345 10.8668 0.8125 7 0.8125ZM7 11.6639C5.99748 11.6639 5.03602 11.2657 4.32714 10.5568C3.61825 9.84788 3.22 8.88642 3.22 7.8839C3.22 6.88138 3.61825 5.91992 4.32714 5.21104C5.03602 4.50215 5.99748 4.1039 7 4.1039C8.00252 4.1039 8.96398 4.50215 9.67286 5.21104C10.3818 5.91992 10.78 6.88138 10.78 7.8839C10.78 8.88642 10.3818 9.84788 9.67286 10.5568C8.96398 11.2657 8.00252 11.6639 7 11.6639Z',
                  fillColor: color,
                  fillOpacity: 1,
                  strokeColor: color,
                  // @ts-ignore  possibly undefined
                  anchor: new googleRef.current.Point(0, 0),
                  // @ts-ignore  possibly undefined
                  scaledSize: new googleRef.current.Size(22, 26),
                };
                // @ts-ignore
                const newMarker = new googleRef.current.Marker({
                  position: { lat: +marker.latitude, lng: +marker.longitude },
                  icon,
                  map: mapRef.current,
                });
                newMarker.info = marker;

                // @ts-ignore  possibly undefined
                googleRef.current.event.addListener(newMarker, 'mouseover', () =>
                  handleMouseEnter(newMarker)
                );
                // @ts-ignore  possibly undefined
                googleRef.current.event.addListener(newMarker, 'mouseout', () =>
                  handleMouseLeave()
                );
                return newMarker;
              });
            // @ts-ignore  possibly undefined
            googleMarkersObj[campaign] = markers;

            const ClusterIcon = (IconColor) =>
              window.btoa(
                `
              <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" fill="none">
                <circle cx="15px" cy="15px" r="9px"  fill="${IconColor}" />
                <circle cx="15px" cy="15px" r="11px" fill-opacity="0.8" fill="${IconColor}" />
                <circle cx="15px" cy="15px" r="12px" fill-opacity="0.5" fill="${IconColor}" />
                <circle cx="15px" cy="15px" r="15px" fill-opacity="0.20" fill="${IconColor}" />
              </svg>
              `
              );
            const markerCluster = new MarkerClusterer(mapRef.current, markers, {
              styles: [
                {
                  url: `data:image/svg+xml;base64,${ClusterIcon(color)}`,
                  height: 30,
                  width: 30,
                  textSize: 14,
                  textColor: '#fff',
                  className: 'marker-cluster--label',
                },
              ],
              gridSize: 20,
              minimumClusterSize: 2,
              clusterClass: 'marker-cluster',
            });

            googleMarkersObj[campaign] = markers;
            googleMapClustersArr.push(markerCluster);
            // @ts-ignore  possibly undefined
            markerCluster.addListener('mouseover', (cluster) => handleMouseClusterEnter(cluster));
            // @ts-ignore  possibly undefined
            googleRef.current.event.addListener(markerCluster, 'mouseout', (e) =>
              handleMouseClusterLeave(e)
            );
          });
        setGoogleMapClusters(googleMapClustersArr);
        setGoogleMapMarkers(googleMarkersObj);
      }
      return;
    }
    clearGMap();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [mapMarkers, mapIsLoaded]);

  return (
    <GoogleMapSectionContainer
      onMouseLeave={() => handleSkip()}
      forwardRef={forwardRef}
      isFullScreen={isFullScreen}
    >
      <GoogleMapReact
        ref={reactGoogleRef}
        bootstrapURLKeys={{
          language: 'en',
          key: 'AIzaSyAELn_2RHFCbnfxmisJJId7eOxREduGy_I',
        }}
        options={createMapOptions}
        defaultCenter={mapDefaultProps.center}
        defaultZoom={mapDefaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onChange={() => handleSkip()}
        onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
      />
      {makePosterInfoView()}
      <FullScreenControls>
        {/* @ts-ignore */}
        {isFullScreen ? (
          <ReactSVG src={FullScreenClose} onClick={() => setIsFullScreen(false)} />
        ) : (
          <ReactSVG src={FullScreen} onClick={() => setIsFullScreen(true)} />
        )}
      </FullScreenControls>
    </GoogleMapSectionContainer>
  );
};

const GoogleMapSectionContainer = styled(({ isFullScreen, forwardRef, ...rest }) => (
  <div ref={forwardRef} {...rest} />
))`
  height: 100%;
  width: 100%;
  position: relative;

  .marker-cluster {
    display: flex;
    align-items: center;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }

  .gm-style > h2 {
    margin: 0;
    text-align: center;
  }

  .gm-style-iw.gm-style-iw-c {
    top: -20px;
  }

  .gm-style .gm-style-iw-t::after {
    top: -20px;
  }

  ${(props) =>
    props.isFullScreen &&
    `
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  `}
`;

const FullScreenControls = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  background: var(--defaultBody);
  padding: 2px;

  div {
    display: flex;
    align-items: center;
  }
`;

const StyledPaperBoxContainer = styled(({ position, ...rest }) => <PaperBoxContainer {...rest} />)`
  position: fixed;
  border-radius: 5px;
  z-index: 30;
  top: ${(props) => `${props.position?.clientY}px` || '300px;'};
  left: ${(props) => `${props.position?.clientX}px` || '300px;'};
`;

interface MapSectionTypes {
  mapMarkers: [MapMarker[]] | [];
  getPosterInfo: (id: number | null) => void;
  poster: PosterType | null;
  forwardRef?: React.Ref<HTMLDivElement>;
}

export default MapSection;

import React from 'react';
import styled from 'styled-components';
import TextNoBorderedInput from '../atoms/form/TextNoBorderedInput';
import TextareaNoBordered from '../atoms/form/TextareaNoBordered';
import DefaultButton from '../atoms/buttons/DefaultButton';
import FormMessageContainer from '../features/FormMessageContainer';
import { SupportFormState, ErrorArrayedObj } from '../types/authTypes';

const ContactSupportForm = ({ values, errors, onSubmit, onFieldChange }: SupportFormTypes) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <SupportContainer>
      <TitleContainer>
        <Title>Contact Support</Title>
      </TitleContainer>

      <Form onSubmit={handleClick}>
        <InputsContainer>
          <SubjectContainer>
            <TextNoBorderedInput
              type="text"
              label="Subject:"
              value={values.subject}
              error={!!errors.subject}
              onChange={onFieldChange('subject')}
            />
          </SubjectContainer>
          <TextareaContainer>
            <TextareaNoBordered
              label="Message:"
              value={values.message}
              error={!!errors.message}
              onChange={onFieldChange('message')}
            />
          </TextareaContainer>
        </InputsContainer>
        <MessageContainer>
          <FormMessageContainer minHeight={40} errors={errors} />
        </MessageContainer>
        <ButtonContainer>
          <DefaultButton isSubmit>Send</DefaultButton>
        </ButtonContainer>
      </Form>
    </SupportContainer>
  );
};

const SupportContainer = styled.div`
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
  background: var(--defaultBody);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  width: 100%;
  padding: 27px 0 33px 0;
  display: flex;
  justify-content: center;
`;

const Title = styled.h2.attrs({
  className: 'font-h1',
})`
  margin: 0;
  text-align: center;
`;

const SubjectContainer = styled.div`
  height: 63px;
  border-bottom: 1px solid var(--mainObjects);
`;

const TextareaContainer = styled.div`
  height: 288px;
`;

const Form = styled.form`
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const InputsContainer = styled.div`
  border: 1px solid var(--mainObjects);
  border-radius: 15px;
  box-sizing: border-box;
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 270px;
`;

interface SupportFormTypes {
  values: SupportFormState;
  onSubmit: () => void;
  onFieldChange: (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: ErrorArrayedObj;
}

export default ContactSupportForm;

import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import DefaultButton from '../atoms/buttons/DefaultButton';
import CloseModalButton from '../atoms/buttons/CloseModalButton';

import { requestApiErrorObjSelector, clearRequestError } from '../redux/ducks/requestApiError';
import { clearUserInfo } from '../redux/ducks/userAuth';
import { logOut } from '../utils/auth';

const ErrorRequestModal = () => {
  const errorObject = useSelector(requestApiErrorObjSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const bodyClass = 'body--overflow';
    const body = document.getElementsByTagName('body')[0];
    body.classList.add(bodyClass);
    return () => {
      body.classList.remove(bodyClass);
    };
  }, []);

  const handleClick = async () => {
    if (errorObject.status === 403) {
      await logOut();
      await dispatch(clearUserInfo());
    }
    await dispatch(clearRequestError());
  };

  return (
    <Container>
      <ModalContainer>
        <CLoseButtonContainer>
          <CloseModalButton onClick={handleClick} />
        </CLoseButtonContainer>
        <TitleContainer>
          <Title>{errorObject.data?.title}</Title>
          <p>{errorObject.data?.msg}</p>
        </TitleContainer>
        <ButtonContainer>
          <DefaultButton onClick={handleClick}>OK</DefaultButton>
        </ButtonContainer>
      </ModalContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  height: 100vh;
  background: var(--modalWindowBackground);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: 464px;
  background: var(--defaultBody);
  border-radius: 5px;
  position: relative;

  & > p {
    color: var(--mainText);
    margin: 0;
    padding: 0 30px;
    text-align: center;
  }
`;

const CLoseButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: -20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0 16px 0;
  background: var(--secondaryBackground);

  & > button {
    width: 270px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 48px 0;
  align-items: center;
  flex-direction: column;
  position: relative;

  & > p {
    color: var(--mainText);
    margin: 0;
    padding: 0 30px;
    text-align: center;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -39px;
    transform: translateX(-50%);
    left: 50%;
    border: 20px solid transparent;
    border-top: 20px solid var(--defaultBody);
  }
`;

const Title = styled.h2.attrs({
  className: 'font-h1',
})`
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--elementBackground);
  text-align: center;
`;

export default ErrorRequestModal;

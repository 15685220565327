import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { all, put, takeEvery, call } from 'redux-saga/effects';
import { createLookupQuery, createPosterQuery } from '../../utils/queryParamCreator';
import { setError, setServerError } from './requestApiError';
import { defaultError } from '../../constants/errors/errorData';
import { logOut } from '../../utils/auth';
import {
  getLookupsApi,
  getPostersApi,
  addPosterCommentApi,
  getModeratorPostersApi,
  approveSinglePosterApi,
  approveAllPostersApi,
} from '../../api/liveReport';
import { OptionsType, FilterType, DefaultObj, PosterType } from '../../types/liveReport';

const initialLookupsList = {
  campaign: [],
  mediaOwner: [],
  refId: [],
  design: [],
  inCharge: [],
  size: [],
  issue: [],
  moderatorIssue: [],
};

const initialState: LiveReportModeratorStateType = {
  fetchProcess: false,
  postersList: [],
  filterList: null,
  amountOfPages: null,
  lookupsList: initialLookupsList,
};

const reducerName = 'liveReportModerator';

const slice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setFetchProcess: (state, action: PayloadAction<boolean>) => {
      state.fetchProcess = action.payload;
    },
    setPosters: (state, action: PayloadAction<PosterType[] | null>) => {
      state.postersList = action.payload;
    },
    setFilters: (state, action: PayloadAction<OptionsType | null>) => {
      state.filterList = action.payload;
    },
    setAmountOfPages: (state, action: PayloadAction<number | null>) => {
      state.amountOfPages = action.payload;
    },
    setLookups: (state, action: PayloadAction<FilterType | null>) => {
      state.lookupsList = { ...state.lookupsList, ...action.payload };
    },
    skipAllValues: (state) => {
      state = initialState;
    },
  },
});

export const {
  setFetchProcess,
  setPosters,
  setFilters,
  setLookups,
  skipAllValues,
  setAmountOfPages,
} = slice.actions;

export const getModeratorPosters = createAction<DefaultObj, any>(
  `${reducerName}/getModeratorPosters`
);
export const getLookups = createAction<OptionsType, any>(`${reducerName}/getLookups`);
export const applyFilter = createAction<OptionsType, any>(`${reducerName}/applyFilter`);
export const getModeratorReports = createAction<any, any>(`${reducerName}/getModeratorReports`);
export const approveSinglePoster = createAction<number, any>(`${reducerName}/approveSinglePoster`);
export const approveAllPosters = createAction<any, any>(`${reducerName}/approveAllPosters`);
export const addPosterComment = createAction<any, any>(`${reducerName}/addPosterComment`);

export const fetchProcessSelector = ({ liveReportModerator }: PartialRootState) =>
  liveReportModerator.fetchProcess;
export const appliedFilterSelector = ({ liveReportModerator }: PartialRootState) =>
  liveReportModerator.filterList;
export const lookupsListSelector = ({ liveReportModerator }: PartialRootState) =>
  liveReportModerator.lookupsList;
export const postersListSelector = ({ liveReportModerator }: PartialRootState) =>
  liveReportModerator.postersList;
export const amountOfPagesSelector = ({ liveReportModerator }: PartialRootState) =>
  liveReportModerator.amountOfPages;

function* parseErrorResponse(e) {
  const { data, status } = e.response;
  if (status >= 500) {
    yield put(setServerError(status));
    return;
  }
  if (status === 401) {
    yield logOut();
    return;
  }
  if (status === 403 && data.error === 'status') {
    yield put(
      setError({
        status,
        data: {
          msg: data.detail[0],
        },
      })
    );
    return;
  }
  yield put(setError(defaultError));
}

function* getModeratorPostersSaga({ payload }) {
  const queryStr = createPosterQuery(payload);
  yield put(setFetchProcess(true));
  try {
    const result = yield call(getPostersApi, queryStr);
    const amountOfPages = Math.ceil(result.data.count / 100) || 0;
    yield put(setAmountOfPages(amountOfPages));
    yield put(setPosters(result.data.results));
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* getLookupsSaga({ payload }) {
  const lookupQuery = createLookupQuery(payload);
  yield put(setFetchProcess(true));
  try {
    const result = yield call(getLookupsApi, lookupQuery);
    yield put(setLookups(result.data));
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* applyFilterSaga({ payload }) {
  try {
    yield put(setFilters(payload));
  } catch {
    yield put(setError(defaultError));
  }
}

function* approveSinglePosterSaga({ payload }) {
  const id = payload;
  yield put(setFetchProcess(true));
  try {
    yield call(approveSinglePosterApi, id);
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* addPosterCommentSaga({ payload }) {
  const { id, comment } = payload;
  yield put(setFetchProcess(true));
  try {
    yield call(addPosterCommentApi, { id, comment });
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* approveAllPostersSaga({ payload }) {
  const { filterValues, page, idArray } = payload;
  const queryStr = createPosterQuery({ ...filterValues, page });
  yield put(setFetchProcess(true));
  try {
    yield call(approveAllPostersApi, idArray);
    const result = yield call(getPostersApi, queryStr);
    const amountOfPages = Math.ceil(result.data.count / 100) || 0;
    yield put(setAmountOfPages(amountOfPages));
    yield put(setPosters(result.data.results));
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* getModeratorReportsSaga({ payload }) {
  const { filterValues, page } = payload;
  const postersQuery = createPosterQuery({ ...filterValues, page });
  const lookupQuery = createLookupQuery(filterValues);
  yield put(setFetchProcess(true));
  try {
    const postersResult = yield call(getModeratorPostersApi, postersQuery);
    const lookupsResult = yield call(getLookupsApi, lookupQuery);
    yield put(setLookups(lookupsResult.data));
    const amountOfPages = Math.ceil(postersResult.data.count / 100) || 0;
    yield put(setAmountOfPages(amountOfPages));
    yield put(setPosters(postersResult.data.results));
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

export function* liveReportModeratorSaga() {
  yield all([takeEvery(getModeratorReports.type, getModeratorReportsSaga)]);
  yield all([takeEvery(applyFilter.type, applyFilterSaga)]);
  yield all([takeEvery(getModeratorPosters.type, getModeratorPostersSaga)]);
  yield all([takeEvery(getLookups.type, getLookupsSaga)]);
  yield all([takeEvery(approveSinglePoster.type, approveSinglePosterSaga)]);
  yield all([takeEvery(approveAllPosters.type, approveAllPostersSaga)]);
  yield all([takeEvery(addPosterComment.type, addPosterCommentSaga)]);
}

type PartialRootState = {
  [reducerName]: ReturnType<typeof slice.reducer>;
};

export interface LiveReportModeratorStateType {
  fetchProcess: boolean;
  postersList: PosterType[] | null;
  filterList: OptionsType | null;
  lookupsList: FilterType | null;
  amountOfPages: number;
}

export default slice.reducer;

import React from 'react';
import styled from 'styled-components';
import TextInput from '../../atoms/form/TextInput';
import FormMessageContainer from '../../features/FormMessageContainer';
import { RestorePassFormProps } from '../../types/authTypes';
import DefaultButton from '../../atoms/buttons/DefaultButton';

const ValidationCodeForm = ({ errors, values, onSubmit, onFieldChange }: RestorePassFormProps) => {
  return (
    <CodeFormContainer onSubmit={onSubmit}>
      <Title>Please, check your email</Title>
      <p>We've sent you the validation code.</p>
      <InputContainer>
        <TextInput
          type="text"
          placeholder="Enter the validation code..."
          value={values.validationCode}
          error={!!errors.validationCode}
          onChange={onFieldChange('validationCode')}
        />
      </InputContainer>
      <MessageContainer>
        <FormMessageContainer minHeight={80} errors={errors} />
      </MessageContainer>
      <ButtonContainer>
        <DefaultButton isSubmit>Submit</DefaultButton>
      </ButtonContainer>
    </CodeFormContainer>
  );
};

const CodeFormContainer = styled.form`
  padding-top: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: var(--mainText);
    margin: 0;
  }
`;

const Title = styled.h2.attrs({
  className: 'font-h1',
})`
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--elementBackground);
  text-align: center;
`;

const InputContainer = styled.div`
  width: 100%;
  padding-top: 70px;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const ButtonContainer = styled.div`
  width: 270px;
`;

export default ValidationCodeForm;

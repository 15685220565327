export default <T, U>(searchValue: T, searchWords: U) => {
  if (!searchValue || typeof searchValue !== 'object') return {};
  if (!searchWords || typeof searchWords !== 'string') return {};
  return Object.entries(searchValue).reduce((acc, [key, value]) => {
    if (!Array.isArray(value)) return acc;
    const resultArray = [];
    value.forEach((entryObj) => {
      if (typeof entryObj !== 'object') return acc;
      const re = new RegExp(searchWords, 'i');
      if (
        Object.values(entryObj).some((element) => {
          if (typeof element !== 'string') return false;
          return element.match(re);
        })
      ) {
        resultArray.push(entryObj);
      }
      return acc;
    });
    if (!resultArray.length) {
      return acc;
    }
    return {
      ...acc,
      [key]: resultArray,
    };
  }, {} as T);
};

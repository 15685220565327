import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { all, put, takeEvery, call } from 'redux-saga/effects';
import { setError, setServerError } from './requestApiError';
import { defaultError } from '../../constants/errors/errorData';
import { getSearchValue } from '../../api/search';
import createSearchResultObj from '../../utils/createSearchResultObj';
import { logOut } from '../../utils/auth';

const initialState: SearchValueStateType = {
  searchValue: null,
  fetchProcess: false,
  searchWords: null,
};

const reducerName = 'search';

const slice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setFetchProcess: (state, action: PayloadAction<boolean>) => {
      state.fetchProcess = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<SearchValueObjType>) => {
      state.searchValue = action.payload;
    },
    setSearchWords: (state, action: PayloadAction<string>) => {
      state.searchWords = action.payload;
    },
  },
});

export const { setFetchProcess, setSearchValue, setSearchWords } = slice.actions;

export const getSearchValues = createAction<string, any>(`${reducerName}/getSearchValues`);

export const fetchProcessSelector = ({ search }: PartialRootState) =>
  search.fetchProcess;
export const searchResultsSelector = ({ search }: PartialRootState) => {
  const { searchValue, searchWords } = search;
  if (searchWords && searchValue) {
    return createSearchResultObj(searchValue, searchWords);
  }
  return searchValue;
};

function* getSearchValuesSaga({ payload }) {
  yield put(setFetchProcess(true));
  try {
    const result = yield call(getSearchValue, payload);
    yield put(setSearchValue(result.data));
    yield put(setFetchProcess(false));
  } catch (e) {
    const { data, status } = e.response;
    yield put(setFetchProcess(false));
    if (status >= 500) {
      yield put(setServerError(status));
      return;
    }
    if (status === 401) {
      yield logOut();
      return;
    }
    if (status === 403 && data.error === 'status') {
      yield put(
        setError({
          status,
          data: {
            msg: data.detail[0],
          },
        })
      );
      return;
    }
    yield put(setError(defaultError));
  }
}

export function* searchValuesSaga() {
  yield all([takeEvery(getSearchValues.type, getSearchValuesSaga)]);
}

type PartialRootState = {
  [reducerName]: ReturnType<typeof slice.reducer>;
};

export interface SearchValueStateType {
  searchValue: SearchValueObjType | null;
  fetchProcess: boolean;
  searchWords: string | null;
}

export interface SearchValueObjType {
  [key: string]: ObjType[];
}

interface ObjType {
  [key: string]: string | number;
}

export default slice.reducer;

import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import AuthTemplate from '../templates/AuthTemplate';
import EmailForm from '../organisms/restorePassword/EmailForm';
import NewPasswordForm from '../organisms/restorePassword/NewPasswordForm';
import ValidationCodeForm from '../organisms/restorePassword/ValidationCodeForm';
import ChangesSuccessForm from '../organisms/restorePassword/ChangesSuccessForm';

import { setServerError } from '../redux/ducks/requestApiError';
import MainLoader from '../atoms/loaders/MainLoader';
import { checkEmptyFields, checkEmptyObj } from '../utils/validator';
import { RestorePassForm, DefaultErrorObj } from '../types/authTypes';
import { restorePassEmail, restorePassConfirm, checkValidationCode } from '../api/userAuth';

const restorePassState = {
  email: '',
  password: '',
  validationCode: '',
};

const RestorePasswordPage = () => {
  const [step, setStep] = React.useState(1);
  const [fetchProcess, setFetchProcess] = React.useState(false);
  const [errors, setError] = React.useState<DefaultErrorObj>({});
  const [formState, setFormState] = React.useState<RestorePassForm>(restorePassState);

  const dispatch = useDispatch();

  const checkEmailExist = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setFetchProcess(true);
    const newError = checkEmptyFields({ email: formState.email });
    if (checkEmptyObj(newError)) {
      setFetchProcess(false);
      return setError(newError);
    }
    return restorePassEmail(formState)
      .then((response) => {
        setError({});
        setFetchProcess(false);
        setStep(2);
      })
      .catch((error) => {
        const { data, status } = error.response;
        setFetchProcess(false);
        if (status >= 500) {
          dispatch(setServerError(status));
          return;
        }
        setError(data);
      });
  };

  const resetForm = () => {
    setStep(1);
  };

  const checkCodeExist = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setFetchProcess(true);
    const newError = checkEmptyFields({
      validationCode: formState.validationCode,
    });
    if (checkEmptyObj(newError)) {
      setFetchProcess(false);
      return setError(newError);
    }
    return checkValidationCode({ email: formState.email, validationCode: formState.validationCode })
      .then((response) => {
        setError({});
        setFetchProcess(false);
        setStep(3);
      })
      .catch((error) => {
        const { data, status } = error.response;
        setFetchProcess(false);
        if (status >= 500) {
          dispatch(setServerError(status));
          return;
        }
        setError(data);
      });
  };

  const setNewPassword = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setFetchProcess(true);
    const newError = checkEmptyFields({ password: formState.password });
    if (checkEmptyObj(newError)) {
      setFetchProcess(false);
      return setError(newError);
    }
    setFetchProcess(false);
    return restorePassConfirm(formState)
      .then((response) => {
        setError({});
        setFetchProcess(false);
        setStep(4);
      })
      .catch((error) => {
        const { data, status } = error.response;
        setFetchProcess(false);
        if (status >= 500) {
          dispatch(setServerError(status));
          return;
        }
        if (data.validationCode) {
          setStep(2);
        }
        setError(data);
      });
  };

  const handleInputChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFormState({
      ...formState,
      [key]: newValue,
    });
  };

  const getRestorePageContent = () => {
    switch (step) {
      case 1:
        return (
          <EmailForm
            errors={errors}
            values={formState}
            onFieldChange={handleInputChange}
            onSubmit={checkEmailExist}
          />
        );
      case 2:
        return (
          <ValidationCodeForm
            errors={errors}
            values={formState}
            onFieldChange={handleInputChange}
            onSubmit={checkCodeExist}
          />
        );
      case 3:
        return (
          <NewPasswordForm
            errors={errors}
            values={formState}
            onFieldChange={handleInputChange}
            onSubmit={setNewPassword}
          />
        );
      default:
        return <ChangesSuccessForm resetForm={resetForm} />;
    }
  };

  const BodyContent = () => (
    <>
      {fetchProcess && <MainLoader />}
      <BodyContainer>{getRestorePageContent()}</BodyContainer>
    </>
  );

  return <AuthTemplate body={BodyContent()} isFooterHide />;
};

const BodyContainer = styled.div`
  padding-bottom: 48px;
  width: 368px;
`;

export default RestorePasswordPage;

import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import MiniAvatar from '../static/images/mini_avatar_default.svg';
import PaperBoxModal from '../molecules/PaperBoxModal';
import UserMenuModal from './UserMenuModal';

const UserMenu = () => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const handleClick = () => {
    menuIsOpen ? setMenuIsOpen(false) : setMenuIsOpen(true);
  };

  return (
    <UserMenuContainer>
      <ReactSVG src={MiniAvatar} onClick={handleClick} />
      {menuIsOpen && (
        <StyledPaperBoxModal closeModal={handleClick}>
          <UserMenuModal />
        </StyledPaperBoxModal>
      )}
    </UserMenuContainer>
  );
};

const UserMenuContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const StyledPaperBoxModal = styled(PaperBoxModal)`
  top: 65px;
  right: 0;
`;

export default UserMenu;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AuthErrors from '../constants/errors/authErrors.enum';
import TextInput from '../atoms/form/TextInput';
import PasswordInput from '../atoms/form/PasswordInput';
import DefaultButton from '../atoms/buttons/DefaultButton';
import FormMessageContainer from '../features/FormMessageContainer';
import { LogInFormState, ErrorArrayedObj } from '../types/authTypes';
import RoutesTypes from '../pages/routes.enum';
import SupportMessage from '../molecules/SupportMessage';

const LogInForm = ({ values, errors, onSubmit, onFieldChange }: LogInFormTypes) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <RegistrationFormContainer onSubmit={handleClick}>
      <InputContainer>
        <TextInput
          type="email"
          placeholder="Email address..."
          onChange={onFieldChange('email')}
          value={values.email}
          error={!!errors.email}
        />
      </InputContainer>
      <InputContainer>
        <PasswordInput
          placeholder="Password..."
          onChange={onFieldChange('password')}
          value={values.password}
          error={!!errors.password}
        />
      </InputContainer>
      <StyledLink to={RoutesTypes.RESTORE_PASSWORD}>Forgot password?</StyledLink>
      <MessageContainer>
        <FormMessageContainer minHeight={80} errors={errors} />
      </MessageContainer>
      <ButtonContainer>
        <DefaultButton isSubmit>Log In</DefaultButton>
      </ButtonContainer>
      <SupportMessageContainer>
        {((Array.isArray(errors.status) && errors.status.includes(AuthErrors.AWAITING)) ||
          (Array.isArray(errors.status) && errors.status.includes(AuthErrors.DECLINED))) && (
          <SupportMessage />
        )}
      </SupportMessageContainer>
    </RegistrationFormContainer>
  );
};

const RegistrationFormContainer = styled.form`
  width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.div`
  margin: 8px 0;
  width: 100%;
`;

const StyledLink = styled(Link).attrs({
  className: 'font-body4',
})`
  align-self: start;
  padding-left: 15px;
  color: var(--secondaryText);
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const SupportMessageContainer = styled.div`
  width: 304px;
  text-align: center;
  padding: 16px 0 22px 0;
  height: 80px;

  p {
    margin 0;
  }
`;

const ButtonContainer = styled.div`
  width: 270px;
`;

interface LogInFormTypes {
  values: LogInFormState;
  onSubmit: () => void;
  onFieldChange: (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: ErrorArrayedObj;
}

export default LogInForm;

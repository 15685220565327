import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '../static/images/avatar_default.svg';
import { userInfo, clearUserInfo } from '../redux/ducks/userAuth';
import DefaultButton from '../atoms/buttons/DefaultButton';
import RoutesTypes from '../pages/routes.enum';
import { logOut } from '../utils/auth';

const UserMenu = () => {
  const userInfoSelector = useSelector(userInfo);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const pushToSettings = () => {
    push(RoutesTypes.USER_SETTINGS);
  };

  const logOutUser = () => {
    dispatch(clearUserInfo());
    logOut();
  };

  return (
    <UserMenuModalContainer>
      <AvatarContainer>
        <ReactSVG src={Avatar} />
      </AvatarContainer>
      <h2 className="font-h1">{`${userInfoSelector.firstName} ${userInfoSelector.lastName}`}</h2>
      <p className="font-body1">{userInfoSelector.email}</p>
      <ButtonContainer>
        <DefaultButton onClick={pushToSettings}>Manage your Account</DefaultButton>
      </ButtonContainer>
      <DefaultButton onClick={logOutUser} isLight>
        Logout
      </DefaultButton>
    </UserMenuModalContainer>
  );
};

const UserMenuModalContainer = styled.div`
  text-align: center;
  position: relative;
  padding: 32px 49px;
  color: var(--mainText);

  h2 {
    margin: 16px 0 5px 0;
  }

  p {
    margin: 0;
  }
`;

const AvatarContainer = styled.div`
  width: 100%;
  text-align: center;
  min-height: 64px;
`;

const ButtonContainer = styled.div`
  margin: 24px 0 16px 0;
`;

export default UserMenu;

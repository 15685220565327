import { combineReducers } from '@reduxjs/toolkit';
import { fork } from 'redux-saga/effects';
import userAuth from './ducks/userAuth';
import requestApiError from './ducks/requestApiError';
import search, { searchValuesSaga } from './ducks/search';
import liveReport, { liveReportSaga } from './ducks/liveReport';
import liveReportModerator, { liveReportModeratorSaga } from './ducks/liveReportModerator';

const rootReducer = combineReducers({
  requestApiError,
  userAuth,
  search,
  liveReport,
  liveReportModerator,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield fork(searchValuesSaga);
  yield fork(liveReportSaga);
  yield fork(liveReportModeratorSaga);
}

export default rootReducer;

import axios from 'axios';
import { api } from './api';
import { ModeratorIssueStatus } from '../types/liveReport';
import { getAccessToken } from '../utils/auth';

export function getBrandApi<BrandRequest>(query) {
  return api.request({
    method: 'GET',
    url: '/brands/',
    params: query,
  });
}

export function getPostersApi<BrandRequest>(query: string) {
  return api.request({
    method: 'GET',
    url: `/posters/${query}`,
  });
}

export function getPosterApi(id: string) {
  return api.request({
    method: 'GET',
    url: `/posters/${id}/`,
  });
}

export function getCSVFileApi(query: string) {
  return api.request({
    method: 'GET',
    headers: {
      common: {
        Accept: 'text/csv',
      },
    },
    url: `/posters/get_csv/${query}`,
  });
}

export function getMetricsApi(query: string) {
  return api.request({
    method: 'GET',
    url: `/posters/get_metrics/${query}`,
  });
}

export function getLookupsApi(query: string) {
  return api.request({
    method: 'GET',
    url: `/posters/get_lookups/${query}`,
  });
}

export function getMapMarkersCoordinates(query: string) {
  return api.request({
    method: 'GET',
    url: `/posters/get_coordinates/${query}`,
  });
}

export function getModeratorPostersApi(query: string) {
  return api.request({
    method: 'GET',
    url: `/posters/moderator_view/${query}`,
  });
}

export function approveSinglePosterApi(id: number) {
  return api.request({
    method: 'PATCH',
    url: `/posters/moderator_view/${id}/`,
    data: {
      moderatorIssue: ModeratorIssueStatus.APPROVED,
    },
  });
}

export function approveAllPostersApi(idArray: [number]) {
  return api.request({
    method: 'POST',
    url: '/posters/moderator_view/multiple_approve/',
    data: {
      ids: idArray,
    },
  });
}

export function getPDFApi({ formData, queryStr }) {
  // make into api.request
  return axios.post(`/api/v2/posters/get_pdf/${queryStr}`, formData, {
    responseType: 'blob',
    headers: { Authorization: `JWT ${getAccessToken()}` },
  });
}

export function addPosterCommentApi(data) {
  const { id, comment } = data;
  return api.request({
    method: 'PATCH',
    url: `/posters/moderator_view/${id}/`,
    data: {
      moderatorIssue: ModeratorIssueStatus.ISSUE,
      moderatorComment: comment,
    },
  });
}
interface BrandRequest {
  query: DefaultErrorObj;
}

interface DefaultErrorObj {
  [key: string]: string;
}

import React from 'react';
import styled from 'styled-components';
import DashboardTemplate from '../templates/DashboardTemplate';
import Search from '../organisms/Search';
import DashboardTopRateCampaigns from '../organisms/DashboardTopRateCampaigns';
import RoutesTypes from './routes.enum';

const DashboardPage = () => {
  return (
    <DashboardTemplate
      body={
        <DashboardContainer>
          <Search pageRoute={RoutesTypes.LIVE_REPORT} />
          <DashboardTopRateCampaigns />
        </DashboardContainer>
      }
    />
  );
};

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 96px 160px 96px;
  width: 100%;
`;

export default DashboardPage;

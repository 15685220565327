import { apiPublic, api } from './api';
import {
  RegistrationRequestForm,
  LogInFormState,
  RestorePassForm,
  checkValidationCodeType,
  SupportFormState,
  UserSettingsFormState,
} from '../types/authTypes';

export function registerUser(data: RegistrationRequestForm) {
  return apiPublic.request({
    method: 'POST',
    url: '/accounts/registration/',
    data,
  });
}

export function logInUser(data: LogInFormState) {
  return apiPublic.request({
    method: 'POST',
    url: '/accounts/login/',
    data,
  });
}

export function restorePassEmail(data: { email: string }) {
  return apiPublic.request({
    method: 'POST',
    url: '/accounts/password/reset/',
    data,
  });
}

export function restorePassConfirm(data: RestorePassForm) {
  return apiPublic.request({
    method: 'POST',
    url: '/accounts/password/reset/confirm/',
    data,
  });
}

export function checkValidationCode(data: checkValidationCodeType) {
  return apiPublic.request({
    method: 'POST',
    url: '/accounts/password/check_validation_code/',
    data,
  });
}

export function getClients() {
  return apiPublic.request({
    method: 'GET',
    url: '/clients/',
  });
}

export function supportRequest(data: SupportFormState) {
  return api.request({
    method: 'POST',
    url: '/accounts/send_message/support/',
    data,
  });
}

export function updateUserInfo(data: UserSettingsFormState, id: number) {
  return api.request({
    method: 'PATCH',
    url: `/me/${id}/`,
    data,
  });
}

import React from 'react';
import styled from 'styled-components';
import { PosterType } from '../types/liveReport';
import { changeToValidDate } from '../utils/formatDate';
import DefaultModal from './DefaultModal';

const PostDataCard = React.memo(
  ({ postData, className, forwardRef, isModerator, isShowCardImage }: PostProps) => {
    const [pictureModalIsOpen, setPictureModalIsOpen] = React.useState(false);

    const handleModalOpen = () => {
      setPictureModalIsOpen(true);
    };

    return (
      <>
        <PostDataCardContainer className={className} ref={forwardRef}>
          <ImageContainer onClick={isShowCardImage ? handleModalOpen : null} isShowCardImage>
            <img src={`${postData.photo}`} alt="" />
          </ImageContainer>
          <DescriptionContainer>
            <Title>Campaign/Design</Title>
            <p>{postData.campaign}</p>
            <p>{postData.design}</p>
          </DescriptionContainer>
          <DescriptionContainer>
            <Title>Media Owner</Title>
            <p>{postData.mediaOwner}</p>
          </DescriptionContainer>
          <DescriptionContainer>
            <Title>Posting Date</Title>
            <p>{changeToValidDate(postData.postingDate)}</p>
          </DescriptionContainer>
          <DescriptionContainer>
            <Title>Size</Title>
            <p>{postData.size}</p>
          </DescriptionContainer>
          <DescriptionContainer>
            <Title>Location</Title>
            <p>{postData.address}</p>
          </DescriptionContainer>
          <DescriptionContainer>
            <Title>Billposter Issue</Title>
            <p>{postData.billposterIssue ? 'Yes' : 'No'}</p>
          </DescriptionContainer>
          <DescriptionContainer>
            <Title>Billposter comment</Title>
            <p>{postData.billposterComment}</p>
          </DescriptionContainer>
          <DescriptionContainer>
            <Title>Moderator comment</Title>
            <p>{postData.moderatorComment}</p>
          </DescriptionContainer>
        </PostDataCardContainer>
        {pictureModalIsOpen && (
          <DefaultModal closeModal={() => setPictureModalIsOpen(false)}>
            <img src={postData.photo} alt="poster_image" />
          </DefaultModal>
        )}
      </>
    );
  }
);

const PostDataCardContainer = styled.div`
  width: 276px;
  padding: 16px;

  p {
    word-break: break-all;
  }
`;

const ImageContainer = styled(({ isShowCardImage, ...rest }) => <div {...rest} />)`
  width: 100%;
  height: 216px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.isShowCardImage && 'cursor: pointer;'}

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
`;

const DescriptionContainer = styled.div`
  margin: 4px 0;

  p {
    margin: 0;
    font-size: 12px;
    color: var(--mainText);
  }
`;

const Title = styled.h2.attrs({
  className: 'font-body2',
})`
  font-size: 14px;
  font-weight: bold;
  color: var(--mainText);
  margin: 0;
`;

interface PostProps {
  postData?: PosterType;
  className?: string;
  forwardRef?: React.Ref<HTMLDivElement>;
  isModerator?: boolean;
  isShowCardImage?: boolean;
}

export default PostDataCard;

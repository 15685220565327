export default (initState, queryObj) => {
  let newObj = { ...initState };
 
  Object.entries(queryObj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const valueArray = [];
      value.forEach((element) => {
        if (key === 'issue') {
          // element === 'true' I use it to convert input data from a string to a boolean type
          valueArray.push({ value: element === 'true', label: [''] });
        } else {
          valueArray.push({ value: element, label: [''] });
        }
      });
      newObj = {
        ...newObj,
        [key]: valueArray,
      };
      return;
    }

    if (key === 'issue') {
      newObj = {
        ...newObj,
        [key]: [
          {
            value: value === 'true', // value === 'true' I use it to convert input data from a string to a boolean type
            label: [''],
          },
        ],
      };
    } else {
      newObj = {
        ...newObj,
        [key]: [
          {
            value,
            label: [''],
          },
        ],
      };
    }
  }, {});

  return newObj;
};

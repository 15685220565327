import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  mainBackground: '#F1F1F1',
  secondaryBackground: '#F3F6F9',
  modalWindowBackground: 'rgba(0 0 0 / 0.4)',
  defaultBody: '#FFFFFF',
  transparentBackground: 'rgba(243 246 249 / 0.5)',
  elementBackground: '#172E46',
  darkHover: '#222A33',
  mainAccent: '#960018',
  mainText: '#373737',
  secondaryText: '#787878',
  footerLightText: '#C2C2C2',
  mainObjects: '#B7B7B7',
};

export default theme;

export interface Campaign {
  campaign: string;
  client: string;
  company: number;
  companyName: string;
  design: number;
  designName: string;
  id: number;
  image: string;
  inCharge: string;
  refId: string;
  size: string;
}

export interface Brand {
  client: number;
  id: number;
  logo: string;
  name: string;
  campaigns: string[];
}

export interface FilterType {
  campaign: Option[];
  refId: Option[];
  mediaOwner: Option[];
  design: Option[];
  inCharge: Option[];
  size: Option[];
  issue: Option[];
  moderatorIssue?: Option[];
}

export interface OptionsType {
  [key: string]: Option[];
}

export interface Option {
  value: string | boolean;
  label: string[];
}

export interface DefaultObj {
  [key: string]: string | any[] | number;
}

export interface MetricsType {
  posted: MetricObjType;
  postedIssue: MetricObjType;
  postedLate: MetricObjType;
  toBePosted: MetricObjType;
  audience: MetricObjType;
  totalLiveHours: MetricObjType;
}

export interface MetricObjType {
  count: number;
  percent: number;
}

export interface MapMarker {
  id: number;
  latitude: string;
  longitude: string;
  campaign: string;
}

export interface PosterType {
  id: number;
  latitude: string;
  longitude: string;
  mediaOwner: number;
  address: string;
  billposterComment: string;
  billposterEmail: string;
  billposterIssue: boolean;
  moderatorComment: string;
  moderatorIssue: boolean;
  photo: string;
  posterDate: string;
  reference: number;
  uuid: string;
  campaign: string;
  design: string;
  client: string;
  postingDate: string;
  size: string;
}

export enum ModeratorIssueStatus {
  APPROVED = 'approved',
  AWAITING = 'awaiting',
  ISSUE = 'issue',
}

import React from 'react';
import styled from 'styled-components';
import TextInput from '../atoms/form/TextInput';
import PasswordInput from '../atoms/form/PasswordInput';
import DefaultButton from '../atoms/buttons/DefaultButton';
import SelectInput from '../atoms/form/SelectInput';
import FormMessageContainer from '../features/FormMessageContainer';
import SupportMessage from '../molecules/SupportMessage';
import AuthErrors from '../constants/errors/authErrors.enum';
import {
  RegistrationFormState,
  ClientObjType,
  ErrorArrayedObj,
  OptionType,
} from '../types/authTypes';

const RegistrationForm = ({
  values,
  errors,
  options,
  onSubmit,
  onFieldChange,
  handleSelect,
}: RegistrationFormTypes) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <RegistrationFormContainer onSubmit={handleClick}>
      <DoubleInputContainer>
        <TextInput
          type="text"
          placeholder="First name..."
          onChange={onFieldChange('firstName')}
          value={values.firstName}
          error={!!errors.firstName}
        />
        <TextInput
          type="text"
          placeholder="Last name..."
          onChange={onFieldChange('lastName')}
          value={values.lastName}
          error={!!errors.lastName}
        />
      </DoubleInputContainer>
      <InputContainer>
        <SelectInput
          placeholder="Client..."
          value={values.client}
          options={options}
          error={!!errors.client}
          handleSelect={handleSelect('client')}
        />
      </InputContainer>
      <InputContainer>
        <TextInput
          type="email"
          placeholder="Email..."
          onChange={onFieldChange('email')}
          value={values.email}
          error={!!errors.email}
        />
      </InputContainer>
      <InputContainer>
        <PasswordInput
          placeholder="Password..."
          onChange={onFieldChange('password')}
          value={values.password}
          error={!!errors.password}
        />
      </InputContainer>
      <MessageContainer>
        <FormMessageContainer minHeight={100} errors={errors} />
      </MessageContainer>
      <ButtonContainer>
        <DefaultButton isSubmit>Register</DefaultButton>
      </ButtonContainer>
      <SupportMessageContainer>
        {((Array.isArray(errors.status) && errors.status.includes(AuthErrors.AWAITING)) ||
          (Array.isArray(errors.status) && errors.status.includes(AuthErrors.DECLINED))) && (
          <SupportMessage />
        )}
      </SupportMessageContainer>
    </RegistrationFormContainer>
  );
};

const RegistrationFormContainer = styled.form`
  width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.div`
  margin: 8px 0;
  width: 100%;
`;

const DoubleInputContainer = styled.div`
  display: flex;
  margin: 8px 0;
  width: calc(100% + 16px);

  & > div {
    margin: 0 8px;
  }
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const SupportMessageContainer = styled.div`
  width: 304px;
  text-align: center;
  padding: 16px 0 22px 0;
  height: 80px;

  p {
    margin 0;
  }
`;

const ButtonContainer = styled.div`
  width: 270px;
`;

interface RegistrationFormTypes {
  values: RegistrationFormState;
  options: ClientObjType[] | null;
  onSubmit: () => void;
  handleSelect: (name: string) => (event: OptionType) => void;
  onFieldChange: (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: ErrorArrayedObj;
}

export default RegistrationForm;

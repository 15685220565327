import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import AuthTemplate from '../templates/AuthTemplate';
import RegistrationForm from '../organisms/RegistrationForm';
import MainLoader from '../atoms/loaders/MainLoader';
import RoutesTypes from './routes.enum';
import DefaultButton from '../atoms/buttons/DefaultButton';
import { setServerError } from '../redux/ducks/requestApiError';
import { createOptionObject } from '../utils/requestsConvertators';
import { checkEmptyFields, checkEmptyObj } from '../utils/validator';
import { registerUser, getClients } from '../api/userAuth';
import { RegistrationFormState, OptionType } from '../types/authTypes';

const initFormState: RegistrationFormState = {
  firstName: '',
  lastName: '',
  client: null,
  email: '',
  password: '',
};

const RegistrationPage = () => {
  const [formState, setFormState] = React.useState(initFormState);
  const [errors, setError] = React.useState({});
  const [fetchProcess, setFetchProcess] = React.useState(false);
  const [registrationIsOver, setRegistrationIsOver] = React.useState(false);
  const [selectOptions, setSelectOptions] = React.useState<OptionType[]>([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    setFetchProcess(true);
    const getOptions = () =>
      getClients()
        .then((response) => {
          const { results } = response.data;
          const optionList = createOptionObject(results, 'id', 'name');
          setSelectOptions(optionList);
          setFetchProcess(false);
        })
        .catch((error) => {
          const { status } = error.response;
          setFetchProcess(false);
          setSelectOptions([]);
          setFetchProcess(false);
          if (status >= 500) {
            dispatch(setServerError(status));
          }
        });

    getOptions();
  }, [dispatch]);

  const handleChangeField = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFormState({
      ...formState,
      [key]: newValue,
    });
  };

  const handleSelect = (key: string) => (selectOption: OptionType) => {
    setFormState({
      ...formState,
      [key]: selectOption,
    });
  };

  const postData = () => {
    setFetchProcess(true);
    const newError = checkEmptyFields(formState);
    if (checkEmptyObj(newError)) {
      setFetchProcess(false);
      return setError(newError);
    }
    const { client } = formState;
    const validRequestData = {
      ...formState,
      client: client?.value || null,
    };
    return registerUser(validRequestData)
      .then(() => {
        setError({});
        setFetchProcess(false);
        setRegistrationIsOver(true);
      })
      .catch((error) => {
        const { data, status } = error.response;
        setFetchProcess(false);
        if (status >= 500) {
          dispatch(setServerError(status));
          return;
        }
        setError(data);
      });
  };

  const FooterContent = () => (
    <FooterText className="font-body3">
      Already have an account?{' '}
      <Link to={RoutesTypes.LOG_IN} className="font-body2">
        Log in.
      </Link>
    </FooterText>
  );

  const registrationProcess = (
    <>
      <RegistrationTitle>Register into PoP</RegistrationTitle>
      <RegistrationForm
        onFieldChange={handleChangeField}
        handleSelect={handleSelect}
        values={formState}
        errors={errors}
        options={selectOptions}
        onSubmit={postData}
      />
    </>
  );

  const registrationFinished = (
    <RegistrationFinishedContainer>
      <RegistrationTitle>Thank you for the registration!</RegistrationTitle>
      <MessageContainer>
        You will be able to log in after we approve your account (takes up to 48 hours). If you have
        any questions contact <a href="mailto:support@pcds.co.uk">support@pcds.co.uk</a>
      </MessageContainer>
      <ButtonContainer>
        <Link to="/">
          <DefaultButton>Home page</DefaultButton>
        </Link>
      </ButtonContainer>
    </RegistrationFinishedContainer>
  );

  const BodyContent = () => (
    <BodyContainer>
      {fetchProcess && <MainLoader />}
      {registrationIsOver ? registrationFinished : registrationProcess}
    </BodyContainer>
  );

  return (
    <AuthTemplate body={BodyContent()} footer={FooterContent()} isFooterHide={registrationIsOver} />
  );
};

const FooterText = styled.p`
  color: var(--mainText);
`;

const BodyContainer = styled.div``;

const RegistrationTitle = styled.h2.attrs({
  className: 'font-h1',
})`
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--elementBackground);
  text-align: center;
`;

const RegistrationFinishedContainer = styled.div`
  width: 400px;
  padding: 112px 0 128px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MessageContainer = styled.p.attrs({
  className: 'font-body1',
})`
  margin-top: -14px;
  text-align: center;
  color: var(--mainText);

  a {
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  padding-top: 170px;
  width: 270px;
`;

export default RegistrationPage;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStoredUserInfo } from '../../utils/auth';
import { UserType } from '../../types/authTypes';

const initialState: UserAuthStateType = {
  info: getStoredUserInfo() || null,
};

const reducerName = 'userAuth';

const slice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserType>) => {
      state.info = action.payload;
    },
    clearUserInfo: (state) => {
      state.info = null;
    },
  },
});

export const { setUserInfo, clearUserInfo } = slice.actions;

export const userInfo = ({ userAuth }: PartialRootState) => userAuth.info;
export const userIdInfoSelector = ({ userAuth }: PartialRootState) => userAuth.info?.id;
export const userCompanySelector = ({ userAuth }: PartialRootState) => userAuth.info?.client;
export const userRoleSelector = ({ userAuth }: PartialRootState) => userAuth.info?.userRole;
export const userInfoSettingsSelector = ({ userAuth }: PartialRootState) => {
  const { info } = userAuth;
  return {
    firstName: info.firstName,
    lastName: info.lastName,
    client: info.client,
    email: info.email,
  };
};

export const isModeratorSelector = ({ userAuth }: PartialRootState) => {
  const { userRole } = userAuth.info;
  if (userRole === 'moderator') {
    return true;
  }
  if (userRole === 'super_moderator') {
    return true;
  }
  return false;
};

type PartialRootState = {
  [reducerName]: ReturnType<typeof slice.reducer>;
};

export interface UserAuthStateType {
  info: UserType;
}

export default slice.reducer;

import React from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RoutesTypes from '../pages/routes.enum';
import history from '../history';
import { isModeratorSelector } from '../redux/ducks/userAuth';

const BurgerNavMenu = () => {
  const isModerator = useSelector(isModeratorSelector);
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname === RoutesTypes.LIVE_REPORT) {
      return history.push(`${RoutesTypes.MODERATOR_LIVE_REPORT}${location.search}`);
    }
    return history.push(`${RoutesTypes.MODERATOR_LIVE_REPORT}`);
  };

  return (
    <MenuContainer>
      <StyledLink to={RoutesTypes.DASHBOARD}>Dashboard</StyledLink>
      {isModerator && <StyledModeratorLink onClick={handleClick}>Moderation</StyledModeratorLink>}
      <StyledLink to="/faq">FAQ</StyledLink>
      <StyledLink to={RoutesTypes.SUPPORT}>Contact Support</StyledLink>
    </MenuContainer>
  );
};

const styledLinkStyles = `
  padding: 12px 0 12px 24px;
  color: var(--elementBackground);

  &:hover {
    background: var(--secondaryBackground);
  }

  &.active {
    color: var(--mainAccent);
    background: var(--secondaryBackground);
  }
`;

const StyledLink = styled(NavLink).attrs({
  className: 'font-h2',
})`
  ${styledLinkStyles}
`;

const StyledModeratorLink = styled.h2.attrs({
  className: 'font-h2',
})`
  cursor: pointer;
  margin: 0;
  ${styledLinkStyles}
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  width: 196px;
`;

export default BurgerNavMenu;

import React from 'react';
import styled from 'styled-components';
import CustomFilterSelect from '../../molecules/CustomFilterSelect';
import DefaultButton from '../../atoms/buttons/DefaultButton';
import { FilterType, Option, OptionsType } from '../../types/liveReport';

const LiveReportFilterForm = ({
  options,
  value,
  handleSelect,
  handleDeleteValue,
  handleClearValue,
  clearAllFilters,
  applyFilters,
  isModeratorPage,
}: FilterFormProps) => {

  return (
    <FilterFormContainer>
      <StyledCustomFilterSelect
        options={options.campaign || []}
        label="Campaign Name"
        value={value.campaign || []}
        handleSelect={handleSelect('campaign')}
        handleDeleteValue={handleDeleteValue('campaign')}
        handleClearValue={handleClearValue('campaign')}
      />
      <StyledCustomFilterSelect
        options={options.design || []}
        label="Design"
        value={value.design || []}
        handleSelect={handleSelect('design')}
        handleDeleteValue={handleDeleteValue('design')}
        handleClearValue={handleClearValue('design')}
      />
      <StyledCustomFilterSelect
        options={options.mediaOwner || []}
        label="Media owner"
        value={value.mediaOwner || []}
        handleSelect={handleSelect('mediaOwner')}
        handleDeleteValue={handleDeleteValue('mediaOwner')}
        handleClearValue={handleClearValue('mediaOwner')}
      />
      <StyledCustomFilterSelect
        options={options.refId || []}
        label="Ref Id"
        value={value.refId || []}
        handleSelect={handleSelect('refId')}
        handleDeleteValue={handleDeleteValue('refId')}
        handleClearValue={handleClearValue('refId')}
      />
      <StyledCustomFilterSelect
        options={options.size || []}
        label="Size"
        value={value.size || []}
        handleSelect={handleSelect('size')}
        handleDeleteValue={handleDeleteValue('size')}
        handleClearValue={handleClearValue('size')}
      />
      <StyledCustomFilterSelect
        options={options.inCharge || []}
        label="IC date"
        value={value.inCharge || []}
        handleSelect={handleSelect('inCharge')}
        handleDeleteValue={handleDeleteValue('inCharge')}
        handleClearValue={handleClearValue('inCharge')}
      />
      <StyledCustomFilterSelect
        options={options.issue || []}
        label="Issue"
        value={value.issue || []}
        handleSelect={handleSelect('issue')}
        handleDeleteValue={handleDeleteValue('issue')}
        handleClearValue={handleClearValue('issue')}
        isOneValue
        isBooleanValue
      />
      {isModeratorPage && (
        <StyledCustomFilterSelect
          options={options.moderatorIssue || []}
          label="Approve"
          value={value.moderatorIssue || []}
          handleSelect={handleSelect('moderatorIssue')}
          handleDeleteValue={handleDeleteValue('moderatorIssue')}
          handleClearValue={handleClearValue('moderatorIssue')}
        />
      )}
      <ButtonContainer>
        <StyledDefaultButton onClick={applyFilters}>Apply Filters</StyledDefaultButton>
        <StyledDefaultButton isLight={true} onClick={clearAllFilters}>
          Clear Filters
        </StyledDefaultButton>
      </ButtonContainer>
    </FilterFormContainer>
  );
};

const FilterFormContainer = styled.div`
  width: 100%;
  background: var(--mainBackground);
  padding: 16px;
  border-radius: 5px;
`;

const StyledCustomFilterSelect = styled(CustomFilterSelect)`
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 20px -8px 0 -8px;
`;

const StyledDefaultButton = styled(DefaultButton)`
  min-width: 70px;
  width: 50%;
  margin: 0 8px;
`;

interface FilterFormProps {
  options: FilterType;
  value: OptionsType;
  handleSelect: (key: string) => (option: Option) => void;
  handleDeleteValue: (key: string) => (value: string) => void;
  handleClearValue: (key: string) => () => void;
  clearAllFilters: () => void;
  applyFilters: () => void;
  isModeratorPage?: boolean;
}

export default LiveReportFilterForm;

import React from 'react';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import queryString from 'query-string';
import camelCaseKeys from 'camelcase-keys';
import history from '../history';
import { createLookupQuery } from '../utils/queryParamCreator';
import DashboardTemplate from '../templates/DashboardTemplate';
import MainLoader from '../atoms/loaders/MainLoader';
import {
  getLookups,
  getPosters,
  getPoster,
  skipAllValues,
  getLiveReports,
  appliedFilterSelector,
  fetchProcessSelector,
  lookupsListSelector,
  postersListSelector,
  metricsSelector,
  posterSelector,
  applyFilter,
  exportCSVFile,
  setPoster,
  getPDFFile,
  mapMarkersSelector,
  amountOfPagesSelector,
} from '../redux/ducks/liveReport';
import FilterIcon from '../static/icons/filter_icon.svg';
import LiveReportFilterForm from '../organisms/LiveReport/LiveReportFilterForm';
import ChartsSection from '../organisms/ChartsSection';
import MapSection from '../organisms/LiveReport/MapSection';
import DefaultButton from '../atoms/buttons/DefaultButton';
import SelectedFilters from '../organisms/LiveReport/SelectedFilters';
import CampaignResultView from '../organisms/LiveReport/CampaignResultView';
import createPreDefaultFilterState from '../utils/createPreDefaultFilterState';
import Search from '../organisms/Search';
import { OptionsType } from '../types/liveReport';
import RoutesTypes from './routes.enum';

const filterFormInitState = {
  campaign: [],
  design: [],
  mediaOwner: [],
  refId: [],
  size: [],
  inCharge: [],
  issue: [],
};

const LiveReportPage = () => {
  const dispatch = useDispatch();
  const query = useLocation().search;
  const location = useLocation();

  const appliedFilters = useSelector(appliedFilterSelector);
  const lookupsList = useSelector(lookupsListSelector);
  const fetchProcess = useSelector(fetchProcessSelector);
  const postersList = useSelector(postersListSelector);
  const amountOfPages = useSelector(amountOfPagesSelector);
  const metrics = useSelector(metricsSelector);
  const mapMarkers = useSelector(mapMarkersSelector);
  const poster = useSelector(posterSelector);

  const mapSectionRef = React.useRef<HTMLDivElement>();

  const [autocompleteForms, setAutocompleteForms] = React.useState(true);

  const [filterIsOpen, setFilterIsOpen] = React.useState(true);

  const [selectFilterValues, setSelectFilterValues] = React.useState<OptionsType>(
    filterFormInitState
  );
  const [page, setPage] = React.useState(1);
  const [sortValue, setSortValue] = React.useState(null);

  const handleSelect = (key) => (value) => {
    const newValue = {
      ...selectFilterValues,
      [key]: [...value],
    };
    setSelectFilterValues(newValue);
  };

  const handleDeleteValue = (key) => (valueToDelete) => {
    const newValue = {
      ...selectFilterValues,
      [key]: [...selectFilterValues[key].filter((element) => element.value !== valueToDelete)],
    };
    setSelectFilterValues(newValue);
  };

  const handleClearValue = (key) => () => {
    const newValue = {
      ...selectFilterValues,
      [key]: [],
    };
    setSelectFilterValues(newValue);
  };

  const clearFilterValue = (key, value) => {
    const newValue = {
      ...selectFilterValues,
      [key]: [...selectFilterValues[key].filter((element) => element.value !== value)],
    };
    setSelectFilterValues(newValue);
    dispatch(applyFilter(newValue));
  };

  const clearAllFilters = () => {
    setAutocompleteForms(false);
    setSelectFilterValues(filterFormInitState);
    dispatch(getLookups(filterFormInitState));
    dispatch(applyFilter(null));
  };

  const changePage = (pageNumber: number) => {
    dispatch(getPosters({ ...appliedFilters, page: pageNumber, ordering: sortValue }));
    setPage(pageNumber);
  };

  const applyFilters = () => {
    if (selectFilterValues.campaign.length === 0) {
      clearAllFilters();
    } else {
      history.push(`${location.pathname}${createLookupQuery(selectFilterValues)}`);
      dispatch(applyFilter(selectFilterValues));
    }

    dispatch(getLiveReports({ filterValues: selectFilterValues, page: 1 }));
    setPage(1);
  };

  const sortLiveReports = (valueToSort) => {
    setSortValue(valueToSort);
    dispatch(getPosters({ ...appliedFilters, page, ordering: valueToSort }));
  };

  const handleCloseFilter = () => {
    filterIsOpen ? setFilterIsOpen(false) : setFilterIsOpen(true);
  };

  const getPosterInfo = (id) => {
    if (!id) {
      return dispatch(setPoster(null));
    }
    const posterToFind = postersList.find((element) => element.id === id);
    if (posterToFind) {
      return dispatch(setPoster(posterToFind));
    }
    return dispatch(getPoster(id));
  };

  const getCSVFile = () => {
    dispatch(exportCSVFile({ ...selectFilterValues }));
  };

  const getPdfFile = () => {
    html2canvas(mapSectionRef.current, {
      useCORS: true,
      allowTaint: false,
      width: 730,
      height: 500,
      scrollY: -window.scrollY,
    }).then((getCanvas) => {
      const image = getCanvas.toDataURL('image/jpeg', 1.0);
      dispatch(getPDFFile({ image, selectFilterValues }));
    });
  };

  React.useEffect(() => {
    if (autocompleteForms) {
      setSelectFilterValues({ ...lookupsList });
    }
  }, [lookupsList, autocompleteForms]);

  React.useEffect(() => {
    return () => {
      dispatch(skipAllValues());
    };
  }, [dispatch]);

  React.useEffect(() => {
    const parsedQuery = camelCaseKeys(queryString.parse(query));
    const validObj = createPreDefaultFilterState(filterFormInitState, parsedQuery);
    dispatch(applyFilter(validObj));
    setSelectFilterValues(validObj);
    setAutocompleteForms(Object.keys(parsedQuery).length === 1);

    dispatch(getLiveReports({ filterValues: validObj, page: 1 }));
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [query]);
  
  return (
    <>
      {fetchProcess && <MainLoader />}
      <DashboardTemplate
        isFooterHide={true}
        showCompanyName={true}
        body={
          <>
            <SearchContainer>
              <Search pageRoute={RoutesTypes.LIVE_REPORT} />
            </SearchContainer>
            <LiveReportContainer>
              <ContainerRow>
                <FilterSection filterIsOpen={filterIsOpen}>
                  <FiltersTitleContainer onClick={handleCloseFilter}>
                    <IconContainer filterIsOpen={filterIsOpen}>
                      <ReactSVG src={FilterIcon} />
                    </IconContainer>
                    {filterIsOpen && <SectionTitle>Filters</SectionTitle>}
                  </FiltersTitleContainer>
                  {filterIsOpen && (
                    <LiveReportFilterForm
                      options={lookupsList}
                      value={selectFilterValues}
                      handleSelect={handleSelect}
                      handleClearValue={handleClearValue}
                      handleDeleteValue={handleDeleteValue}
                      clearAllFilters={clearAllFilters}
                      applyFilters={applyFilters}
                    />
                  )}
                </FilterSection>
                <MetricsSection filterIsOpen={filterIsOpen}>
                  <SectionTitle>Metrics</SectionTitle>
                  <ChartsSectionContainer>
                    <ChartsSection metricsData={metrics} />
                  </ChartsSectionContainer>
                  <MapSectionContainer filterIsOpen={filterIsOpen}>
                    <MapSection
                      mapMarkers={mapMarkers}
                      getPosterInfo={getPosterInfo}
                      poster={poster}
                      forwardRef={mapSectionRef}
                    />
                  </MapSectionContainer>
                </MetricsSection>
              </ContainerRow>
              <ContainerRow>
                {filterIsOpen && (
                  <SelectedFiltersContainer filterIsOpen={filterIsOpen}>
                    {appliedFilters && <MarginedSectionTitle>Selected Filters</MarginedSectionTitle>}
                    <SelectedFilters
                      appliedFilters={appliedFilters}
                      clearAllFilters={clearAllFilters}
                      clearFilterValue={clearFilterValue}
                    />
                  </SelectedFiltersContainer>
                )}
                <ContainerColumn filterIsOpen={filterIsOpen}>
                  <ExportButtonsContainer>
                    <DefaultButton onClick={getCSVFile} disabled={fetchProcess}>
                      Export CSV
                    </DefaultButton>
                    <DefaultButton onClick={getPdfFile} disabled={fetchProcess}>
                      Export PDF
                    </DefaultButton>
                  </ExportButtonsContainer>
                  <ResultContainer>
                    <CampaignResultView
                      data={postersList}
                      filterIsOpen={filterIsOpen}
                      changePage={changePage}
                      sortLiveReports={sortLiveReports}
                      amountOfPages={amountOfPages}
                      page={page}
                    />
                  </ResultContainer>
                </ContainerColumn>
              </ContainerRow>
            </LiveReportContainer>
          </>
        }
      />
    </>
  );
};

const LiveReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 0;
  width: 100%;
`;

const SearchContainer = styled.div`
  width: 100%;
`;

const FiltersTitleContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const SectionTitle = styled.p.attrs({
  className: 'font-body3',
})`
  color: var(--mainText);
  margin: 0;
  margin-left: 7px;
  min-height: 20px;
  display: flex;
  align-items: center;
`;

const MarginedSectionTitle = styled(SectionTitle)`
  margin-bottom: 10px;
`;

const ContainerRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ExportButtonsContainer = styled(ContainerRow)`
  width: 256px;
  margin: 16px 4px;
  align-self: flex-end;
  z-index: 20;

  button {
    margin: 0 4px;
    width: 124px;
    height: 32px;
    min-width: 124px;
  }
`;

const MapSectionContainer = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  height: ${(props) => (props.filterIsOpen ? 'calc(100% - 172px);' : '728px')};
`;

const ChartsSectionContainer = styled.div`
  margin-bottom: 16px;
  height: 136px;
`;

const ContainerColumn = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.filterIsOpen ? '66.6%' : '100%')};
  height: 100%;
  margin-left: ${(props) => (props.filterIsOpen ? '8px' : '0')};
`;

const SelectedFiltersContainer = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  width: ${(props) => (props.filterIsOpen ? '33.3%;' : '0%')};
  margin-right: 8px;
  margin-top: 32px;
`;

const ResultContainer = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  margin-top: -32px;
`;

const FilterSection = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  width: 33.3%;
  margin-right: 8px;
  ${(props) =>
    props.filterIsOpen
      ? ''
      : `width: auto;
  position: fixed;
  left: 0;`}
`;

const IconContainer = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  ${(props) =>
    props.filterIsOpen
      ? ''
      : `
  padding: 10px 10px 8px 10px;
  background: var(--secondaryBackground);

  svg {
    path {
      fill: var(--mainObjects);
    }
  }
  `}
`;

const MetricsSection = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  width: ${(props) => (props.filterIsOpen ? '66.6%' : '100%')};
  margin-left: ${(props) => (props.filterIsOpen ? '8px' : '0')};
`;

export default LiveReportPage;

import React from 'react';

const SupportMessage = () => {
  return (
    <p className="font-body3">
      Please contact{' '}
      <a href="mailto:support@pcds.co.uk" className="font-body2">
        support@pcds.co.uk
      </a>{' '}
      if you have any questions.
    </p>
  );
};

export default SupportMessage;

export const defaultError = {
  status: null,
  data: {
    title: 'Something went wrong',
    msg: 'Please, refresh page',
  },
};

export const tokenError = {
  status: null,
  data: {
    title: 'Session is over',
    msg: 'Please, login',
  },
};

import React from 'react';
import styled from 'styled-components';

const TextNoBorderedInput = ({ type, label, value, error, onChange }: InputTypes) => (
  <InputContainer>
    <StyledLabel htmlFor={label} error={error}>
      {label}
      <StyledInput id={label} type={type} onChange={onChange} value={value} error={error} />
    </StyledLabel>
  </InputContainer>
);

const InputContainer = styled.div`
  height: 100%;
  padding: 8px 20px 13px 20px;
`;

const StyledLabel = styled(({ label, error, ...rest }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label htmlFor={label} {...rest} />
)).attrs({
  className: 'font-body4',
})`
  padding-left: 2px;
  color: var(--secondaryText);
  color: ${(props) => (props.error ? 'var(--mainAccent)' : 'var(--secondaryText)')};
`;

const StyledInput = styled(({ error, ...rest }) => <input {...rest} />)`
  width: 100%;
  box-sizing: border-box;
  outline: none;
  font-size: 12px;
  color: var(--mainText);
  border: none;
  padding-top: 6px;
`;

interface InputTypes {
  type: string;
  label: string;
  value: string;
  error: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default TextNoBorderedInput;

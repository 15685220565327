import React from 'react';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import SearchInput from '../atoms/form/SearchInput';
import SearchableKeys from '../constants/searchableKeys.enum';
import { customScroll } from '../styles/GlobalStyles';
import { SearchValueObjType } from '../redux/ducks/search';

const SearchInputComponent = ({
  value,
  setValue,
  searchResults,
  onChange,
  pageRoute
}: PropTypes) => {
  const getNameToView = (valueObj, key, index) => {
    if (!valueObj || typeof valueObj !== 'object') return '';
    if (key === SearchableKeys.CAMPAIGN) {
      return valueObj.name ? (
        <Highlighter
          key={valueObj.id + valueObj.name}
          searchWords={[`${value}`]}
          textToHighlight={valueObj.name}
          highlightClassName="marked"
        />
      ) : (
        ''
      );
    }
    if (key === SearchableKeys.DESIGN_NAME) {
      return valueObj.name ? (
        <Highlighter
          key={valueObj.id + valueObj.name}
          searchWords={[`${value}`]}
          textToHighlight={valueObj.name}
          highlightClassName="marked"
        />
      ) : (
        ''
      );
    }
    if (key === SearchableKeys.REF_ID) {
      return valueObj.name ? (
        <Highlighter
          key={valueObj.id + valueObj.refId}
          searchWords={[`${value}`]}
          textToHighlight={valueObj.refId}
          highlightClassName="marked"
        />
      ) : (
        ''
      );
    }
    return '';
  };

  const parseResultObj = (data: SearchValueObjType) => {
    if (!Object.entries(data).length) {
      return <StyledText>No search results</StyledText>;
    }
    return Object.entries(data).map(([key, fieldToShow]) => {
      return fieldToShow.map((element, index) => {
        return (
          <p key={key + element.id}>
            <Link
              to={`${pageRoute}?${key}=${encodeURIComponent(
                key === 'refId' ? element.refId : element.name
              )}`}
              onClick={() => setValue('')}
            >
              <span className="marked marked__uppercased">{key}</span>:{' '}
              {getNameToView(element, key, index)}
            </Link>
          </p>
        );
      });
    });
  };

  return (
    <SearchInputContainer>
      <StyledSearchInput
        placeholder="Search companies"
        value={value}
        onChange={onChange}
        searchResults={searchResults}
      />
      {!!searchResults && (
        <SearchResultsContainer>
          <ResultList>{parseResultObj(searchResults)}</ResultList>
        </SearchResultsContainer>
      )}
    </SearchInputContainer>
  );
};

const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSearchInput = styled(({ ...rest }) => <SearchInput {...rest} />)`
  border-radius: ${(props) => (props.searchResults ? '15px 15px 0 0' : '15px')};
`;

const ResultList = styled.div`
  overflow-y: scroll;
  ${customScroll}
  height: 100%;
  max-height: 150px;
`;

const StyledText = styled.p`
  padding: 6px 20px;
`;

const SearchResultsContainer = styled.div`
  padding: 16px 0;
  border-top: 1px solid var(--mainBackground);

  z-index: 10;
  position: absolute;
  width: 100%;
  top: 100%;
  background: var(--defaultBody);
  box-shadow: 0px 10px 17px 0px rgba(0, 0, 0, 0.09);
  border-radius: 0 0 15px 15px;
  border-top: 1px solid var(--mainBackground);
  z-index: 10;

  p {
    margin: 0;
    color: var(--secondaryText);

    :hover {
      cursor: pointer;
      background: var(--secondaryBackground);
    }
  }

  a {
    color: var(--secondaryText);
    display: block;
    width: 100%;
    height: 100%;
    padding: 6px 20px;
  }

  .marked {
    color: var(--mainText);
    background-color: transparent;

    &__uppercased {
      text-transform: capitalize;
    }
  }
`;

interface PropTypes {
  value: string;
  setValue: (value: string) => void;
  searchResults: SearchValueObjType;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pageRoute: string;
}

export default SearchInputComponent;

import React from 'react';
import styled, { keyframes } from 'styled-components';

const Loader = () => {
  React.useEffect(() => {
    const bodyClass = 'body--overflow';
    const body = document.getElementsByTagName('body')[0];
    body.classList.add(bodyClass);
    return () => {
      body.classList.remove(bodyClass);
    };
  }, []);

  return (
    <Container>
      <StyledLoader>
        <div />
        <div />
        <div />
        <div />
      </StyledLoader>
    </Container>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100vh;
  background: var(--transparentBackground);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoader = styled.div.attrs({
  className: 'loader',
})`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--elementBackground);
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--elementBackground) transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export default Loader;

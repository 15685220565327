import React from 'react';

import './api/axiosConfig';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isOpenErrorModalSelector } from './redux/ducks/requestApiError';
import PrivateRoute from './features/PrivateRoute';
import PublicRoute from './features/PublicRoute';
import RoutesTypes, { HOMEPAGE } from './pages/routes.enum';
import RegistrationPage from './pages/RegistrationPage';
import LiveReportPage from './pages/LiveReportPage';
import DashboardPage from './pages/DashboardPage';
import LogInPage from './pages/LogInPage';
import NotFoundPage from './pages/PageNotFound';
import RestorePasswordPage from './pages/RestorePasswordPage';
import SupportPage from './pages/SupportPage';
import PermissionCheck from './features/PermissionCheck';
import UserSettingsPage from './pages/UserSettingsPage';
import FaqPage from './pages/FaqPage';
import ModerationPage from './pages/ModerationPage';
import ErrorRequestModal from './molecules/ErrorRequestModal';

function App() {
  const errorModalIsOpen = useSelector(isOpenErrorModalSelector);

  return (
    <div className="App">
      {errorModalIsOpen && <ErrorRequestModal />}
      <Switch>
        <Redirect exact from="/" to={HOMEPAGE} />
        <PublicRoute path={RoutesTypes.REGISTRATION} component={RegistrationPage} />
        <PublicRoute path={RoutesTypes.LOG_IN} component={LogInPage} />
        <PublicRoute path={RoutesTypes.RESTORE_PASSWORD} component={RestorePasswordPage} />
        <PrivateRoute path={RoutesTypes.SUPPORT} component={SupportPage} />
        <PrivateRoute path={RoutesTypes.DASHBOARD} component={DashboardPage} />
        <PrivateRoute path={RoutesTypes.USER_SETTINGS} component={UserSettingsPage} />
        <PrivateRoute path={RoutesTypes.LIVE_REPORT} component={LiveReportPage} />
        <PrivateRoute path={RoutesTypes.FAQ} component={FaqPage} />
        <PermissionCheck>
          <PrivateRoute path={RoutesTypes.MODERATOR_LIVE_REPORT} component={ModerationPage} />
        </PermissionCheck>
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </div>
  );
}

export default App;

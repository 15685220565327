import { OptionType } from '../types/authTypes';

export const createOptionObject = (data: OptionType[], valueName: string, labelName: string) => {
  if (!data || !Array.isArray(data)) return [];
  return data.map((element) => {
    return {
      value: element[valueName],
      label: element[labelName],
    };
  });
};

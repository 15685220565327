import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import DeleteOptionIcon from '../../static/icons/delete_select_option.svg';
import { OptionsType } from '../../types/liveReport';

const SelectedFilters = ({
  appliedFilters,
  clearAllFilters,
  clearFilterValue,
}: SelectedFiltersProps) => {
  const handleClick = (key, value) => {
    clearFilterValue(key, value);
  };

  return (
    <>
      {appliedFilters && (
        <SelectedFiltersContainer>
          <FiltersContainer>
            <ClearFilter onClick={clearAllFilters}>Clear Filters</ClearFilter>
            {Object.entries(appliedFilters).map(([key, element]) => {
              return element.map((filterValue, index) => {                
                if (key === 'issue') {
                  return (
                    <FilterValue key={index} onClick={() => handleClick(key, filterValue.value)}>
                      {filterValue.value ? 'Yes' : 'No'}
                      <ReactSVG src={DeleteOptionIcon} />
                    </FilterValue>
                  );
                }
                return (
                  <FilterValue key={index} onClick={() => handleClick(key, filterValue.value)}>
                    {filterValue.value}
                    <ReactSVG src={DeleteOptionIcon} />
                  </FilterValue>
                );
              });
            })}
          </FiltersContainer>
        </SelectedFiltersContainer>
      )}
    </>
  );
};

const SelectedFiltersContainer = styled.div`
  width: 100%;
  background: var(--mainBackground);
  min-height: 200px;
  padding: 16px 12px;
  border-radius: 5px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterValue = styled.button`
  text-transform: capitalize;
  color: var(--mainText);
  border-radius: 5px;
  padding: 7px;
  background: var(--defaultBody);
  display: flex;
  margin: 0 8px 16px 0;
  border: none;
  cursor: pointer;

  & > div {
    margin-left: 10px;
  }
`;

const ClearFilter = styled(FilterValue)`
  color: var(--mainAccent);
`;

interface SelectedFiltersProps {
  appliedFilters: OptionsType;
  clearAllFilters: () => void;
  clearFilterValue: (key: string, value: string) => void;
}

export default SelectedFilters;

import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import SearchIcon from '../../static/icons/search_icon.svg';

const TextInput = ({ className, placeholder, value, label, onChange }: InputTypes) => (
  <InputContainer>
    {label && <p className="font-body4">{label}</p>}
    <ReactSVG src={SearchIcon} className="icon" />
    <StyledInput
      type="text"
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      value={value}
    />
  </InputContainer>
);

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
  border-radius: 15px;

  p {
    color: var(--mainObjects);
    padding-left: 16px;
    margin: 0;
    margin-bottom: 4px;
  }

  & > .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
  padding: 0 20px;
  color: var(--mainText);
  border: none;
  border-radius: 15px;
  background: var(--defaultBody);

  ::placeholder {
    color: var(--secondaryText);
  }
`;

interface InputTypes {
  className?: string;
  placeholder: string;
  value: string;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default TextInput;

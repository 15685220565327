import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import queryString from 'query-string';
import camelCaseKeys from 'camelcase-keys';
import history from '../history';
import DashboardTemplate from '../templates/DashboardTemplate';
import { createLookupQuery } from '../utils/queryParamCreator';
import MainLoader from '../atoms/loaders/MainLoader';
import {
  getLookups,
  skipAllValues,
  getModeratorReports,
  appliedFilterSelector,
  fetchProcessSelector,
  lookupsListSelector,
  postersListSelector,
  approveSinglePoster,
  applyFilter,
  getModeratorPosters,
  approveAllPosters,
  amountOfPagesSelector,
} from '../redux/ducks/liveReportModerator';
import FilterIcon from '../static/icons/filter_icon.svg';
import LiveReportFilterForm from '../organisms/LiveReport/LiveReportFilterForm';
import ModeratorPosterViewList from '../organisms/LiveReport/ModeratorPosterViewList';
import createPreDefaultFilterState from '../utils/createPreDefaultFilterState';
import SelectedFilters from '../organisms/LiveReport/SelectedFilters';
import Search from '../organisms/Search';
import { OptionsType } from '../types/liveReport';
import RoutesTypes from './routes.enum';

const filterFormInitState = {
  campaign: [],
  design: [],
  mediaOwner: [],
  refId: [],
  size: [],
  inCharge: [],
  issue: [],
  moderatorIssue: [],
};

const ModerationPage = () => {
  const dispatch = useDispatch();
  const query = useLocation().search;
  const location = useLocation();

  const [filterIsOpen, setFilterIsOpen] = React.useState(true);

  const [page, setPage] = React.useState(1);

  const [autocompleteForms, setAutocompleteForms] = React.useState(true);

  const [selectFilterValues, setSelectFilterValues] = React.useState<OptionsType>(
    filterFormInitState
  );

  const fetchProcess = useSelector(fetchProcessSelector);
  const lookupsList = useSelector(lookupsListSelector);
  const postersList = useSelector(postersListSelector);
  const amountOfPages = useSelector(amountOfPagesSelector);
  const appliedFilters = useSelector(appliedFilterSelector);

  const handleSelect = (key) => (value) => {
    const newValue = {
      ...selectFilterValues,
      [key]: [...value],
    };
    setSelectFilterValues(newValue);
  };

  const handleClearValue = (key) => () => {
    const newValue = {
      ...selectFilterValues,
      [key]: [],
    };
    setSelectFilterValues(newValue);
  };

  const handleDeleteValue = (key) => (valueToDelete) => {
    const newValue = {
      ...selectFilterValues,
      [key]: [...selectFilterValues[key].filter((element) => element.value !== valueToDelete)],
    };
    setSelectFilterValues(newValue);
  };

  const clearFilterValue = (key, value) => {
    const newValue = {
      ...selectFilterValues,
      [key]: [...selectFilterValues[key].filter((element) => element.value !== value)],
    };
    setSelectFilterValues(newValue);
    dispatch(applyFilter(newValue));
  };

  const clearAllFilters = () => {
    setAutocompleteForms(false);
    setSelectFilterValues(filterFormInitState);
    dispatch(getLookups(filterFormInitState));
    dispatch(applyFilter(null));
  };

  const applyFilters = () => {
    history.push(`${location.pathname}${createLookupQuery(selectFilterValues)}`);
    dispatch(applyFilter(selectFilterValues));
    dispatch(getModeratorReports({ filterValues: selectFilterValues, page: 1 }));
    setPage(1);
  };

  const handleCloseFilter = () => {
    filterIsOpen ? setFilterIsOpen(false) : setFilterIsOpen(true);
  };

  const changePage = (pageNumber: number) => {
    dispatch(getModeratorPosters({ ...appliedFilters, page: pageNumber }));
    setPage(pageNumber);
  };

  const handleApproveSinglePoster = (id: number) => {
    dispatch(approveSinglePoster(id));
  };

  const handleApproveAllPosters = (idArray: [number]) => {
    dispatch(approveAllPosters({ filterValues: selectFilterValues, page, idArray }));
  };

  const getPosters = () => {
    dispatch(getModeratorPosters({ ...selectFilterValues, page }));
  };

  React.useEffect(() => {
    if (autocompleteForms) {
      setSelectFilterValues({ ...lookupsList });
    }
  }, [lookupsList, autocompleteForms]);

  React.useEffect(() => {
    let validObj;
    if (query) {
      const parsedQuery = camelCaseKeys(queryString.parse(query));
      validObj = createPreDefaultFilterState(filterFormInitState, parsedQuery);
      dispatch(applyFilter(validObj));
      setSelectFilterValues(validObj);
      setAutocompleteForms(Object.keys(parsedQuery).length === 1);
    }
    dispatch(getModeratorReports({ filterValues: validObj || filterFormInitState, page: 1 }));
    return () => {
      dispatch(skipAllValues());
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [query]);

  return (
    <>
      {fetchProcess && <MainLoader />}
      <DashboardTemplate
        isFooterHide={true}
        showCompanyName={true}
        body={
          <>
            <SearchContainer>
              <Search pageRoute={RoutesTypes.MODERATOR_LIVE_REPORT} />
            </SearchContainer>
            <LiveReportModeratorContainer>
              <FilterSectionContainer filterIsOpen={filterIsOpen}>
                <FiltersTitleContainer onClick={handleCloseFilter}>
                  <IconContainer filterIsOpen={filterIsOpen}>
                    <ReactSVG src={FilterIcon} />
                  </IconContainer>
                  {filterIsOpen && <SectionTitle>Filters</SectionTitle>}
                </FiltersTitleContainer>
                {filterIsOpen && (
                  <LiveReportFilterForm
                    options={lookupsList}
                    value={selectFilterValues}
                    handleSelect={handleSelect}
                    handleClearValue={handleClearValue}
                    handleDeleteValue={handleDeleteValue}
                    clearAllFilters={clearAllFilters}
                    applyFilters={applyFilters}
                    isModeratorPage
                  />
                )}
                {filterIsOpen && (
                  <SelectedFiltersContainer filterIsOpen={filterIsOpen}>
                    {appliedFilters && <MarginedSectionTitle>Selected Filters</MarginedSectionTitle>}
                    <SelectedFilters
                      appliedFilters={appliedFilters}
                      clearAllFilters={clearAllFilters}
                      clearFilterValue={clearFilterValue}
                    />
                  </SelectedFiltersContainer>
                )}
              </FilterSectionContainer>
              <PosterListContainer filterIsOpen={filterIsOpen}>
                <ModeratorPosterViewList
                  filterIsOpen={filterIsOpen}
                  postersList={postersList}
                  fetchProcess={fetchProcess}
                  amountOfPages={amountOfPages}
                  handlePageChange={changePage}
                  handleApproveSinglePoster={handleApproveSinglePoster}
                  handleApproveAllPosters={handleApproveAllPosters}
                  getPosters={getPosters}
                  page={page}
                />
              </PosterListContainer>
            </LiveReportModeratorContainer>
          </>
        }
      />
    </>
  );
};

const LiveReportModeratorContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  width: 100%;
`;

const SearchContainer = styled.div`
  width: 100%;
`;

const FilterSectionContainer = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  width: 33.3%;
  margin-right: 8px;
  ${(props) =>
    props.filterIsOpen
      ? ''
      : `width: auto;
  position: fixed;
  left: 0;`}
`;

const FiltersTitleContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const SectionTitle = styled.p.attrs({
  className: 'font-body3',
})`
  color: var(--mainText);
  margin: 0;
  margin-left: 7px;
  min-height: 20px;
  display: flex;
  align-items: center;
`;

const IconContainer = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  ${(props) =>
    props.filterIsOpen
      ? ''
      : `
  padding: 10px 10px 8px 10px;
  background: var(--secondaryBackground);

  svg {
    path {
      fill: var(--mainObjects);
    }
  }
  `}
`;

const SelectedFiltersContainer = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  width: 100%;
  margin-right: 8px;
  margin-top: 32px;
`;

const MarginedSectionTitle = styled(SectionTitle)`
  margin-bottom: 10px;
`;

const PosterListContainer = styled(({ filterIsOpen, ...rest }) => <div {...rest} />)`
  width: ${(props) => (props.filterIsOpen ? '66.6%' : '100%')};
`;

export default ModerationPage;

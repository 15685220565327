import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ErrorObj, ErrorArrayedObj, DefaultErrorObj } from '../types/authTypes';
import { checkEmptyObj } from '../utils/validator';
import AuthErrors from '../constants/errors/authErrors.enum';
import RoutesTypes from '../pages/routes.enum';
import HelperMessages from '../molecules/HelperMessages';

const FormMessageContainer = ({ minHeight, errors = {}, successMessage }: PropTypes) => {
  const viewMessage = () => {
    if (successMessage) {
      return <p className="font-body3">{successMessage}</p>;
    }
    if (errors && !checkEmptyObj(errors)) {
      return false;
    }
    if (!errors.status) {
      return <HelperMessages errorMessages={errors} isError />;
    }
    if (Array.isArray(errors.status) && errors.status.includes(AuthErrors.ERROR)) {
      return <HelperMessages errorMessages={errors.message} isError />;
    }
    if (Array.isArray(errors.status) && errors.status.includes(AuthErrors.UNREGISTER)) {
      return (
        <>
          <p className="font-body3">
            Seems like you don't have an account yet. Please,{' '}
            <Link to={RoutesTypes.REGISTRATION} className="font-body2">
              Register!
            </Link>
          </p>
          <HelperMessages errorMessages={errors.message} isError />
        </>
      );
    }
    if (Array.isArray(errors.status) && errors.status.includes(AuthErrors.AWAITING)) {
      return (
        <>
          <p className="font-body3">Your account awaits approval. It may take up to 48 hours.</p>
          <HelperMessages errorMessages={errors.message} />
        </>
      );
    }
    if (Array.isArray(errors.status) && errors.status.includes(AuthErrors.DECLINED)) {
      return (
        <>
          <p className="font-body3 error-message">Your account was declined!</p>
          <HelperMessages errorMessages={errors.message} isError />
        </>
      );
    }
    if (Array.isArray(errors.status) && errors.status.includes(AuthErrors.DISABLED)) {
      return (
        <>
          <p className="font-body3 error-message">Your account has been disabled.</p>
          <HelperMessages errorMessages={errors.message} isError />
        </>
      );
    }
    return false;
  };

  return <MessageContainer minHeight={minHeight}>{viewMessage()}</MessageContainer>;
};

const MessageContainer = styled(({ minHeight, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: ${(props) => props.minHeight}px;

  p {
    margin: 4px 0;
    width: 300px;
  }

  & > .error-message {
    color: var(--mainAccent);
  }
`;

interface PropTypes {
  minHeight: number;
  successMessage?: string | null;
  errors?: ErrorObj | ErrorArrayedObj | DefaultErrorObj;
}

export default FormMessageContainer;

import React from 'react';
import styled from 'styled-components';

const DefaultButton = ({
  children,
  isSubmit,
  onClick,
  isLight,
  className,
  disabled,
}: DefaultButtonTypes) => (
  <StyledButton
    onClick={onClick}
    type={isSubmit ? 'submit' : 'button'}
    isLight={isLight}
    className={className}
    disabled={disabled}
  >
    {children}
  </StyledButton>
);

interface DefaultButtonTypes {
  children: React.ReactNode;
  isSubmit?: boolean;
  onClick?: () => void;
  isLight?: boolean;
  className?: string;
  disabled?: boolean;
}

const StyledButton = styled(({ isLight, ...rest }) => <button {...rest} />)`
  width: 100%;
  height: 48px;
  min-width: 270px;
  border-radius: 20px;
  font-size: 16px;
  outline: none;
  cursor: pointer;

  ${(props) =>
    props.isLight
      ? `
  color: var(--elementBackground);
  background: var(--defaultBody);
  border: 1px solid var(--elementBackground);
`
      : `
  color: var(--defaultBody);
  background: var(--elementBackground);
  border: none;
`}

  ${(props) => props.disabled && 'opacity: 0.7;'}

  :hover {
    background: ${(props) => (props.isLight ? 'var(--elementBackground)' : 'var(--darkHover)')};
    color: ${(props) => (props.isLight ? 'var(--defaultBody)' : 'var(--defaultBody)')};
  }
`;

export default DefaultButton;

import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { PosterType, ModeratorIssueStatus } from '../../types/liveReport';
import { customScroll } from '../../styles/GlobalStyles';
import PaperBoxContainer from '../../atoms/PaperBox';
import PostDataCard from '../../molecules/PostDataCard';
import DefaultModal from '../../molecules/DefaultModal';
import { changeToValidDate } from '../../utils/formatDate';
import ApproveIcon from '../../static/icons/check_icon.svg';
import IssueIcon from '../../static/icons/exclamation_icon.svg';
import QuestionIcon from '../../static/icons/question_icon.svg';

const CampaignResultTable = ({
  data,
  setAmountOnPageOfResults,
  changeResultPosition,
  sortLiveReports,
}: CampaignResultTableProps) => {
  const [position, setPosition] = React.useState(null);
  const [valueToSort, setValueToSort] = React.useState(null);
  const [isLatestValueToSort, setIsLatestValueToSort] = React.useState(false);

  const delayHandler = React.useRef(null);
  const postData = React.useRef(null);
  const mousePosition = React.useRef(null);
  const infoModalRef = React.useRef<HTMLDivElement>(null);
  const tableRef = React.useRef(null);
  const rowRef = React.useRef(null);
  const [tableHeight, setTableHeight] = React.useState(null);
  const [rowHeight, setRowHeight] = React.useState(null);

  const [pictureModalIsOpen, setPictureModalIsOpen] = React.useState(false);
  const [infoModalIsOpen, setInfoModalIsOpen] = React.useState(false);

  const innerHeight = Math.floor(window.innerHeight);

  const handleMouseEnter = (e, post) => {
    postData.current = post;
    delayHandler.current = setTimeout(() => {
      setInfoModalIsOpen(true);
    }, 500);
  };

  const handleMouseMove = (e) => {
    mousePosition.current = {
      clientX: e.clientX,
      clientY: e.clientY,
    };
  };

  React.useEffect(() => {
    if (infoModalRef && infoModalRef.current) {
      const infoModalRefHeight = infoModalRef.current.getBoundingClientRect().height;
      const infoModalRefWidth = infoModalRef.current.getBoundingClientRect().width;
      const clientYPosition =
        infoModalRefHeight + mousePosition.current.clientY > innerHeight
          ? innerHeight - infoModalRefHeight
          : mousePosition.current.clientY / 2;

      setPosition({
        clientX: mousePosition.current.clientX - infoModalRefWidth - 30,
        clientY: clientYPosition,
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [infoModalRef, postData.current, infoModalIsOpen]);

  const handleMouseLeave = () => {
    clearTimeout(delayHandler.current);
    setInfoModalIsOpen(false);
    setPosition(null);
  };

  React.useEffect(() => {
    if (tableRef && tableRef.current && rowRef && rowRef.current) {
      setTableHeight(Math.round(tableRef.current.getBoundingClientRect().height));
      setRowHeight(Math.round(rowRef.current.getBoundingClientRect().height) + 8); // height of element + margin-bottom;
      tableRef.current.scrollTop = 0;
    }
  }, [tableRef, rowRef, data]);

  React.useEffect(() => {
    if (tableHeight && rowHeight) {
      const amountOnScreen = Math.round(tableHeight / rowHeight);
      setAmountOnPageOfResults(amountOnScreen);
    }
  }, [tableHeight, rowHeight, setAmountOnPageOfResults]);

  const handleScroll = (e) => {
    const scrollPosition = e.target.scrollTop;
    const rowsOnScroll = Math.round(scrollPosition / rowHeight);
    changeResultPosition(rowsOnScroll);
  };

  const handleModalOpen = () => {
    setPictureModalIsOpen(true);
  };

  const setModeratorIssueStatus = (issueStatus) => {
    switch (issueStatus) {
      case ModeratorIssueStatus.APPROVED:
        return (
          <SVGContainer>
            <ReactSVG src={ApproveIcon} />
          </SVGContainer>
        );
      case ModeratorIssueStatus.AWAITING:
        return (
          <SVGContainer>
            <ReactSVG src={QuestionIcon} />
          </SVGContainer>
        );
      case ModeratorIssueStatus.ISSUE:
        return (
          <SVGContainer>
            <ReactSVG src={IssueIcon} />
          </SVGContainer>
        );
      default:
        return null;
    }
  };

  const setSortValue = (sortName) => {
    if (sortName === valueToSort) {
      sortLiveReports(`${isLatestValueToSort ? '' : '-'}${sortName}`);
      return setIsLatestValueToSort(!isLatestValueToSort);
    }
    sortLiveReports(`${sortName}`);
    setValueToSort(sortName);
    return setIsLatestValueToSort(false);
  };

  const setSortArrow = (columnName) => {
    if (columnName === valueToSort) {
      return <ArrowContainer isLatestValueToSort={isLatestValueToSort} />;
    }
    return null;
  };

  return (
    <>
      <CampaignResultTableContainer>
        <TableRowContainer>
          <TableRowTitle width={3} />
          <TableRowTitle width={14} onClick={() => setSortValue('campaign')}>
            Campaign {setSortArrow('campaign')}
          </TableRowTitle>
          <TableRowTitle width={12} onClick={() => setSortValue('design')}>
            Design {setSortArrow('design')}
          </TableRowTitle>
          <TableRowTitle width={15} onClick={() => setSortValue('media_owner')}>
            Media owner {setSortArrow('media_owner')}
          </TableRowTitle>
          <TableRowTitle width={16} onClick={() => setSortValue('billposter_comment')}>
            Comment {setSortArrow('billposter_comment')}
          </TableRowTitle>
          <TableRowTitle width={15} onClick={() => setSortValue('posting_date')}>
            Posting date {setSortArrow('posting_date')}
          </TableRowTitle>
          <TableRowTitle width={6} onClick={() => setSortValue('size')}>
            Size {setSortArrow('size')}
          </TableRowTitle>
          <TableRowTitle width={7} onClick={() => setSortValue('billposter_issue')}>
            Issue {setSortArrow('billposter_issue')}
          </TableRowTitle>
          <TableRowTitle width={15} onClick={() => setSortValue('address')}>
            Location {setSortArrow('address')}
          </TableRowTitle>
        </TableRowContainer>
        <TableBodyContainer data={!!data} onScroll={handleScroll} forwardRef={tableRef}>
          {data && data.length ? (
            data?.map((post, index) => (
              <TableRow
                key={index}
                onMouseEnter={(e) => handleMouseEnter(e, post)}
                onMouseLeave={handleMouseLeave}
                onClick={handleModalOpen}
                onMouseMove={handleMouseMove}
                ref={rowRef}
              >
                <TableColumn width={3}>{setModeratorIssueStatus(post.moderatorIssue)}</TableColumn>
                <TableColumn width={14}>{post.campaign}</TableColumn>
                <TableColumn width={12}>{post.design}</TableColumn>
                <TableColumn width={12}>{post.mediaOwner}</TableColumn>
                <TableColumn width={16}>{post.billposterComment}</TableColumn>
                <TableColumn width={15}>{changeToValidDate(post.postingDate)}</TableColumn>
                <TableColumn width={6}>{post.size}</TableColumn>
                <TableColumn width={7}>{post.billposterIssue ? 'Yes' : 'No'}</TableColumn>
                <TableColumn width={15}>{post.address}</TableColumn>
              </TableRow>
            ))
          ) : (
            <EmptyMessage>No poster info</EmptyMessage>
          )}
        </TableBodyContainer>
      </CampaignResultTableContainer>
      {infoModalIsOpen && (
        <StyledPaperBoxContainer position={position} forwardRef={infoModalRef}>
          <PostDataCard postData={postData.current} />
        </StyledPaperBoxContainer>
      )}
      {pictureModalIsOpen && (
        <DefaultModal closeModal={() => setPictureModalIsOpen(false)}>
          <img src={postData.current.photo} alt="poster_image" />
        </DefaultModal>
      )}
    </>
  );
};

const CampaignResultTableContainer = styled.div`
  width: 100%;
`;

const TableRowContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 8px;
`;

const StyledPaperBoxContainer = styled(({ position, ...rest }) => <PaperBoxContainer {...rest} />)`
  position: fixed;
  border-radius: 5px;
  z-index: 30;
  top: ${(props) => `${props.position?.clientY}px` || '300px;'};
  left: ${(props) => `${props.position?.clientX}px` || '300px;'};
`;

const SVGContainer = styled.div`
  height: 15px;
`;

const ArrowContainer = styled(({ isLatestValueToSort, ...rest }) => <div {...rest} />)`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  ${(props) =>
    props.isLatestValueToSort
      ? `
    border-top: 10px solid var(--darkHover);
  `
      : `
    border-bottom: 10px solid var(--darkHover);
  `}
  margin-left: 5px;
`;

const TableRow = styled.div`
  width: 100%;
  background: var(--defaultBody);
  display: flex;
  margin-bottom: 8px;
  padding: 7px 8px;

  &:hover {
    background: var(--secondaryBackground);
    cursor: pointer;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const TableRowTitle = styled(({ width, ...rest }) => <span {...rest} />)`
  width: ${(props) => `${props.width}%`};
  display: flex;
  align-items: center;
  color: var(--mainText);
  overflow: hidden;
  padding-bottom: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

const TableColumn = styled(({ width, ...rest }) => <span {...rest} />).attrs({
  className: 'font-body4',
})`
  display: block;
  width: ${(props) => `${props.width}%`};
  color: var(--secondaryText);
  white-space: nowrap;
  overflow: hidden;
  padding-right: 15px;
  text-overflow: ellipsis;
`;

const EmptyMessage = styled.p.attrs({
  className: 'font-h2',
})`
  text-align: center;
  width: 100%;
  padding: 40px 0;
`;

const TableBodyContainer = styled(({ data, forwardRef, ...rest }) => (
  <div {...rest} ref={forwardRef} />
))`
  width: 100%;
  height: ${(props) => (props.data ? '463px' : 'auto')};
  overflow-y: scroll;
  ${customScroll}
  position: relative;
`;

interface CampaignResultTableProps {
  data: PosterType[];
  setAmountOnPageOfResults: (value: number) => void;
  changeResultPosition: (value: number) => void;
  sortLiveReports: (sortValue: string) => void;
}

export default CampaignResultTable;

const disabledColor = 'var(--mainObjects)';
const disabledBackground = 'rgba(183, 183, 183, 0.1)';

const defaultData = [
  {
    color: disabledColor,
    background: disabledBackground,
    value: 50,
    title: 'coming',
  },
];

export default (metricObj, fontColor, backgroundColor) => {
  if (!metricObj) return defaultData;
  return [
    {
      color: fontColor,
      background: backgroundColor,
      value: metricObj.percent,
      title: metricObj.count,
    },
  ];
};

import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import SearchInputComponent from '../molecules/SearchInputComponent';
import MainLoader from '../atoms/loaders/MainLoader';
import {
  getSearchValues,
  fetchProcessSelector,
  searchResultsSelector,
  setSearchWords,
  setSearchValue,
} from '../redux/ducks/search';

const Search = ({ pageRoute } : PropTypes) => {
  const dispatch = useDispatch();
  const [valueToSearch, setValueToSearch] = React.useState<string>('');

  const fetchProcess = useSelector(fetchProcessSelector);
  const searchResults = useSelector(searchResultsSelector);

  React.useEffect(() => {
    if (valueToSearch.length < 3) {
      dispatch(setSearchValue(null));
      dispatch(setSearchWords(null));
    }
    if (valueToSearch.length === 3) {
      dispatch(getSearchValues(valueToSearch));
      dispatch(setSearchWords(valueToSearch));
    }
    if (valueToSearch.length >= 4) {
      dispatch(setSearchWords(valueToSearch));
    }
  }, [dispatch, valueToSearch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValueToSearch(newValue);
  };

  return (
    <SearchContainer>
      {fetchProcess && <MainLoader />}
      <h1 className="font-h1">Campaigns Search Bar</h1>
      <SearchInputComponent
        value={valueToSearch}
        setValue={setValueToSearch}
        onChange={handleChange}
        searchResults={searchResults}
        pageRoute={pageRoute}
      />
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  h1 {
    margin-top: 0;
  }
`;

interface PropTypes {
  pageRoute: string;
}

export default Search;

import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';
// import { tokenError } from '../constants/errors/errorData';

import { apiPublic, api } from './api';
import { getAccessToken } from '../utils/auth';

function onRequestTokenInterceptor(request: AxiosRequestConfig) {
  const token = getAccessToken();

  if (!token) {
    return request;
  }
  request.headers.Authorization = `JWT ${token}`;

  return request;
}

function makeRequestValidData(request: AxiosRequestConfig) {
  if (request.data) {
    request.data = snakeCaseKeys(request.data, { deep: true });
  }
  return request;
}

function makeResponseValidData(response: AxiosResponse) {
  if (response.data) {
    response.data = camelCaseKeys(response.data, { deep: true });
  }

  return response;
}

function makeResponseErrorData(error: AxiosError) {
  if (error.response?.data) {
    error.response.data = camelCaseKeys(error.response.data, { deep: true });
  }

  return Promise.reject(error);
}

api.interceptors.request.use(makeRequestValidData);
api.interceptors.request.use(onRequestTokenInterceptor);

api.interceptors.response.use(makeResponseValidData, makeResponseErrorData);

apiPublic.interceptors.request.use(makeRequestValidData);
apiPublic.interceptors.response.use(makeResponseValidData, makeResponseErrorData);

import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { all, put, takeEvery, call } from 'redux-saga/effects';
import { createLookupQuery, createPosterQuery } from '../../utils/queryParamCreator';
import { logOut } from '../../utils/auth';
import { setError, setServerError } from './requestApiError';
import { defaultError } from '../../constants/errors/errorData';
import {
  getLookupsApi,
  getPostersApi,
  getMetricsApi,
  getCSVFileApi,
  getPosterApi,
  getPDFApi,
  getMapMarkersCoordinates,
} from '../../api/liveReport';
import b64toBlob from '../../utils/b64toBlob';
import {
  OptionsType,
  FilterType,
  DefaultObj,
  PosterType,
  MetricsType,
  MapMarker,
} from '../../types/liveReport';

const fileDownload = require('js-file-download');

const initialLookupsList = {
  campaign: [],
  mediaOwner: [],
  refId: [],
  design: [],
  inCharge: [],
  size: [],
  issue: [],
};

const initialState: LiveReportStateType = {
  fetchProcess: false,
  postersList: [],
  metrics: {},
  filterList: null,
  amountOfPages: null,
  lookupsList: initialLookupsList,
  mapMarkersList: [],
  poster: null,
};

const reducerName = 'liveReport';

const slice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setFetchProcess: (state, action: PayloadAction<boolean>) => {
      state.fetchProcess = action.payload;
    },
    setPosters: (state, action: PayloadAction<PosterType[] | null>) => {
      state.postersList = action.payload;
    },
    setFilters: (state, action: PayloadAction<OptionsType | null>) => {
      state.filterList = action.payload;
    },
    setAmountOfPages: (state, action: PayloadAction<number | null>) => {
      state.amountOfPages = action.payload;
    },
    setPoster: (state, action: PayloadAction<PosterType>) => {
      state.poster = action.payload;
    },
    setMapMarkersList: (state, action: PayloadAction<[MapMarker[]]>) => {
      state.mapMarkersList = action.payload;
    },
    setMetrics: (state, action: PayloadAction<MetricsType | null>) => {
      state.metrics = action.payload;
    },
    setLookups: (state, action: PayloadAction<FilterType | null>) => {
      state.lookupsList = { ...state.lookupsList, ...action.payload };
    },
    skipAllValues: (state) => {
      state.postersList = [];
      state.metrics = {};
      state.mapMarkersList = [];
      state.lookupsList = initialLookupsList;
      state.filterList = null;
    },
  },
});

export const {
  setFetchProcess,
  setPosters,
  setFilters,
  setLookups,
  skipAllValues,
  setAmountOfPages,
  setMetrics,
  setPoster,
  setMapMarkersList,
} = slice.actions;

export const getPosters = createAction<DefaultObj, any>(`${reducerName}/getPosters`);
export const getLookups = createAction<OptionsType, any>(`${reducerName}/getLookups`);
export const applyFilter = createAction<OptionsType, any>(`${reducerName}/applyFilter`);
export const getMetrics = createAction<DefaultObj, any>(`${reducerName}/getMetrics`);
export const getLiveReports = createAction<any, any>(`${reducerName}/getLiveReports`);
export const getMapMarkers = createAction<any, any>(`${reducerName}/getMapMarkers`);
export const getPoster = createAction<any, any>(`${reducerName}/getPoster`);
export const exportCSVFile = createAction<any, any>(`${reducerName}/exportCSVFile`);
export const getPDFFile = createAction<any, any>(`${reducerName}/getPDFFile`);

export const fetchProcessSelector = ({ liveReport }: PartialRootState) => liveReport.fetchProcess;
export const appliedFilterSelector = ({ liveReport }: PartialRootState) => liveReport.filterList;
export const lookupsListSelector = ({ liveReport }: PartialRootState) => liveReport.lookupsList;
export const postersListSelector = ({ liveReport }: PartialRootState) => liveReport.postersList;
export const amountOfPagesSelector = ({ liveReport }: PartialRootState) => liveReport.amountOfPages;
export const metricsSelector = ({ liveReport }: PartialRootState) => liveReport.metrics;
export const mapMarkersSelector = ({ liveReport }: PartialRootState) => liveReport.mapMarkersList;
export const posterSelector = ({ liveReport }: PartialRootState) => liveReport.poster;

function* parseErrorResponse(e) {
  const { data, status } = e.response;
  if (status >= 500) {
    yield put(setServerError(status));
    return;
  }
  if (status === 401) {
    yield logOut();
    return;
  }
  if (status === 403 && data.error === 'status') {
    yield put(
      setError({
        status,
        data: {
          msg: data.detail[0],
        },
      })
    );
    return;
  }
  yield put(setError(defaultError));
}

function* getPostersSaga({ payload }) {
  const queryStr = createPosterQuery(payload);

  yield put(setFetchProcess(true));
  try {
    const result = yield call(getPostersApi, queryStr);
    const amountOfPages = Math.ceil(result.data.count / 100) || 0;
    yield put(setAmountOfPages(amountOfPages));
    yield put(setPosters(result.data.results));
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* getMetricsData({ payload }) {
  const queryStr = createPosterQuery(payload);
  yield put(setFetchProcess(true));
  try {
    const result = yield call(getMetricsApi, queryStr);
    yield put(setMetrics(result.data));
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* getLookupsSaga({ payload }) {
  const lookupQuery = createLookupQuery(payload);
  yield put(setFetchProcess(true));
  try {
    const result = yield call(getLookupsApi, lookupQuery);
    yield put(setLookups(result.data));
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* getPDFFileSaga({ payload }) {
  const { selectFilterValues, image } = payload;

  const blob = b64toBlob(image);

  const queryStr = createPosterQuery(selectFilterValues);
  const formData = new FormData();
  formData.set('map', blob, 'map.jpeg');

  yield put(setFetchProcess(true));
  try {
    const result = yield call(getPDFApi, { formData, queryStr });
    const blobPdf = result.request.response;
    fileDownload(blobPdf, 'live-report.pdf');
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* exportCSVFileSaga({ payload }) {
  const queryStr = createPosterQuery(payload);
  yield put(setFetchProcess(true));
  try {
    const result = yield call(getCSVFileApi, queryStr);
    const csvContent = `data:text/csv;charset=utf-8,${result.data}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'live-report.csv');
    document.body.appendChild(link);
    link.click();
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* getPosterSaga({ payload }) {
  try {
    const result = yield call(getPosterApi, payload);
    yield put(setPoster(result.data));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* applyFilterSaga({ payload }) {
  try {
    yield put(setFilters(payload));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

function* getLiveReportsSaga({ payload }) {
  const { filterValues, page } = payload;
  const postersQuery = createPosterQuery({ ...filterValues, page });
  const metricsQuery = createPosterQuery(filterValues);
  const lookupQuery = createLookupQuery({
    ...initialLookupsList,
    campaign: filterValues.campaign
  });

  yield put(setFetchProcess(true));
  try {
    const metricsResult = yield call(getMetricsApi, metricsQuery);
    const postersResult = yield call(getPostersApi, postersQuery);
    const lookupsResult = yield call(getLookupsApi, lookupQuery);
    const markersResult = yield call(getMapMarkersCoordinates, postersQuery);
    yield put(setMapMarkersList(markersResult.data));
    yield put(setLookups(lookupsResult.data));
    yield put(setMetrics(metricsResult.data));
    const amountOfPages = Math.ceil(postersResult.data.count / 100) || 0;
    yield put(setAmountOfPages(amountOfPages));
    yield put(setPosters(postersResult.data.results));
    yield put(setFetchProcess(false));
  } catch (e) {
    yield put(setFetchProcess(false));
    yield call(parseErrorResponse, e);
  }
}

export function* liveReportSaga() {
  yield all([takeEvery(getLiveReports.type, getLiveReportsSaga)]);
  yield all([takeEvery(applyFilter.type, applyFilterSaga)]);
  yield all([takeEvery(getPosters.type, getPostersSaga)]);
  yield all([takeEvery(getMetrics.type, getMetricsData)]);
  yield all([takeEvery(getLookups.type, getLookupsSaga)]);
  yield all([takeEvery(getPoster.type, getPosterSaga)]);
  yield all([takeEvery(exportCSVFile.type, exportCSVFileSaga)]);
  yield all([takeEvery(getPDFFile.type, getPDFFileSaga)]);
}

type PartialRootState = {
  [reducerName]: ReturnType<typeof slice.reducer>;
};

export interface LiveReportStateType {
  fetchProcess: boolean;
  postersList: PosterType[] | null;
  filterList: OptionsType | null;
  lookupsList: FilterType | null;
  amountOfPages: number;
  metrics: MetricsType | DefaultObj;
  mapMarkersList: [MapMarker[]] | [];
  poster: PosterType | null;
}

export default slice.reducer;

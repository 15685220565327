import React from 'react';
import styled from 'styled-components';

const TextInput = ({
  type,
  className,
  placeholder,
  value,
  error,
  label,
  disabled,
  onChange,
}: InputTypes) => (
  <InputContainer>
    {label && <p className="font-body4">{label}</p>}
    <StyledInput
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      error={error}
      disabled={disabled}
    />
  </InputContainer>
);

const InputContainer = styled.div`
  position: relative;
  width: 100%;

  p {
    color: var(--mainObjects);
    padding-left: 16px;
    margin: 0;
    margin-bottom: 4px;
  }
`;

const StyledInput = styled(({ error, ...rest }) => <input {...rest} />)`
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
  font-size: 14px;
  padding-left: 16px;
  color: ${(props) => (props.disabled ? 'var(--mainObjects)' : 'var(--mainText)')};
  border: ${(props) =>
    props.error ? '1px solid var(--mainAccent)' : '1px solid var(--mainObjects)'};

  background: ${(props) => (props.disabled ? 'var(--defaultBody)' : '')};

  ::placeholder {
    color: var(--secondaryText);
  }

  :focus {
    border: 1px solid var(--elementBackground);
  }
`;

interface InputTypes {
  type: string;
  className?: string;
  placeholder: string;
  value: string;
  label?: string;
  disabled?: boolean;
  error: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default TextInput;

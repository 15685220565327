import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import RoutesTypes from '../pages/routes.enum';
import logo from '../static/images/pcds_main_logo.svg';

const DashboardFooter = ({ className }: { className?: string }) => {
  return (
    <FooterContainer>
      <LogoContainer>
        <ReactSVG src={logo} />
        <DescriptionContainer>
          Founded in 1983, PCDS remains the UK’s leading poster distribution specialist in the
          Out-of-Home advertising industry.
        </DescriptionContainer>
      </LogoContainer>
      <ContactInfoContainer>
        <Title>Contact Us</Title>
        <a href="tel:020 8501 2266" className="font-body4">
          020 8501 2266
        </a>
        <a href="mailto:info@pcds.co.uk" className="font-body4">
          info@pcds.co.uk
        </a>
      </ContactInfoContainer>
      <NavContainer>
        <Title>Links</Title>
        <StyledLink to={RoutesTypes.DASHBOARD}>Dashboard</StyledLink>
        <StyledLink to="/">FAQ</StyledLink>
        <StyledLink to={RoutesTypes.SUPPORT}>Contact Support</StyledLink>
        <StyledLink to="/">Privacy Policy</StyledLink>
      </NavContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
`;

const LogoContainer = styled.div`
  width: 50%;
  height: fit-content;
  display: flex;
  align-items: center;
`;

const DescriptionContainer = styled.p.attrs({
  className: 'font-body4',
})`
  margin: 0;
  color: var(--footerLightText);
  font-size: 12px;
  margin-left: 16px;
  width: 43%;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  & > a {
    color: var(--footerLightText);
    margin: 6px 0;
  }
`;

const StyledLink = styled(Link).attrs({
  className: 'font-body4',
})`
  color: var(--footerLightText);
  margin: 6px 0;
`;

const NavContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2.attrs({
  className: 'font-body2',
})`
  color: var(--defaultBody);
  margin: 0;
  margin-bottom: 6px;
`;

export default DashboardFooter;

const getRandomColor = () => {
  // eslint-disable-next-line
  return `#${((Math.random() * 0xffffff) << 0).toString(16)}`;
};

export default (index) => {
  switch (index) {
    case 0:
      return '#960018';
    case 1:
      return '#6B77C4';
    case 2:
      return '#F29C38';
    case 3:
      return '#48A9EE';
    default:
      return getRandomColor();
  }
};

export const checkEmptyFields = <T>(data: T) => {
  return Object.entries(data).reduce<T>((acc, [key, value]) => {
    if (!value && value !== 0) {
      return {
        ...acc,
        [key]: ['Field cannot be empty'],
      };
    }
    return acc;
  }, {} as T);
};

export const checkEmptyObj = <T>(data: T) => {
  if (typeof data === 'object') {
    return !!Object.keys(data).length;
  }
  return false;
};

export const createErrorMessageArray = <T>(data: T) => {
  const errorArray: Array<string> = [];
  if (!data) return errorArray;
  if (typeof data === 'string') {
    errorArray.push(data);
  }
  if (Array.isArray(data)) {
    data.forEach((element) => {
      if (!errorArray.includes(element)) {
        return errorArray.push(element);
      }
      return false;
    });
  }
  if (typeof data === 'object') {
    Object.values(data).map((value) => {
      if (typeof value === 'string') {
        if (!errorArray.includes(value)) {
          return errorArray.push(value);
        }
      }
      if (Array.isArray(value)) {
        value.forEach((element) => {
          if (Array.isArray(element)) return createErrorMessageArray(element);
          if (!errorArray.includes(element)) {
            return errorArray.push(element);
          }
          return false;
        });
      }
      return false;
    });
  }
  return errorArray;
};

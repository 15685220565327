import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PasswordInput from '../../atoms/form/PasswordInput';
import FormMessageContainer from '../../features/FormMessageContainer';
import TextInput from '../../atoms/form/TextInput';
import MainLoader from '../../atoms/loaders/MainLoader';
import { DefaultErrorObj } from '../../types/authTypes';
import RoutesTypes from '../../pages/routes.enum';
import DefaultButton from '../../atoms/buttons/DefaultButton';
import { logInUser } from '../../api/userAuth';
import { checkEmptyFields, checkEmptyObj } from '../../utils/validator';
import { setServerError } from '../../redux/ducks/requestApiError';
import { setAuthData } from '../../utils/auth';
import { setUserInfo } from '../../redux/ducks/userAuth';

const initFormState = {
  email: '',
  password: '',
};

const ChangesSuccessForm = ({ resetForm }: FormPropsTypes) => {
  const [formState, setFormState] = React.useState(initFormState);
  const [errors, setError] = React.useState<DefaultErrorObj>({});
  const [fetchProcess, setFetchProcess] = React.useState(false);

  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleChangeField = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFormState({
      ...formState,
      [key]: newValue,
    });
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setFetchProcess(true);
    const newError = checkEmptyFields(formState);
    if (checkEmptyObj(newError)) {
      setFetchProcess(false);
      return setError(newError);
    }
    return logInUser(formState)
      .then((response) => {
        const { token, user } = response.data;
        setAuthData(token, user);
        dispatch(setUserInfo(user));
        setError({});
        setFetchProcess(false);
        push(RoutesTypes.DASHBOARD);
      })
      .catch((error) => {
        const { data, status } = error.response;
        setFetchProcess(false);
        if (status >= 500) {
          dispatch(setServerError(status));
          return;
        }
        setError(data);
      });
  };

  return (
    <CodeFormContainer onSubmit={onSubmit}>
      {fetchProcess && <MainLoader />}
      <Title>Success!</Title>
      <p>Your Password has been changed!</p>
      <InputContainer>
        <TextInput
          type="email"
          placeholder="Email address..."
          onChange={handleChangeField('email')}
          value={formState.email}
          error={!!errors.email}
        />
      </InputContainer>
      <InputContainer>
        <PasswordInput
          placeholder="Password..."
          onChange={handleChangeField('password')}
          value={formState.password}
          error={!!errors.password}
        />
      </InputContainer>
      <StyledLink to={RoutesTypes.RESTORE_PASSWORD} onClick={resetForm}>
        Forgot password?
      </StyledLink>
      <MessageContainer>
        <FormMessageContainer minHeight={30} errors={errors} />
      </MessageContainer>
      <ButtonContainer>
        <DefaultButton isSubmit>Submit</DefaultButton>
      </ButtonContainer>
    </CodeFormContainer>
  );
};

const CodeFormContainer = styled.form`
  padding-top: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    color: var(--mainText);
    margin: 0;
    padding-bottom: 32px;
  }
`;

const StyledLink = styled(Link).attrs({
  className: 'font-body4',
})`
  align-self: start;
  padding-left: 15px;
  color: var(--secondaryText);
`;

const Title = styled.h2.attrs({
  className: 'font-h1',
})`
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--elementBackground);
  text-align: center;
`;

const InputContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const ButtonContainer = styled.div`
  width: 270px;
`;

interface FormPropsTypes {
  resetForm: () => void;
}

export default ChangesSuccessForm;

import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import ShowPassIcon from '../../static/icons/show_pass.svg';

const PasswordInput = ({ placeholder, value, error, onChange }: InputTypes) => {
  const [inputType, setInputType] = React.useState('password');

  const handleClick = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  return (
    <InputContainer>
      <StyledInput
        placeholder={placeholder}
        type={inputType}
        value={value}
        error={error}
        onChange={onChange}
      />
      <IconContainer src={ShowPassIcon} onClick={handleClick} />
    </InputContainer>
  );
};

const IconContainer = styled(ReactSVG)`
  position: absolute;
  cursor: pointer;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledInput = styled(({ error, ...rest }) => <input {...rest} />)`
  width: 100%;
  height: 48px;
  border: ${(props) =>
    props.error ? '1px solid var(--mainAccent)' : '1px solid var(--mainObjects)'};
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 45px;
  color: var(--mainText);

  ::placeholder {
    color: var(--secondaryText);
  }

  :focus {
    border: 1px solid var(--elementBackground);
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

interface InputTypes {
  placeholder: string;
  value: string;
  error: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default PasswordInput;

import React from 'react';
import styled from 'styled-components';
import CustomPieChart from '../molecules/CustomPieChart';
import { MetricsType, DefaultObj } from '../types/liveReport';
import createMetricData from '../utils/createMetricData';

const colorTypes = {
  chartOne: '#960018',
  chartOneBackGround: 'rgba(150, 0, 24, 0.1)',
  chartTwo: '#6B77C4',
  chartTwoBackGround: 'rgba(107, 119, 196, 0.1)',
  chartThree: '#F29C38',
  chartThreeBackGround: 'rgba(242, 156, 56, 0.1)',
  chartFour: '#48A9EE',
  chartFourBackGround: 'rgba(72, 169, 238, 0.1)',
  chartFive: '#365c77',
  chartFiveBackGround: 'rgba(54, 92, 119, 0.1)',
  chartSix: '#ec00e5',
  chartSixBackGround: 'rgba(236, 0, 229, 0.1)',
};

// export interface MetricsType {
//   posted: MetricObjType;
//   postedIssue: MetricObjType;
//   postedLate: MetricObjType;
//   toBePosted: MetricObjType;
// }

const ChartsSection = ({ metricsData }: ChartsTypes) => {
  return (
    <ChartsContainer>
      <StyledChartContainer>
        <CustomPieChart
          data={createMetricData(
            metricsData.posted,
            colorTypes.chartOne,
            colorTypes.chartOneBackGround
          )}
          name="Posted"
        />
      </StyledChartContainer>
      <StyledChartContainer>
        <CustomPieChart
          data={createMetricData(
            metricsData.toBePosted,
            colorTypes.chartTwo,
            colorTypes.chartTwoBackGround
          )}
          name="To be Posted"
        />
      </StyledChartContainer>
      <StyledChartContainer>
        <CustomPieChart
          data={createMetricData(
            metricsData.postedLate,
            colorTypes.chartThree,
            colorTypes.chartThreeBackGround
          )}
          name="Posted Late"
        />
      </StyledChartContainer>
      <StyledChartContainer>
        <CustomPieChart
          data={createMetricData(
            metricsData.postedIssue,
            colorTypes.chartFour,
            colorTypes.chartFourBackGround
          )}
          name="Posting Issues"
        />
      </StyledChartContainer>
      <StyledChartContainer>
        <CustomPieChart
          data={createMetricData(
            metricsData.totalLiveHours,
            colorTypes.chartFive,
            colorTypes.chartFiveBackGround
          )}
          name="Total Live Hours"
        />
      </StyledChartContainer>
      <StyledChartContainer>
        <CustomPieChart
          data={createMetricData(
            metricsData.audience,
            colorTypes.chartSix,
            colorTypes.chartSixBackGround
          )}
          name="Audience Reached"
        />
      </StyledChartContainer>
    </ChartsContainer>
  );
};

const ChartsContainer = styled.div`
  background: var(--mainBackground);
  display: flex;
  justify-content: space-between;
  padding: 24px 35px 10px 35px;
  align-items: center;
  height: 100%;
  border-radius: 5px;
`;

const StyledChartContainer = styled.div`
  margin: 0 10px;
  width: 16.6%;
`;

interface ChartsTypes {
  metricsData?: MetricsType | DefaultObj;
}

export default ChartsSection;

import React from 'react';
import styled from 'styled-components';

const PageNotFound = () => {
  return (
    <Container>
      <h1>Page not found</h1>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: var(--secondaryBackground);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PageNotFound;

import React from 'react';
import styled from 'styled-components';
import PagePaginate from '../../molecules/PagePaginate';
import { PosterType, ModeratorIssueStatus } from '../../types/liveReport';
import { customScroll } from '../../styles/GlobalStyles';
import PaperBoxContainer from '../../atoms/PaperBox';
import PostDataCard from '../../molecules/PostDataCard';

import ModeratorPosterCard from './ModeratorPosterCard';

const ModeratorPosterViewList = React.memo(
  ({
    filterIsOpen,
    postersList,
    fetchProcess,
    amountOfPages,
    handleApproveSinglePoster,
    handlePageChange,
    handleApproveAllPosters,
    getPosters,
    page,
  }: PropsTypes) => {
    const gridRef = React.useRef(null);
    const posterCard = React.useRef<HTMLDivElement>(null);
    const rowRef = React.useRef(null);

    const [mousePosition, setMousePosition] = React.useState(null);
    const [modalPosterPosition, setModalPosterPosition] = React.useState(null);
    const [posterInfo, setPosterInfo] = React.useState(null);
    const rowOnScreen = React.useRef(null);

    const [gridTableHeight, setGridTableHeight] = React.useState(null);
    const [rowHeight, setRowHeight] = React.useState(null);
    const [rowsOnScreen, setRowsOnScreen] = React.useState(0);
    const [isSelectAll, setIsSelectAll] = React.useState(false);
    const [selectAllArray, setSelectAllArray] = React.useState([]);
    const [minHeightForPosterCard, setMinHeightForPosterCard] = React.useState(null);

    const [amountOnPageResults, setAmountOnPageResults] = React.useState(null);

    const [startResultView, setStartResultView] = React.useState(1);
    const [endResultView, setEndResultView] = React.useState(null);
    const innerHeight = Math.floor(window.innerHeight);

    const selectAllViewPosters = () => {
      const postersToSelect = [];
      postersList.forEach((poster, index) => {
        if (
          index + 1 >= startResultView &&
          index + 1 <= endResultView &&
          poster.moderatorIssue.toString() !== ModeratorIssueStatus.APPROVED
        ) {
          return postersToSelect.push(poster.id);
        }
        return false;
      });
      setSelectAllArray(postersToSelect);
    };

    const setModalPosterInfo = React.useCallback((poster) => {
      setPosterInfo(poster);
    }, []);

    const clearModalPosterInfo = React.useCallback(() => {
      setPosterInfo(null);
    }, []);

    const handleSelectAll = () => {
      selectAllViewPosters();
      setIsSelectAll(true);
    };

    const handleCancelSelectAll = () => {
      setIsSelectAll(false);
      setSelectAllArray([]);
    };

    React.useEffect(() => {
      if (posterCard && posterCard.current) {
        const posterHeight = posterCard.current.getBoundingClientRect().height;
        const clientYPosition =
          posterHeight + mousePosition.clientY > innerHeight
            ? innerHeight - posterHeight
            : mousePosition.clientY;
        setModalPosterPosition({
          clientX: mousePosition.clientX,
          clientY: clientYPosition,
        });
      }
      // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [posterCard, posterInfo, innerHeight]);

    const setMouseCoordinates = React.useCallback(
      (coordinates) => {
        if (posterInfo) return;
        setMousePosition(coordinates);
      },
      [posterInfo]
    );

    const setAmountOnPageOfResults = React.useCallback(
      (value) => {
        setStartResultView(1);
        if (!filterIsOpen) {
          const maxResultView =
            postersList.length <= rowsOnScreen * 7 ? postersList.length : rowsOnScreen * 7;
          setAmountOnPageResults(rowsOnScreen * 7);
          setEndResultView(maxResultView);
          return;
        }
        const maxResultView =
          postersList.length <= rowsOnScreen * 5 ? postersList.length : rowsOnScreen * 5;
        setAmountOnPageResults(rowsOnScreen * 5);
        setEndResultView(maxResultView);
      },
      [filterIsOpen, postersList, rowsOnScreen]
    );

    const changeResultPosition = (value) => {
      if (!filterIsOpen) {
        const maxResultView =
          postersList.length <= amountOnPageResults + value * 7
            ? postersList.length
            : amountOnPageResults + value * 7;
        setStartResultView(1 + value * 7 > postersList.length ? postersList.length : 1 + value * 7);
        setEndResultView(maxResultView);
        return;
      }
      const maxResultView =
        postersList.length <= amountOnPageResults + value * 5
          ? postersList.length
          : amountOnPageResults + value * 5;
      setStartResultView(1 + value * 5 > postersList.length ? postersList.length : 1 + value * 5);
      setEndResultView(maxResultView);
    };

    React.useEffect(() => {
      if (gridRef && gridRef.current && rowRef && rowRef.current) {
        setGridTableHeight(Math.round(gridRef.current.getBoundingClientRect().height));
        setRowHeight(Math.round(rowRef.current.getBoundingClientRect().height) + 8); // height of element + margin;
        setMinHeightForPosterCard(Math.ceil(rowRef.current.getBoundingClientRect().height));
        setRowsOnScreen(
          Math.round(
            gridRef.current.getBoundingClientRect().height /
              (rowRef.current.getBoundingClientRect().height + 8)
          )
        );
        gridRef.current.scrollTop = 0;
      }
    }, [gridRef, rowRef, postersList]);

    React.useEffect(() => {
      if (gridTableHeight && rowHeight) {
        const amountOnScreen = Math.ceil(gridTableHeight / rowHeight);
        setAmountOnPageOfResults(amountOnScreen);
      }
    }, [gridTableHeight, rowHeight, setAmountOnPageOfResults]);

    const handleScroll = (e) => {
      const divScrollPosition = e.target.scrollTop;
      const rowsOnScroll = Math.round(divScrollPosition / rowHeight);
      if (rowsOnScroll !== rowOnScreen.current) {
        rowOnScreen.current = rowsOnScroll;
        changeResultPosition(rowsOnScroll);
      }
    };

    const handleApproveAll = () => {
      handleApproveAllPosters(selectAllArray);
      handleCancelSelectAll();
    };

    return (
      <ModeratorPosterContainer>
        <SectionTitleContainer>
          <SectionTitle>Posting History</SectionTitle>
          {postersList && postersList.length ? (
            <PosterSelectControlsContainer>
              {isSelectAll ? (
                <>
                  <span>{selectAllArray.length} items selected</span>
                  {/* eslint-disable-next-line */}
                  <span className="approve_all_btn" onClick={() => handleApproveAll()}>
                    Approve All
                  </span>
                  {/* eslint-disable-next-line */}
                  <span className="cancel_all_btn" onClick={handleCancelSelectAll}>
                    Cancel
                  </span>
                </>
              ) : (
                <PosterControl onClick={handleSelectAll}>Select All</PosterControl>
              )}
            </PosterSelectControlsContainer>
          ) : null}
        </SectionTitleContainer>
        {/* eslint-disable */}
        {/* format linter conflict */}
        <PosterListContainer onScroll={handleScroll} ref={gridRef}>
          {postersList && postersList.length
            ? postersList?.map((poster) => (
                <StyledPaperBoxContainer
                  key={poster.id}
                  filterIsOpen={filterIsOpen}
                  minHeightForPosterCard={minHeightForPosterCard}
                >
                  <StyledModeratorPosterCard
                    poster={poster}
                    forwardRef={rowRef}
                    handleApproveSinglePoster={handleApproveSinglePoster}
                    getPosters={getPosters}
                    setModalPosterInfo={setModalPosterInfo}
                    setMouseCoordinates={setMouseCoordinates}
                    clearModalPosterInfo={clearModalPosterInfo}
                    isSelectByAll={
                      selectAllArray.length
                        ? selectAllArray.some((posterId) => posterId === poster.id)
                        : false
                    }
                  />
                </StyledPaperBoxContainer>
              ))
            : !fetchProcess && <EmptyMessage>No poster info</EmptyMessage>}
        </PosterListContainer>
        {/* eslint-enable */}
        <PaginationContainer>
          {postersList.length ? (
            <AmountOfResultsContainer>
              {startResultView} - {endResultView} of {postersList.length} Results
            </AmountOfResultsContainer>
          ) : null}
          {amountOfPages && amountOfPages > 1 ? (
            <PagePaginate
              amountOfPages={amountOfPages}
              handlePageChange={handlePageChange}
              page={page}
            />
          ) : null}
        </PaginationContainer>
        {posterInfo && (
          <StyledPaperPosterBoxContainer position={modalPosterPosition} forwardRef={posterCard}>
            <PostDataCard postData={posterInfo} isModerator={true} />
          </StyledPaperPosterBoxContainer>
        )}
      </ModeratorPosterContainer>
    );
  }
);

const ModeratorPosterContainer = styled.div`
  width: 100%;
`;

const SectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SectionTitle = styled.p.attrs({
  className: 'font-body3',
})`
  color: var(--mainText);
  margin: 0;
  margin-left: 7px;
  min-height: 20px;
`;

const PosterControl = styled.span.attrs({
  className: 'font-body3',
})`
  color: var(--mainText);
  margin: 0;
  margin-left: 7px;
  min-height: 20px;
  cursor: pointer;
`;

const PosterSelectControlsContainer = styled.div`
  display: flex;

  & > .cancel_all_btn {
    font-weight: bold;
    color: var(--mainAccent);
    cursor: pointer;
  }

  & > .approve_all_btn {
    font-weight: bold;
    color: var(--elementBackground);
    cursor: pointer;
  }

  span {
    margin-left: 12px;
  }
`;

const PosterListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  ${customScroll}
  position: relative;
  max-height: 1000px;
`;

const StyledPaperBoxContainer = styled(({ filterIsOpen, forwardRef, ...rest }) => (
  <PaperBoxContainer {...rest} forwardRef={forwardRef} />
))`
  position: relative;
  border-radius: 5px;
  box-shadow: none;
  margin: 4px;
  min-height: ${(props) => props.minHeightForPosterCard}px;
  width: ${(props) => (props.filterIsOpen ? 'calc(20% - 8px)' : 'calc(100% / 7 - 8px)')};

  ${(props) =>
    props.filterIsOpen
      ? `
  :nth-child(-n+5) {
    margin-top: 0px;
  }
  `
      : `
  :nth-child(-n+7) {
    margin-top: 0px;
  }
  `};
`;

const StyledPaperPosterBoxContainer = styled(({ position, ...rest }) => (
  <PaperBoxContainer {...rest} />
))`
  position: fixed;
  border-radius: 5px;
  top: ${(props) => `${props.position?.clientY - 100}px` || '300px;'};
  left: ${(props) => `${props.position?.clientX - 300}px` || '300px;'};
  z-index: 15;
`;

const EmptyMessage = styled.p.attrs({
  className: 'font-h2',
})`
  text-align: center;
  width: 100%;
  padding: 40px 0;
`;

const StyledModeratorPosterCard = styled(({ postData, forwardRef, ...rest }) => (
  <ModeratorPosterCard {...rest} postData={postData} forwardRef={forwardRef} />
))`
  width: 100%;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 0px;
  align-items: center;
  justify-content: space-between;
`;

const AmountOfResultsContainer = styled.p.attrs({
  className: 'font-body1',
})`
  color: var(--secondaryText);
  margin: 0;
`;

interface PropsTypes {
  filterIsOpen: boolean;
  postersList: PosterType[];
  fetchProcess: boolean;
  amountOfPages: number | null;
  handlePageChange: (pageNumber: number) => void;
  handleApproveSinglePoster: (id: number) => void;
  handleApproveAllPosters: (idArray: number[]) => void;
  getPosters: () => void;
  page: number;
}

export default ModeratorPosterViewList;

import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import styled from 'styled-components';

const CustomPieChart = ({ name, data }: ChartProps) => {
  return (
    <ChartContainer>
      <Container>
        <StyledPieChart
          data={data}
          background={data[0].background}
          totalValue={100}
          lengthAngle={-360}
          lineWidth={20}
          paddingAngle={0}
          radius={50}
          rounded
          startAngle={270}
          viewBoxSize={[100, 100]}
        />
        <LabelContainer color={data[0]?.color} disabled={!!data}>
          {data[0]?.title}
        </LabelContainer>
      </Container>
      <ChartNameContainer disabled={!!data}>{name}</ChartNameContainer>
    </ChartContainer>
  );
};

const StyledPieChart = styled(PieChart)``;

const ChartContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  width: 63px;
  height: 63px;
  margin: 0 auto;
  position: relative;
`;

const ChartNameContainer = styled(({ disabled, ...rest }) => <p {...rest} />).attrs({
  className: 'font-body4',
})`
  text-align: center;
  color: ${(props) => (props.disabled ? 'var(--secondaryText)' : 'var(--mainText)')};
  margin-bottom: 0;
`;

const LabelContainer = styled(({ color, ...rest }) => <span {...rest} />)`
  display: block;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.color};
  ${(props) =>
    props.disabled
      ? `
  font-size: 12px;
  `
      : `
  font-weight: bold;
  font-size: 14px;
  `}
`;

interface ChartProps {
  data: ChartData[];
  name: string;
}

interface ChartData {
  color: string;
  value: number;
  background: string;
  title: string | number;
}

export default CustomPieChart;

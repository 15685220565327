import React from 'react';
import styled from 'styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import Textarea from '../atoms/form/Textarea';
import FormMessageContainer from '../features/FormMessageContainer';
import { ErrorArrayedObj } from '../types/authTypes';

const AddCommentDialog = ({ closeModal, addComment, errors }: AddCommentDialogTypes) => {
  const [inputValue, setInputValue] = React.useState('');
  const [error, setError] = React.useState(false);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (error) {
      setError(false);
    }
  };

  const handleSubmit = () => {
    if (!inputValue) {
      return setError(true);
    }
    return addComment(inputValue);
  };

  return (
    <AddCommentDialogContainer>
      <StyledTextarea
        placeholder="Add comment..."
        value={inputValue}
        onChange={handleChange}
        error={error || !!errors.moderatorComment}
      />
      <MessageContainer>
        <FormMessageContainer minHeight={40} errors={errors} />
      </MessageContainer>
      <ButtonsContainer>
        <StyledDefaultButton isLight onClick={closeModal}>
          Cancel
        </StyledDefaultButton>
        <StyledDefaultButton onClick={handleSubmit}>Submit</StyledDefaultButton>
      </ButtonsContainer>
    </AddCommentDialogContainer>
  );
};

const AddCommentDialogContainer = styled.div`
  width: 368px;
  padding: 16px;
`;

const StyledTextarea = styled(Textarea)`
  height: 200px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 0 38px;
`;

const StyledDefaultButton = styled(DefaultButton)`
  min-width: 115px;
  height: 32px;
  margin: 0 8px;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

interface AddCommentDialogTypes {
  closeModal: () => void;
  addComment: (comment: string) => void;
  errors: ErrorArrayedObj;
}

export default AddCommentDialog;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: RequestApiErrorState = {
  errorData: null,
  errorStatus: null,
};

const reducerName = 'requestApiError';

const slice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setServerError: (state, action: PayloadAction<PayloadErrorsObj>) => {
      const { status } = action.payload;
      state.errorStatus = status;
      state.errorData = {
        title: 'Server Error',
        msg: 'Please, try later...',
      };
    },
    setError: (state, action: PayloadAction<PayloadErrorsObj>) => {
      const { status, data } = action.payload;
      state.errorStatus = status;
      state.errorData = data;
    },
    clearRequestError: (state) => {
      state.errorData = null;
      state.errorStatus = null;
    },
  },
});

export const { clearRequestError, setServerError, setError } = slice.actions;

export const isOpenErrorModalSelector = ({ requestApiError }: PartialRootState) =>
  !!requestApiError.errorData;

export const requestApiErrorObjSelector = ({ requestApiError }: PartialRootState) => {
  const { errorData, errorStatus } = requestApiError;
  return {
    data: errorData,
    status: errorStatus,
  };
};

interface PayloadErrorsObj {
  data?: ErrorsObj;
  status: null | number;
}
interface RequestApiErrorState {
  errorData: ErrorsObj | null;
  errorStatus: number | null;
}

interface ErrorsObj {
  [key: string]: string;
}

type PartialRootState = {
  [reducerName]: RequestApiErrorState;
};

export default slice.reducer;

import React from 'react';
import styled from 'styled-components';
import CloseModalButton from '../atoms/buttons/CloseModalButton';

const DefaultModal = ({ children, closeModal, className }: PropsTypes) => {
  React.useEffect(() => {
    // eslint-disable-next-line
    const listener = (event: any) => {
      event.stopPropagation();
      if (!ref.current || ref.current.contains(event.target)) return;
      closeModal();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [closeModal]);

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Container>
      <ModalContainer className={className} ref={ref}>
        <CLoseButtonContainer>
          <CloseModalButton onClick={() => closeModal()} />
        </CLoseButtonContainer>
        <ChildrenContainer>{children}</ChildrenContainer>
      </ModalContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  height: 100vh;
  background: var(--modalWindowBackground);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: auto;
  max-width: 70%;
  border-radius: 5px;
  position: relative;
`;

const ChildrenContainer = styled.div`
  width: 100%;

  & > img {
    max-width: 100%;
    max-height: 100vh;
  }
`;

const CLoseButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: -20px;
`;

interface PropsTypes {
  children: React.ReactNode;
  closeModal: () => void;
  className?: string;
}

export default DefaultModal;

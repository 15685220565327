import React from 'react';
import styled from 'styled-components';

const Textarea = ({ placeholder, label, value, error, onChange, className }: InputTypes) => (
  <InputContainer className={className}>
    <StyledLabel htmlFor={label} error={error}>
      {label}
      <StyledInput
        id={label}
        onChange={onChange}
        value={value}
        error={error}
        placeholder={placeholder}
      />
    </StyledLabel>
  </InputContainer>
);

const InputContainer = styled.div`
  height: 100%;
`;

const StyledLabel = styled(({ label, error, ...rest }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label htmlFor={label} {...rest} />
)).attrs({
  className: 'font-body4',
})`
  padding-left: 2px;
  color: var(--secondaryText);
  color: ${(props) => (props.error ? 'var(--mainAccent)' : 'var(--secondaryText)')};
`;

const StyledInput = styled(({ error, ...rest }) => <textarea {...rest} />)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  outline: none;
  font-size: 12px;
  color: ${(props) => (props.error ? 'var(--mainAccent)' : 'var(--mainText)')};
  padding: 8px 20px 13px 20px;
  padding-top: 6px;
  resize: none;
  border: 0.5px solid ${(props) => (props.error ? 'var(--mainAccent)' : 'var(--mainObjects)')};
  border-radius: 5px;
`;

interface InputTypes {
  label?: string;
  value: string;
  error?: boolean;
  placeholder?: string;
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default Textarea;

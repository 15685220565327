import React from 'react';
import styled from 'styled-components';
import TextInput from '../../atoms/form/TextInput';
import FormMessageContainer from '../../features/FormMessageContainer';
import { RestorePassFormProps } from '../../types/authTypes';
import DefaultButton from '../../atoms/buttons/DefaultButton';

const EmailForm = ({ errors, values, onSubmit, onFieldChange }: RestorePassFormProps) => {
  return (
    <EmailFormContainer onSubmit={onSubmit}>
      <Title>Forgot password?</Title>
      <p>Please enter your email below and follow instructions.</p>
      <InputContainer>
        <TextInput
          type="email"
          placeholder="Email address..."
          value={values.email}
          error={!!errors.email}
          onChange={onFieldChange('email')}
        />
      </InputContainer>
      <MessageContainer>
        <FormMessageContainer minHeight={80} errors={errors} />
      </MessageContainer>
      <ButtonContainer>
        <DefaultButton isSubmit>Submit</DefaultButton>
      </ButtonContainer>
    </EmailFormContainer>
  );
};

const EmailFormContainer = styled.form`
  padding-top: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: var(--mainText);
    margin: 0;
  }
`;

const Title = styled.h2.attrs({
  className: 'font-h1',
})`
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--elementBackground);
  text-align: center;
`;

const InputContainer = styled.div`
  width: 100%;
  padding-top: 48px;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const ButtonContainer = styled.div`
  width: 270px;
`;

export default EmailForm;

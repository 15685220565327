import React from 'react';
import styled from 'styled-components';

const InfoModal = ({ children, className }: InfoModalTypes) => {
  return (
    <InfoModalContainer className={className}>
      <ContentContainer>{children}</ContentContainer>
      <ArrowContainer />
    </InfoModalContainer>
  );
};

const InfoModalContainer = styled.div`
  position: absolute;
  width: auto;
`;

const ContentContainer = styled.div`
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.09);
  border-radius: 15px;
  background: var(--defaultBody);
  width: max-content;
  padding: 5px;
`;

const ArrowContainer = styled.div`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 30px solid var(--defaultBody);
  margin: 0 auto;
`;

interface InfoModalTypes {
  children: React.ReactNode;
  className?: string;
}

export default InfoModal;

import React from 'react';
import styled from 'styled-components';
import TextInput from '../atoms/form/TextInput';
import DefaultButton from '../atoms/buttons/DefaultButton';
import FormMessageContainer from '../features/FormMessageContainer';

import { UserSettingsFormState, ErrorArrayedObj } from '../types/authTypes';

const UserSettingsForm = ({
  values,
  errors,
  successMessage,
  onSubmit,
  onFieldChange,
}: UserSettingsFormTypes) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <RegistrationFormContainer onSubmit={handleClick}>
      <DoubleInputContainer>
        <TextInput
          type="text"
          placeholder="First name..."
          onChange={onFieldChange('firstName')}
          value={values.firstName}
          error={!!errors.firstName}
          label="First name"
        />
        <TextInput
          type="text"
          placeholder="Last name..."
          onChange={onFieldChange('lastName')}
          value={values.lastName}
          error={!!errors.lastName}
          label="Last name"
        />
      </DoubleInputContainer>
      <InputContainer>
        <TextInput
          type="text"
          placeholder="Client..."
          onChange={onFieldChange('client')}
          value={values.client}
          error={!!errors.client}
          disabled
          label="Client"
        />
      </InputContainer>
      <InputContainer>
        <TextInput
          type="email"
          placeholder="Email..."
          onChange={onFieldChange('email')}
          value={values.email}
          error={!!errors.email}
          label="Email"
        />
      </InputContainer>

      <MessageContainer>
        <FormMessageContainer minHeight={80} errors={errors} successMessage={successMessage} />
      </MessageContainer>
      <ButtonContainer>
        <DefaultButton isSubmit>Update</DefaultButton>
      </ButtonContainer>
    </RegistrationFormContainer>
  );
};

const RegistrationFormContainer = styled.form`
  width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.div`
  margin: 8px 0;
  width: 100%;
  margin: 8px 0;
`;

const DoubleInputContainer = styled.div`
  display: flex;
  width: calc(100% + 16px);
  margin: 8px 0;

  & > div {
    margin: 0 8px;
  }
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const ButtonContainer = styled.div`
  width: 270px;
`;

interface UserSettingsFormTypes {
  values: UserSettingsFormState;
  onSubmit: () => void;
  successMessage: string | null;
  onFieldChange: (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: ErrorArrayedObj;
}

export default UserSettingsForm;

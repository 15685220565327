import { createGlobalStyle, css } from 'styled-components';

// TODO check TS config to import
import PoppinsRegular from '../fonts/Poppins-Regular.ttf';
import PoppinsBold from '../fonts/Poppins-Bold.ttf';

export const bodyStyles = css`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin: 0;
`;

export const boldFontStyles = css`
  font-family: 'PoppinsBold', sans-serif;
  font-weight: bold;
`;

export const fontH1Styles = css`
  ${boldFontStyles};
  font-size: 24px;
  line-height: 36px;
`;

export const fontH2Styles = css`
  ${boldFontStyles};
  font-size: 16px;
`;

export const fontBody1Styles = css`
  font-size: 16px;
`;

export const fontBody2Styles = css`
  font-weight: bold;
  font-size: 14px;
`;

export const fontBody3Styles = css`
  font-size: 14px;
`;

export const fontBody4Styles = css`
  font-size: 12px;
`;

export const customScroll = css`
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--mainObjects);
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--mainObjects);
  }
`;

const GlobalStyle = createGlobalStyle`
  :root {
    ${({ theme }) => css`
      --mainText: ${theme.mainText};
      --mainBackground: ${theme.mainBackground};
      --secondaryBackground: ${theme.secondaryBackground};
      --elementBackground: ${theme.elementBackground};
      --darkHover: ${theme.darkHover};
      --mainAccent: ${theme.mainAccent};
      --secondaryText: ${theme.secondaryText};
      --mainObjects: ${theme.mainObjects};
      --transparentBackground: ${theme.transparentBackground};
      --defaultBody: ${theme.defaultBody};
      --modalWindowBackground: ${theme.modalWindowBackground};
      --footerLightText: ${theme.footerLightText};
    `}
  }

  @font-face {
      font-family: 'Poppins';
      font-style: normal;
      src: local('Poppins'),
      url(${PoppinsRegular}) format('ttf');
    }

  @font-face {
      font-family: 'PoppinsBold';
      font-style: normal;
      font-weight: bold;
      src: local('PoppinsBold'),
      url(${PoppinsBold}) format('ttf');
    }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  body {
    ${bodyStyles}
    ${customScroll}
  }

  body.body--overflow {
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: var(--mainText);
  }

  .layout-wrap {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 56px;
  }

  .font-h1 {
    ${fontH1Styles}
  }

  .font-h2 {
    ${fontH2Styles}
  }

  .font-body1 {
    ${fontBody1Styles}
  }

  .font-body2 {
    ${fontBody2Styles}
  }

  .font-body3 {
    ${fontBody3Styles}
  }

  .font-body4 {
    ${fontBody4Styles}
  }
`;

export default GlobalStyle;

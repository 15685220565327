import React from 'react';
import styled from 'styled-components';
import DashboardFooter from '../organisms/DashboardFooter';
import DashboardHeader from '../organisms/DashboardHeader';

const DashboardTemplate = ({ body, isFooterHide, showCompanyName }: AuthTemplateTypes) => {
  return (
    <RootContainer isFooterHide={isFooterHide}>
      <HeaderContainer>
        <LayoutWrapper>
          <DashboardHeader showCompanyName={showCompanyName} />
        </LayoutWrapper>
      </HeaderContainer>
      <LayoutWrapper>
        <BodyContainer>{body}</BodyContainer>
      </LayoutWrapper>

      {!isFooterHide && (
        <FooterContainer>
          <LayoutWrapper>
            <DashboardFooter />
          </LayoutWrapper>
        </FooterContainer>
      )}
    </RootContainer>
  );
};

const RootContainer = styled(({ isFooterHide, ...rest }) => <div {...rest} />)`
  width: 100%;
  min-height: 100vh;
  background: var(--defaultBody);
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.isFooterHide ? 'flex-start' : 'space-between')};
  align-items: center;
`;

const LayoutWrapper = styled.div.attrs({
  className: 'layout-wrap',
})``;

const HeaderContainer = styled.div`
  width: 100%;
  background: var(--secondaryBackground);
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
`;

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 32px 0 24px 0;
  background: var(--elementBackground);
`;

interface AuthTemplateTypes {
  body: React.ReactNode;
  showCompanyName?: boolean;
  isFooterHide?: boolean;
}

export default DashboardTemplate;

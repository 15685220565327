import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import DashboardTemplate from '../templates/DashboardTemplate';
import { userInfoSettingsSelector, userIdInfoSelector, setUserInfo } from '../redux/ducks/userAuth';
import { setServerError } from '../redux/ducks/requestApiError';
import { UserSettingsFormState } from '../types/authTypes';
import { checkEmptyFields, checkEmptyObj } from '../utils/validator';
import { updateUserInfo } from '../api/userAuth';
import { setAuthData } from '../utils/auth';
import MainLoader from '../atoms/loaders/MainLoader';
import UserSettingsForm from '../organisms/UserSettingsForm';

const UserSettingsPage = () => {
  const userSettingsInfo = useSelector(userInfoSettingsSelector);
  const userId = useSelector(userIdInfoSelector);

  const [formState, setFormState] = React.useState<UserSettingsFormState>(userSettingsInfo);
  const [errors, setError] = React.useState({});
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [fetchProcess, setFetchProcess] = React.useState(false);

  const dispatch = useDispatch();

  const handleChangeField = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFormState({
      ...formState,
      [key]: newValue,
    });
  };

  const onSubmit = () => {
    setFetchProcess(true);
    const newError = checkEmptyFields(formState);
    if (checkEmptyObj(newError)) {
      setFetchProcess(false);
      return setError(newError);
    }
    return updateUserInfo(formState, userId)
      .then((response) => {
        const { data } = response;
        setError({});
        setFetchProcess(false);
        setAuthData(data.token, data.user);
        dispatch(setUserInfo(data.user));
        setSuccessMessage('Your information has been updated');
      })
      .catch((error) => {
        const { data, status } = error.response;
        setSuccessMessage(null);
        setFetchProcess(false);
        if (status >= 500) {
          dispatch(setServerError(status));
          return;
        }
        setError(data);
      });
  };

  const bodyContent = (
    <>
      {fetchProcess && <MainLoader />}
      <UserSettingsContainer>
        <TitleContainer>
          <Title>Manage your Account</Title>
        </TitleContainer>

        <UserSettingsForm
          values={formState}
          errors={errors}
          onSubmit={onSubmit}
          successMessage={successMessage}
          onFieldChange={handleChangeField}
        />
      </UserSettingsContainer>
    </>
  );

  return <DashboardTemplate body={bodyContent} isFooterHide={true} />;
};

const UserSettingsContainer = styled.div`
  margin: 88px 0;
  max-width: 560px;
  padding: 58px 0 64px 0;
  width: 560px;
  border-radius: 15px;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
  background: var(--defaultBody);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  width: 100%;
  padding-bottom: 33px;
  display: flex;
  justify-content: center;
`;

const Title = styled.h2.attrs({
  className: 'font-h1',
})`
  margin: 0;
  text-align: center;
`;

export default UserSettingsPage;

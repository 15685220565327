import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { userRoleSelector } from '../redux/ducks/userAuth';
import RouteTypes from '../pages/routes.enum';

const PermissionCheck = ({ children }: Props) => {
  const userRole = useSelector(userRoleSelector);

  const checkUserPermissions = () => {
    if (userRole === 'moderator') {
      return true;
    }
    if (userRole === 'super_moderator') {
      return true;
    }
    return false;
  };

  return (
    <>
      {checkUserPermissions() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: RouteTypes.LOG_IN,
          }}
        />
      )}
    </>
  );
};

interface Props {
  children?: React.ReactNode;
}

export default PermissionCheck;

import snakeCaseKeys from 'snakecase-keys';
import { OptionsType, DefaultObj } from '../types/liveReport';

export const createLookupQuery = (selectedOptions: OptionsType) => {
  if (!selectedOptions || typeof selectedOptions !== 'object') return '';
  let query = '';
  Object.entries(snakeCaseKeys(selectedOptions)).forEach(([key, optionValue]) => {
    if (!optionValue || !Array.isArray(optionValue)) return;
    if (!optionValue.length) return;
    optionValue.forEach((option) => {
      if (!query.length) {
        query = `?${key}=${encodeURIComponent(option.value)}`;
        return;
      }
      query += `&${key}=${encodeURIComponent(option.value)}`;
    });
  });
  return query;
};

const getValidPosterKey = (key) => {
  if (!key) return '';
  if (key === 'campaign') return 'campaign__in';
  if (key === 'refId') return 'ref_id__in';
  if (key === 'design') return 'design__in';
  if (key === 'mediaOwner') return 'media_owner__in';
  if (key === 'inCharge') return 'in_charge__in';
  if (key === 'size') return 'size__in';
  if (key === 'moderatorIssue') return 'moderator_issue__in';
  return key;
};

const parseOptionArrayedValuesToString = (optionArray) => {
  const valuesArray = optionArray.map((option, index) => {
    return encodeURIComponent(option.value);
  });
  return valuesArray.join(',');
};

export const createPosterQuery = (selectedOptions: DefaultObj) => {
  if (!selectedOptions || typeof selectedOptions !== 'object') return '';
  let query = '';
  Object.entries(selectedOptions).forEach(([key, optionValue]) => {
    if (!optionValue || !Array.isArray(optionValue)) return;
    if (!optionValue.length) return;
    if (!query.length) {
      query += `?${getValidPosterKey(key)}=${parseOptionArrayedValuesToString(optionValue)}`;
      return;
    }
    query += `&${getValidPosterKey(key)}=${parseOptionArrayedValuesToString(optionValue)}`;
  });
  if (selectedOptions.page) {
    query.length
      ? (query += `&page=${selectedOptions.page}`)
      : (query += `?page=${selectedOptions.page}`);
  }
  if (selectedOptions.ordering) {
    query.length
      ? (query += `&ordering=${selectedOptions.ordering}`)
      : (query += `?ordering=${selectedOptions.ordering}`);
  }
  return query;
};

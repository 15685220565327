import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import DashboardTemplate from '../templates/DashboardTemplate';
import { setServerError } from '../redux/ducks/requestApiError';
import ContactSupportForm from '../organisms/ContactSupportForm';
import { SupportFormState } from '../types/authTypes';
import { checkEmptyFields, checkEmptyObj } from '../utils/validator';
import { supportRequest } from '../api/userAuth';
import MainLoader from '../atoms/loaders/MainLoader';
import RoutesTypes from './routes.enum';
import { defaultError } from '../constants/errors/errorData';

const initFormState: SupportFormState = {
  subject: '',
  message: '',
};

const SupportPage = () => {
  const [formState, setFormState] = React.useState(initFormState);
  const [errors, setError] = React.useState({});
  const [fetchProcess, setFetchProcess] = React.useState(false);

  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleChangeField = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFormState({
      ...formState,
      [key]: newValue,
    });
  };

  const onSubmit = () => {
    setFetchProcess(true);
    const newError = checkEmptyFields(formState);
    if (checkEmptyObj(newError)) {
      setFetchProcess(false);
      return setError(newError);
    }
    return supportRequest(formState)
      .then(() => {
        setError({});
        setFormState(initFormState);
        setFetchProcess(false);
        push(RoutesTypes.DASHBOARD);
      })
      .catch((error) => {
        try {
          const { data, status } = error.response;
          setFetchProcess(false);
          if (status >= 500) {
            dispatch(setServerError(status));
            return;
          }
          setError(data);
        } catch {
          dispatch(setServerError(defaultError));
        }
      });
  };

  const bodyContent = (
    <>
      {fetchProcess && <MainLoader />}
      <FormContainer>
        <ContactSupportForm
          values={formState}
          errors={errors}
          onSubmit={onSubmit}
          onFieldChange={handleChangeField}
        />
      </FormContainer>
    </>
  );

  return <DashboardTemplate body={bodyContent} isFooterHide={true} />;
};

const FormContainer = styled.div`
  padding: 88px 0;
  max-width: 560px;
  width: 560px;
`;

export default SupportPage;

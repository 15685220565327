import React from 'react';
import styled from 'styled-components';
import PaperBox from '../atoms/PaperBox';

const PaperBoxModal = ({ children, closeModal, className }: PropsTypes) => {
  React.useEffect(() => {
    // eslint-disable-next-line
    const listener = (event: any) => {
      event.stopPropagation();
      if (!ref.current || ref.current.contains(event.target)) return;
      closeModal();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [closeModal]);

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <PaperBoxContainer className={className} ref={ref}>
      <PaperBox>{children}</PaperBox>
    </PaperBoxContainer>
  );
};

const PaperBoxContainer = styled.div`
  position: absolute;
  z-index: 25;
`;

interface PropsTypes {
  children: React.ReactNode;
  closeModal: () => void;
  className?: string;
}

export default PaperBoxModal;

import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import MainLoader from '../atoms/loaders/MainLoader';
import history from '../history';
import RoutesTypes from '../pages/routes.enum';
import InfoModal from '../molecules/InfoModal';
import { Brand } from '../types/liveReport';
import { getBrandApi } from '../api/liveReport';
import { setError, setServerError } from '../redux/ducks/requestApiError';
import { defaultError } from '../constants/errors/errorData';
import { logOut } from '../utils/auth';

const DashboardSearch = () => {
  const dispatch = useDispatch();

  const [fetchProcess, setFetchProcess] = React.useState(false);
  const [brandsList, setBrandsList] = React.useState<Brand[] | null>(null);
  const [brandName, setBrandName] = React.useState(null);
  const [delayHandler, setDelayHandler] = React.useState(null);
  const [modalPosition, setModalPosition] = React.useState(null);

  React.useEffect(() => {
    const query = {
      page: 1,
      ordering: 'match',
    };

    const getBrandsList = async () => {
      setFetchProcess(true);
      try {
        await getBrandApi(query)
          .then((response) => {
            setBrandsList(response.data.results);
            setFetchProcess(false);
          })
          .catch((error) => {
            const { status, data } = error.response;
            setFetchProcess(false);
            if (status >= 500) {
              dispatch(setServerError(status));
              return;
            }
            if (status === 401) {
              logOut();
              return;
            }
            if (status === 403 && data.error === 'status') {
              dispatch(
                setError({
                  status,
                  data: {
                    msg: data.detail[0],
                  },
                })
              );
              return;
            }
            dispatch(setError(defaultError));
          });
      } catch {
        setFetchProcess(false);
        dispatch(setError(defaultError));
      }
    };

    getBrandsList();
  }, [dispatch]);

  const handleMouseEnter = (e, brand) => {
    const container = e.currentTarget;
    if (container.offsetWidth) {
      const leftPosition = container.offsetLeft + container.offsetWidth / 2;
      const topPosition = container.offsetTop;
      setDelayHandler(
        setTimeout(() => {
          setModalPosition({
            top: topPosition,
            left: leftPosition,
          });
          setBrandName(brand);
        }, 500)
      );
    }
  };

  const clearModalInfo = () => {
    clearTimeout(delayHandler);
    setModalPosition(null);
    setBrandName(null);
  };

  const handleMouseLeave = () => {
    clearModalInfo();
  };

  const handleClick = (brand) => {
    clearModalInfo();
    if (!brand.campaigns.length) {
      return history.push(`${RoutesTypes.LIVE_REPORT}`, {
        from: history.location,
      });
    }
    return history.push(
      `${RoutesTypes.LIVE_REPORT}?campaign=${brand.campaigns.join('&campaign=')}`,
      {
        from: history.location,
      }
    );
  };

  return (
    <Container>
      {fetchProcess && <MainLoader />}
      <Title>TOP Brands</Title>
      <BrandsListContainer>
        {brandsList &&
          brandsList.map((brand) => {
            return (
              <ImageContainer
                key={brand.id}
                onMouseEnter={(e) => handleMouseEnter(e, brand.name)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(brand)}
              >
                {brand.logo ? (
                  <img src={brand.logo} alt="Brand_logo" />
                ) : (
                  <div className="default-logo">
                    <p>No Logo</p>
                  </div>
                )}
              </ImageContainer>
            );
          })}
        {brandName && (
          <StyledInfoModal position={modalPosition}>
            <p>{brandName}</p>
          </StyledInfoModal>
        )}
      </BrandsListContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 160px;
  min-height: 450px;
`;

const BrandsListContainer = styled.div`
  width: calc(100% + 64px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 -32px;
  position: relative;
`;

const ImageContainer = styled.div`
  width: calc(20% - 64px);
  margin: 16px 32px;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }

  .default-logo {
    background: var(--secondaryBackground);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;

    p {
      margin: 0;
      text-align: center;
    }
  }
`;

const StyledInfoModal = styled(({ position, ...rest }) => <InfoModal {...rest} />)`
  transform: translate(-50%, -100%);
  top: ${(props) => props.position.top}px;
  left: ${(props) => props.position.left}px;
`;

const Title = styled.h1.attrs({
  className: 'font-h1',
})`
  color: var(--elementBackground);
  margin-bottom: 8px;
`;

export default DashboardSearch;

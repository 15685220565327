import React from 'react';
import styled from 'styled-components';
import { PosterType } from '../../types/liveReport';
import { customScroll } from '../../styles/GlobalStyles';
import PostDataCard from '../../molecules/PostDataCard';
import PaperBoxContainer from '../../atoms/PaperBox';

const CampaignResultGrid = ({
  data,
  filterIsOpen,
  setAmountOnPageOfResults,
  changeResultPosition,
}: CampaignResultGridProps) => {
  const gridRef = React.useRef(null);
  const rowRef = React.useRef(null);
  const rowOnScreen = React.useRef(null);
  const [gridTableHeight, setGridTableHeight] = React.useState(null);
  const [rowHeight, setRowHeight] = React.useState(null);
  const [minHeightForPosterCard, setMinHeightForPosterCard] = React.useState(null);

  React.useEffect(() => {
    if (gridRef && gridRef.current && rowRef && rowRef.current) {
      setGridTableHeight(Math.ceil(gridRef.current.getBoundingClientRect().height));
      setRowHeight(Math.ceil(rowRef.current.getBoundingClientRect().height) + 8); // height of element + margin;
      setMinHeightForPosterCard(Math.ceil(rowRef.current.getBoundingClientRect().height));

      gridRef.current.scrollTop = 0;
    }
  }, [gridRef, rowRef, data]);

  React.useEffect(() => {
    if (gridTableHeight && rowHeight) {
      const amountOnScreen = Math.ceil(gridTableHeight / rowHeight);
      setAmountOnPageOfResults(amountOnScreen);
    }
  }, [gridTableHeight, rowHeight, setAmountOnPageOfResults]);

  const handleScroll = (e) => {
    const scrollPosition = e.target.scrollTop;
    const rowsOnScroll = Math.round(scrollPosition / rowHeight);
    if (rowsOnScroll !== rowOnScreen.current) {
      rowOnScreen.current = rowsOnScroll;
      changeResultPosition(rowsOnScroll);
    }
  };

  return (
    <CampaignResultGridContainer onScroll={handleScroll} ref={gridRef}>
      {data && data.length ? (
        data?.map((postData, index) => (
          <StyledPaperBoxContainer
            key={index}
            filterIsOpen={filterIsOpen}
            minHeightForPosterCard={minHeightForPosterCard}
          >
            <StyledPostDataCard postData={postData} forwardRef={rowRef} isShowCardImage={true} />
          </StyledPaperBoxContainer>
        ))
      ) : (
        <EmptyMessage>No poster info</EmptyMessage>
      )}
    </CampaignResultGridContainer>
  );
};

const CampaignResultGridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  ${customScroll}
  position: relative;
  height: 475px;
`;

const EmptyMessage = styled.p.attrs({
  className: 'font-h2',
})`
  text-align: center;
  width: 100%;
  padding: 40px 0;
`;

const StyledPaperBoxContainer = styled(({ filterIsOpen, forwardRef, ...rest }) => (
  <PaperBoxContainer {...rest} forwardRef={forwardRef} />
))`
  position: relative;
  border-radius: 5px;
  box-shadow: none;
  margin: 4px;
  min-height: ${(props) => props.minHeightForPosterCard}px;
  width: ${(props) => (props.filterIsOpen ? 'calc(33.3% - 8px)' : 'calc(20% - 8px)')};
`;

const StyledPostDataCard = styled(({ postData, forwardRef, ...rest }) => (
  <PostDataCard {...rest} postData={postData} forwardRef={forwardRef} />
))`
  width: 100%;
`;

interface CampaignResultGridProps {
  data?: PosterType[];
  filterIsOpen: boolean;
  setAmountOnPageOfResults: (value: number) => void;
  changeResultPosition: (value: number) => void;
}

export default CampaignResultGrid;

import React from 'react';
import styled from 'styled-components';

const PaperBox = ({ children, className, forwardRef }: PropsTypes) => (
  <PaperBoxContainer className={className} ref={forwardRef}>
    {children}
  </PaperBoxContainer>
);

const PaperBoxContainer = styled.div`
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
  border-radius: 15px;
  background: var(--defaultBody);
`;

interface PropsTypes {
  children: React.ReactNode;
  className?: string;
  forwardRef?: React.Ref<HTMLDivElement>;
}

export default PaperBox;

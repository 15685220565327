import React from 'react';
import styled from 'styled-components';
import PasswordInput from '../../atoms/form/PasswordInput';
import FormMessageContainer from '../../features/FormMessageContainer';
import { RestorePassFormProps } from '../../types/authTypes';
import DefaultButton from '../../atoms/buttons/DefaultButton';

const ValidationCodeFormForm = ({
  errors,
  values,
  onSubmit,
  onFieldChange,
}: RestorePassFormProps) => {
  return (
    <CodeFormContainer onSubmit={onSubmit}>
      <Title>Create new password</Title>
      <p>
        You are one step away to reset the password for{' '}
        <EmailContainer>{values.email}</EmailContainer>
      </p>
      <InputContainer>
        <PasswordInput
          placeholder="Enter the new password..."
          value={values.password}
          error={!!errors.password}
          onChange={onFieldChange('password')}
        />
      </InputContainer>
      <MessageContainer>
        <FormMessageContainer minHeight={80} errors={errors} />
      </MessageContainer>
      <ButtonContainer>
        <DefaultButton isSubmit>Submit</DefaultButton>
      </ButtonContainer>
    </CodeFormContainer>
  );
};

const CodeFormContainer = styled.form`
  padding-top: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    color: var(--mainText);
    margin: 0;
  }
`;

const EmailContainer = styled.span`
  font-weight: bold;
`;

const Title = styled.h2.attrs({
  className: 'font-h1',
})`
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--elementBackground);
  text-align: center;
`;

const InputContainer = styled.div`
  width: 100%;
  padding-top: 48px;
`;

const MessageContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

const ButtonContainer = styled.div`
  width: 270px;
`;

export default ValidationCodeFormForm;

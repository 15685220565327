import React from 'react';
import styled from 'styled-components';
import PaperBoxModal from './PaperBoxModal';
import BurgerMenuButton from '../atoms/buttons/BurgerMenuButton';
import BurgerNavMenu from './BurgerNavMenu';

const BurgerMenu = ({ className }: BurgerProps) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const handleClick = () => {
    menuIsOpen ? setMenuIsOpen(false) : setMenuIsOpen(true);
  };

  return (
    <BurgerMenuContainer className={className}>
      <BurgerMenuButton onClick={handleClick} isOpen={menuIsOpen} />
      {menuIsOpen && (
        <StyledPaperBoxModal closeModal={handleClick}>
          <BurgerNavMenu />
        </StyledPaperBoxModal>
      )}
    </BurgerMenuContainer>
  );
};

const BurgerMenuContainer = styled.div`
  position: relative;
`;

const StyledPaperBoxModal = styled(PaperBoxModal)`
  top: 60px;
  left: 0;
`;

interface BurgerProps {
  className?: string;
}

export default BurgerMenu;

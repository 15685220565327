import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

const PagePaginate = ({ amountOfPages, handlePageChange, page }: PagePaginateTypes) => {
  return (
    <StyledReactPaginateContainer>
      <ReactPaginate
        pageCount={amountOfPages}
        onPageChange={(e) => handlePageChange(e.selected + 1)}
        initialPage={0}
        forcePage={page - 1}
        previousLabel="Back"
        nextLabel="Next"
        pageClassName="pagination-button"
        activeClassName="pagination-button--active"
        previousClassName="pagination-button__move"
        nextClassName="pagination-button__move"
        disableInitialCallback={true}
      />
    </StyledReactPaginateContainer>
  );
};

const StyledReactPaginateContainer = styled.div`
  ul {
    display: flex;
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
    cursor: pointer;
    margin: 0 2px;
    font-size: 14px;
    background: var(--mainBackground);
    border-radius: 5px;
  }

  a {
    outline: none;
    padding: 5px 11px;
    display: block;
  }

  .pagination-button {
    &--active {
      background: var(--elementBackground);

      a {
        color: var(--defaultBody);
      }
    }
  }
`;

interface PagePaginateTypes {
  handlePageChange: (pageNumber: number) => void;
  amountOfPages: number | null;
  page?: number;
}

export default PagePaginate;

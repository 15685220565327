import history from '../history';
import RoutesTypes from '../pages/routes.enum';

const ACCESS = 'accessToken';
const USER_INFO = 'userInfo';

export const getAccessToken = (): string | void => localStorage.getItem(ACCESS);

export const getStoredUserInfo = () => {
  const info = localStorage.getItem(USER_INFO);
  if (!info) return null;
  const userInfo = JSON.parse(info);
  return userInfo;
};

export const setTokenData = (accessToken: string) => {
  localStorage.setItem(ACCESS, accessToken);
};

export const setUserData = (userInfo) => {
  localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
};

export const setAuthData = (access, userInfo) => {
  setUserData(userInfo);
  setTokenData(access);
};

export const isAccessTokenInStore = () => !!getAccessToken();

export const cleanOutTokens = () => {
  localStorage.removeItem(ACCESS);
};

export const cleanOutUser = () => {
  localStorage.removeItem(USER_INFO);
};

export const logOut = () => {
  cleanOutTokens();
  cleanOutUser();

  history.push(RoutesTypes.LOG_IN);
};

export const logOutWithHistory = () => {
  cleanOutTokens();
  cleanOutUser();

  history.push(RoutesTypes.LOG_IN, {
    from: history.location,
  });
};

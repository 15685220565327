enum RoutesTypes {
  REGISTRATION = '/registration',
  LOG_IN = '/log-in',
  RESTORE_PASSWORD = '/restore-password',
  SUPPORT = '/support',
  DASHBOARD = '/dashboard',
  USER_SETTINGS = '/user-settings',
  LIVE_REPORT = '/live-report',
  MODERATOR_LIVE_REPORT = '/live-report-moderation',
  FAQ = '/faq',
}

export const HOMEPAGE = '/dashboard';

export default RoutesTypes;

import React from 'react';
import { Redirect, Route, useLocation, RouteComponentProps } from 'react-router-dom';
import RouteTypes from '../pages/routes.enum';
import { isAccessTokenInStore } from '../utils/auth';

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const location = useLocation();
  const isAuthenticated = isAccessTokenInStore();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: RouteTypes.LOG_IN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;

interface Props {
  path: string;
  exact?: boolean;
  strict?: boolean;
  sensitive?: boolean;
  component?: React.FC<RouteComponentProps<any>> | React.ComponentType;
}

import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import RoutesTypes from '../pages/routes.enum';
import logo from '../static/images/pcds_header_logo.svg';
import BurgerMenu from '../molecules/BurgerMenu';
import UserMenu from './UserMenu';
import { userCompanySelector } from '../redux/ducks/userAuth';

const DashboardHeader = ({ showCompanyName, className }: HeaderTypes) => {
  const userCompany = useSelector(userCompanySelector);
  return (
    <DashboardHeaderContainer className={className}>
      <MenuContainer>
        <Link to={RoutesTypes.DASHBOARD}>
          <ReactSVG src={logo} />
        </Link>
        <StyledBurgerMenu />
        {showCompanyName && <CompanyTitle>{userCompany}</CompanyTitle>}
      </MenuContainer>
      <UserControlMenuContainer>
        <UserMenu />
      </UserControlMenuContainer>
    </DashboardHeaderContainer>
  );
};

const DashboardHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  align-items: center;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBurgerMenu = styled(BurgerMenu)`
  margin: 0 48px;
`;

const CompanyTitle = styled.h1.attrs({
  className: 'font-h1',
})`
  color: var(--elementBackground);
  margin: 0;
`;

const UserControlMenuContainer = styled.div``;

interface HeaderTypes {
  showCompanyName?: boolean;
  className?: string;
}

export default DashboardHeader;

import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import { PosterType, ModeratorIssueStatus } from '../../types/liveReport';
import { addPosterCommentApi } from '../../api/liveReport';
import ApproveIcon from '../../static/icons/approve_icon.svg';
import AddIssueIcon from '../../static/icons/add_issue_icon.svg';
import PaperBoxModal from '../../molecules/PaperBoxModal';
import AddCommentDialog from '../../molecules/AddCommentDialog';
import { setServerError } from '../../redux/ducks/requestApiError';
import DefaultModal from '../../molecules/DefaultModal';

const ModeratorPosterCard = React.memo(
  ({
    poster,
    setModalPosterInfo,
    clearModalPosterInfo,
    setMouseCoordinates,
    className,
    forwardRef,
    isSelectByAll,
    handleApproveSinglePoster,
    getPosters,
  }: ModeratorPosterType) => {
    const [pictureModalIsOpen, setPictureModalIsOpen] = React.useState(false);
    const [delayHandler, setDelayHandler] = React.useState(null);
    const imageContainerRef = React.useRef();
    const [isApproved, setIsApproved] = React.useState(false);
    const [isCommented, setIsCommented] = React.useState(false);
    const [commentModalIsOpen, setCommentModalIsOpen] = React.useState(false);
    const [errors, setError] = React.useState<{ [key: string]: string[] }>({});

    const dispatch = useDispatch();

    const handleMouseEnter = (e) => {
      setDelayHandler(
        setTimeout(() => {
          setModalPosterInfo(poster);
        }, 500)
      );
    };

    const handleMouseMove = (e) => {
      setMouseCoordinates({
        clientX: e.clientX,
        clientY: e.clientY,
      });
    };

    const handleMouseLeave = (e) => {
      clearTimeout(delayHandler);
      clearModalPosterInfo();
    };

    const handleApprove = (id) => {
      if (!isApproved) {
        handleApproveSinglePoster(id);
        setIsApproved(true);
        setIsCommented(false);
      }
    };

    const handleModalOpen = () => {
      setPictureModalIsOpen(true);
    };

    React.useEffect(() => {
      if (poster.moderatorIssue.toString() === ModeratorIssueStatus.APPROVED) {
        setIsApproved(true);
      }
      if (poster.moderatorIssue.toString() === ModeratorIssueStatus.ISSUE) {
        setIsCommented(true);
      }
      return () => {
        setIsApproved(false);
        setIsCommented(false);
      };
    }, [poster]);

    React.useEffect(() => {
      if (isSelectByAll) {
        setIsApproved(true);
      }
      if (!isSelectByAll && !(poster.moderatorIssue.toString() === ModeratorIssueStatus.APPROVED)) {
        setIsApproved(false);
      }
      // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [isSelectByAll]);

    const closeModal = () => {
      setCommentModalIsOpen(false);
    };

    const addPosterComment = (comment: string) => {
      return addPosterCommentApi({ id: poster.id, comment })
        .then(() => {
          setError({});
          getPosters();
          setCommentModalIsOpen(false);
          setIsCommented(true);
          setIsApproved(false);
        })
        .catch((error) => {
          const { data, status } = error.response;
          if (status >= 500) {
            dispatch(setServerError(status));
            return;
          }
          setError(data);
        });
    };

    return (
      <>
        <ModeratorPosterCardContainer className={className} ref={forwardRef}>
          <ImageContainer
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            onClick={handleModalOpen}
            ref={imageContainerRef}
          >
            <img src={`${poster.photo}`} alt="" />
          </ImageContainer>
          <ControlContainer>
            <ButtonApproveContainer isApproved={isApproved}>
              <ReactSVG src={ApproveIcon} onClick={() => handleApprove(poster.id)} />
              <ButtonTitle>Approve</ButtonTitle>
            </ButtonApproveContainer>
            <ButtonIssueContainer isCommented={isCommented}>
              <ReactSVG src={AddIssueIcon} onClick={() => setCommentModalIsOpen(true)} />
              <ButtonTitle>Add Issue</ButtonTitle>
            </ButtonIssueContainer>
          </ControlContainer>
        </ModeratorPosterCardContainer>
        {pictureModalIsOpen && (
          <DefaultModal closeModal={() => setPictureModalIsOpen(false)}>
            <img src={poster.photo} alt="poster_image" />
          </DefaultModal>
        )}
        {commentModalIsOpen && (
          <StyledPaperBoxModal closeModal={closeModal}>
            <AddCommentDialog
              closeModal={closeModal}
              addComment={addPosterComment}
              errors={errors}
            />
          </StyledPaperBoxModal>
        )}
      </>
    );
  }
);

const ModeratorPosterCardContainer = styled.div`
  width: 100%;
  padding: 8px 6px;
  background: var(--mainBackground);
  border-radius: 5px;
  position: relative;
`;

const StyledPaperBoxModal = styled(PaperBoxModal)`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
`;

const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
`;
/* eslint-disable */

const ButtonApproveContainer = styled(({ isApproved, ...rest }) => <div {...rest} />)`
  width: 50%;
  text-align: center;

  div {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: auto;

    &:hover {
      svg {
        fill: #5dd886;
      }

      path:last-child {
        stroke: #ffffff;
      }
    }
    ${(props) =>
      props.isApproved &&
      `
    svg { 
      fill: #5dd886; 
    }

    path:last-child {
      stroke: #ffffff;
    } `}
`;

const ButtonIssueContainer = styled(({ isCommented, ...rest }) => <div {...rest} />)`
  width: 50%;
  text-align: center;

  div {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: auto;
    border-radius: 50px;

    &:hover {
      background: #960018;

      path:first-child {
        stroke: #ffffff;
        fill: #ffffff;
      }

      path:nth-child(2) {
        stroke: #ffffff;
        fill: #ffffff;
      }
    }

    ${(props) =>
      props.isCommented &&
      `
      background: #960018;

      path:first-child {
        stroke: #ffffff;
        fill: #ffffff;
      }

      path:nth-child(2) {
        stroke: #ffffff;
        fill: #ffffff;
      }`}
  }
`;

/* eslint-enable */

const ButtonTitle = styled.p.attrs({
  className: 'font-body4',
})`
  margin: 0;
  margin-top: 8px;
`;

interface ModeratorPosterType {
  poster: PosterType;
  className?: string;
  forwardRef?: React.Ref<HTMLDivElement>;
  handleApproveSinglePoster: (id: number) => void;
  setModalPosterInfo?: (poster: PosterType) => void;
  clearModalPosterInfo?: () => void;
  setMouseCoordinates?: (coordinates: any) => void;
  getPosters: () => void;
  isSelectByAll: boolean;
}

export default ModeratorPosterCard;

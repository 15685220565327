import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import logo from '../static/images/pcds_main_logo.svg';

const AuthTemplate = ({ body, footer, isFooterHide }: AuthTemplateTypes) => {
  return (
    <RootContainer>
      <AuthContainer>
        <LogoContainer>
          <ReactSVG src={logo} />
        </LogoContainer>
        <BodyContainer>{body}</BodyContainer>
        {!isFooterHide && <FooterContent>{footer}</FooterContent>}
      </AuthContainer>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: var(--secondaryBackground);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

const AuthContainer = styled.div`
  max-width: 560px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
  background: var(--defaultBody);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  width: 100%;
  padding: 32px 0;
  display: flex;
  justify-content: center;
`;

const BodyContainer = styled.div.attrs({
  className: 'layout-wrap',
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterContent = styled.footer`
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: center;
  background: var(--secondaryBackground);
  border-radius: 0 0 15px 15px;
`;

interface AuthTemplateTypes {
  body: React.ReactNode;
  footer?: React.ReactNode;
  isFooterHide?: boolean;
}

export default AuthTemplate;

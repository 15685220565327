import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export const axiosConfig: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  baseURL: '/api/v2',
};

export const apiPublic: AxiosInstance = axios.create(axiosConfig);
export const api: AxiosInstance = axios.create(axiosConfig);

import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import DeleteIcon from '../static/icons/delete_select.svg';
import DeleteOptionIcon from '../static/icons/delete_select_option.svg';
import PaperBoxModal from './PaperBoxModal';
import { customScroll } from '../styles/GlobalStyles';
import { Option } from '../types/liveReport';

const selectClassName = 'react-select-custom';

const CustomFilterSelect = ({
  label,
  options,
  value,
  handleDeleteValue,
  handleSelect,
  handleClearValue,
  isOneValue,
  className,
  isBooleanValue,
}: InputTypes) => {
  const parsedOptions = options.some((option) => !option) ? [] : options;

  const [valueContainerIsOpen, setValueContainerIsOpen] = React.useState(false);
  const [selectIsOpen, setSelectIsOpen] = React.useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    valueContainerIsOpen ? setValueContainerIsOpen(false) : setValueContainerIsOpen(true);
  };

  const handleDelete = (e, valueToDelete) => {
    e.stopPropagation();
    handleDeleteValue(valueToDelete);
  };

  const clearAllValues = (e) => {
    e.stopPropagation();
    handleClearValue();
  };

  const closeValueModal = () => {
    setValueContainerIsOpen(false);
  };

  const CustomMultiValue = (props) => {
    if (props.index <= 1)
      return (
        <StyledMultiValue onClick={(e) => handleDelete(e, props.data.value)}>
          {isBooleanValue ? `${props.data.value ? 'Yes' : 'No'}` : `${props.data.value}`}
          <ReactSVG src={DeleteOptionIcon} />
        </StyledMultiValue>
      );
    return null;
  };

  const customModalValue = (valueToView) => {
    return (
      <ExtendedMultiValueContainer>
        {valueToView &&
          valueToView.map((element, index) => {
            if (index > 1)
              return (
                // eslint-disable-next-line
                <ExtendedMultiValue key={index} onClick={(e) => handleDelete(e, element.value)}>
                  {element.value}
                  <ReactSVG src={DeleteOptionIcon} />
                </ExtendedMultiValue>
              );
            return null;
          })}
      </ExtendedMultiValueContainer>
    );
  };

  const handleOpenSelect = (e) => {
    selectIsOpen ? setSelectIsOpen(false) : setSelectIsOpen(true);
  };

  const handleCloseSelect = () => {
    setSelectIsOpen(false);
  };

  const CustomOption = (props) => {
    if (isOneValue && value.length) return <StyledCustomOption>No options</StyledCustomOption>;
    return (
      <StyledCustomOption {...props.innerProps} amountOfChildren={props.children.length}>
        {props.children.map((element, index) => (
          // eslint-disable-next-line
          <span key={index}>{element}</span>
        ))}
      </StyledCustomOption>
    );
  };

  const AmountOfValuesContainer = () => {
    if (value.length <= 2) return null;
    return (
      <StyledAmountOfValuesContainer>
        <StyledOpenModal onClick={handleClick}>+{value.length - 2}</StyledOpenModal>
        {valueContainerIsOpen && (
          <StyledPaperBoxModal closeModal={closeValueModal}>
            {customModalValue(value)}
          </StyledPaperBoxModal>
        )}
      </StyledAmountOfValuesContainer>
    );
  };

  const CustomClearIndicator = (props) => (
    <StyledCustomClearIndicator>
      <AmountOfValuesContainer />
      <ClearAllButton onClick={clearAllValues}>
        <ReactSVG src={DeleteIcon} />
      </ClearAllButton>
    </StyledCustomClearIndicator>
  );

  return (
    <SelectContainer className={className}>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelectContainer onClick={handleOpenSelect}>
        <StyledSelect
          value={value}
          options={parsedOptions}
          components={{
            MultiValue: CustomMultiValue,
            ClearIndicator: CustomClearIndicator,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            Option: CustomOption,
          }}
          onChange={handleSelect}
          className={selectClassName}
          classNamePrefix={selectClassName}
          isSearchable={false}
          maxMenuHeight={240}
          placeholder=""
          onBlur={handleCloseSelect}
          menuIsOpen={selectIsOpen}
          isMulti
        />
      </StyledSelectContainer>
    </SelectContainer>
  );
};

const StyledCustomClearIndicator = styled.div`
  display: flex;
`;

const SelectContainer = styled.div``;

const StyledSelectContainer = styled.div``;

const StyledLabel = styled.p.attrs({
  className: 'font-body3',
})`
  color: var(--mainText);
  margin 0;
`;

const ClearAllButton = styled.button`
  border: none;
  background: transparent;

  svg {
    margin-top: 3px;
  }
`;

const StyledAmountOfValuesContainer = styled.div`
  position: relative;
`;

const StyledOpenModal = styled.button`
  border: none;
  color: var(--mainText);
  border-radius: 5px;
  padding: 7px;
  background: var(--secondaryBackground);
  cursor: pointer;
`;

const StyledPaperBoxModal = styled(PaperBoxModal)`
  width: 260px;
  position: absolute;
  top: 100%;
  left: 0;

  & > div {
    border-radius: 5px;
  }
`;

const ExtendedMultiValueContainer = styled.div`
  padding: 16px 0;
  width: 100%;
`;

const StyledMultiValue = styled.button.attrs({
  className: `${selectClassName}__delete`,
})`
  text-transform: capitalize;
  color: var(--mainText);
  border-radius: 5px;
  padding: 7px;
  background: var(--secondaryBackground);
  display: flex;
  margin-right: 8px;
  border: none;
  cursor: pointer;

  & > div {
    margin-left: 10px;
  }
`;

const ExtendedMultiValue = styled(StyledMultiValue)`
  width: 100%;
  padding: 15px;
  justify-content: space-between;
  border-radius: 0px;
  background: transparent;

  &:hover {
    background: var(--secondaryBackground);
  }
`;

const StyledCustomOption = styled(({ amountOfChildren, ...rest }) => <div {...rest} />)`
  display: flex;
  padding: 15px 16px;
  align-items: center;

  ${(props) =>
    props.amountOfChildren < 3
      ? `
    span {
      display: block;
      width: 70%;
      text-transform: capitalize;
    }

    span:last-child {
      width: 30%;
    }
  `
      : `

    span {
      display: block;
      width: 40%;
      text-transform: capitalize;
    }

    span:last-child {
      width: 20%;
    }
  `}

  ${(props) =>
    props.amountOfChildren <= 1
      ? `
    span {
      display: block;
      width: 100%;
      text-transform: capitalize;
    }
  `
      : `
  `}

  &:hover {
    background-color: var(--secondaryBackground);
    color: var(--mainText);
  }
`;

const StyledSelect = styled(({ error, ...rest }) => <Select {...rest} />)`
  width: 100%;

  .${selectClassName}__value-container {
    padding: 2px 16px;
  }

  .${selectClassName}__control {
    min-height: 48px;
    border: 1px solid var(--defaultBody);
    box-sizing: border-box;
    border-radius: 15px;
    outline: none;
    font-size: 12px;

    &:hover {
      cursor: pointer;
      border: 1px solid var(--defaultBody);
    }
  }

  .${selectClassName}__control--is-focused {
    border-color: var(--defaultBody) !important;
    box-shadow: none !important;
  }

  .${selectClassName}__menu {
    border: 1px solid var(--defaultBody);
    box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
    border-color: var(--defaultBody) !important;
    box-sizing: border-box;
    border-radius: 15px;
    outline: none;
    padding: 11px 0;
    font-size: 12px;
  }

  .${selectClassName}__menu-list {
    ${customScroll}
  }
`;

interface InputTypes {
  label: string;
  value: Option[] | null;
  options: Option[] | null;
  handleDeleteValue: (value: string) => void;
  handleSelect: (event: Option) => void;
  handleClearValue: () => void;
  className?: string;
  isOneValue?: boolean;
  isBooleanValue?: boolean;
}

export default CustomFilterSelect;

import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { customScroll } from '../../styles/GlobalStyles';

const SelectInput = ({ placeholder, options, value, error, handleSelect }: InputTypes) => {
  return (
    <StyledSelect
      error={error}
      value={value}
      options={options}
      onChange={handleSelect}
      className="react-select"
      classNamePrefix="react-select"
      placeholder={placeholder}
    />
  );
};

const StyledSelect = styled(({ error, ...rest }) => <Select {...rest} />)`
  width: 100%;

.react-select__control  {
  height: 48px;
  border: ${(props) =>
    props.error ? '1px solid var(--mainAccent)' : '1px solid var(--mainObjects)'};
  box-sizing: border-box;
  border-radius: 15px;
  outline: none;
  font-size: 14px;
}

.react-select__control--is-focused {
  border-color: var(--elementBackground)!important;
  box-shadow: none!important;
}

  .react-select__menu {
    border: 1px solid var(--mainObjects);
    box-sizing: border-box;
    border-radius: 15px;
    outline: none;
    padding: 15px 0;
    font-size: 14px;
  }

  .react-select__indicators {
    display: none;
  }

  .react-select__menu-list {
    ${customScroll}
  }

  .react-select__option {
    padding-top 15px;
    padding-bottom: 15px;
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background-color: var(--secondaryBackground);
    color: var(--mainText);
  }
`;

interface InputTypes {
  placeholder: string;
  value: OptionType | null;
  options: OptionType[] | null;
  error: boolean;
  handleSelect: (event: OptionType) => void;
}

interface OptionType {
  [key: string]: string | number;
}

export default SelectInput;
